import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import MUIDataTable from 'mui-datatables';
import axios from "../../auth/Axios";

const InfoModal = ({ open, handleClose, floorplan, propertyName }) => {
  const[moreInfo, setMoreInfo] = useState([])
  const fetchData = async () => {
  const queryData = {
      params: {
        propertyName: propertyName,
        floorplan: floorplan
    }
  }

  await axios.get('market/get-detailed-floorplan', queryData)
    .then((res)=> {
      setMoreInfo(res.data.data)
    })
    .catch(err => {
      console.log("==== error is ====", err)
    })
  }

  const columns = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'squarefeet',
      label: 'Squarefeet',
    },
    {
      name: 'min_rent',
      label: 'Low Rent',
    },
    {
      name: 'max_rent',
      label: 'High Rent',
    },
  ];
  

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none", // Allow selecting a single row
    rowsPerPage: 5,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 15, 20, 50],
  };

  useEffect(()=> {
    fetchData();
  },[])

  return (

    <Modal
      size="xl"
      centered
      style={{
        fontFamily: "Poppins, sans-serif",
        zIndex: 1200,
        maxHeight: "100%",
        width: "110%",

      }}
      show={open}
      onHide={handleClose}
      animation="true"
      backdrop={'false'}
    >

      <Modal.Header closeButton>
          <h4 className="text-center m-0 m-2 fw-bold">Additional Info</h4>
      </Modal.Header>

      <Modal.Body className="px-5">
        {
          moreInfo.length === 0 ?
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" role="status" className="my-5">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            :

            <div >
              <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                      title= {propertyName + " (" +  floorplan + ")"}
                      data={moreInfo}
                      columns={columns}
                      options={options}                     
                    />
              </ThemeProvider>
            </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
