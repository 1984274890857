import LineChart from "../../LineChart.js";
import { Spinner } from "react-bootstrap";

export default function Applications({ applicationsData }) {
  function reverseArr(input) {
    try {
      var ret = [];
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    } catch (err) {}
  }
  const chartData = {
    labels: reverseArr(applicationsData.labels),
    datasets: [
      {
        label: "Applications",
        data: reverseArr(applicationsData.data),
        borderColor: "#FF6746",
        borderWidth: 3,
        smooth: true,
      },
    ],
  };
  return (
    <div
      className="mx-2 shadow-sm border h-100 mt-4 pb-3 px-3 bg-white"
      style={{ borderRadius: "14px" }}
    >
      <p className="lead fw-bold my-0 mt-3">Applications</p>
      {/* <span className="fw-bold text-muted">{subTitle}</span> */}
      {applicationsData ? (
        <>
          <div className="Legend my-4 d-flex justify-content-end">
          </div>
          <LineChart width={650} height={200} data={chartData} />
        </>
      ) : (
        <div className="d-flex w-100 h-100 justify-content-center">
          <Spinner animation="border" role="status" className="my-5 mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
