import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useCheckUser = () => {
  const [user, setUser] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    async function findUser() {
      if (localStorage.getItem("access")) {
        setUser(true);
        setLoading(false);
      } else {
        const exceptionRoutes = ["verify-email", "reset-password"];
        const isExceptionRoute = exceptionRoutes.some(route => location.pathname.includes(route));
        if (!isExceptionRoute) {
          navigate("/login");
        }
      }
    }
    findUser();
  }, []);
  return {
    user,
    setUser,
    isLoading,
  };
};

export default useCheckUser;
