import React, { useEffect, useState } from "react";
import {
    formatExpirations,
  } from "./Utils/formatters";
import { Spinner } from "react-bootstrap";
import Outlook from "./Outlook";

export default function OutlookWrapper({
    propertyId,
    dateRange,
    setDateRange,
    outlook,
    referenceRent,
}) {
    
    const [formattedOutlook, setFormattedOutlook] = useState([]);


    useEffect(() => {
        setFormattedOutlook(formatExpirations(outlook));
      }, []);

    return (
        <>
            {
                formattedOutlook?.length !== 0 && referenceRent?.reference_rent ? (
                <Outlook
                    propertyId={propertyId}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    expirations={formattedOutlook}
                    referenceRent={referenceRent.reference_rent}
                />
                ) 
                :
                <div className="d-flex w-100 justify-content-center">
                    <Spinner animation="border" role="status" className="my-5">
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
        </>
    )
} 