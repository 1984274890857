import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Switch, Space } from 'antd';

export default function ExpirationChart({ expirations, thresholds, anticipatedRenConv, renewalOutlook, renewalOutlookLabels }) {
  const [formattedRenewalOutlook, setFormattedRenewalOutlook] = useState({
    "pending":[0,0,0,0],
    "notice":[0,0,0,0],
    "renewed":[0,0,0,0]
  })
  const [toggle, setToggle] = useState(false)
  const anticipatedExp = expirations.data.map(expiration => expiration - (expiration * anticipatedRenConv));
  
  function forecastExpirations(expiration, index) {
    if (index < 12) {
      return 0
    }
    if (index >= 12 && index < 18){
      return expiration + anticipatedExp[index - 12]
    }
    return expiration
  }
const forecastedExp = anticipatedExp.map((expiration, index) => forecastExpirations(expiration, index))

function formatRenewalOutlook(obj) {
  const result = { pending: [], notice: [], renewed:[] };

  for (const key in obj) {
    if (key === 'avg_retention') {
      result.avg_retention = obj[key];
      continue;
    }
    obj[key].pending.forEach((value, index) => {
      result.pending[index] = (result.pending[index] || 0) + value;
    });
    obj[key].notice.forEach((value, index) => {
      result.notice[index] = (result.notice[index] || 0) + value;
    });
    obj[key].renewed.forEach((value, index) => {
      result.renewed[index] = (result.renewed[index] || 0) + value;
    });
  }
  return result;
}

useEffect(()=> {
    const output = formatRenewalOutlook(renewalOutlook)
    setFormattedRenewalOutlook(output)
},[])

const handleSwitch = (value) => {
  setToggle(value)
}

const data = {
    labels: expirations.labels,
    datasets: [
      {
        label: "Threshold",
        data: thresholds,
        borderColor: "#FF507A",
        backgroundColor: "#FF507A",
        type: "line",
        yAxisID: 'y',
        order: 0,
      },
      {
        label: "Expiration",
        data: expirations.data,
        backgroundColor: "rgba(243, 156, 18, 0.8)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 3,
      },
      {
        label: "Anticipated",
        data: anticipatedExp,
        backgroundColor: "rgba(72, 201, 176, 0.8)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 1,
      },
      {
        label: "Forecasted",
        data: forecastedExp,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            family: "Poppins",
            weight: 'bold', 
          },
        },
      },
    },
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins", 
          },
        },
      },
    },
  };

  const outlookData = {
    labels: renewalOutlookLabels,
    datasets: [
      {
        label: "Pending",
        data: formattedRenewalOutlook?.pending,
        backgroundColor: "rgba(243, 156, 18, 0.8)",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 1,
      },
      {
        label: "On Notice",
        data: formattedRenewalOutlook?.notice,
        backgroundColor: "#FF507A",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 0,
      },
      {
        label: "Renewed",
        data: formattedRenewalOutlook?.renewed,
        backgroundColor: "rgba(72, 201, 176, 0.8)",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 2,
      },
      {
        label: "60 Days Avg Retention",
        data: renewalOutlookLabels.map(() => formattedRenewalOutlook?.avg_retention), // Array with the same length as labels filled with 60
        borderColor: "rgba(0, 123, 255, 0.8)",
        backgroundColor: "rgba(0, 123, 255, 0.8)",
        borderWidth: 3,
        pointRadius: 0,
        fill: false,
        type: "line",
        yAxisID: 'y1',
        order: 3,
      },
    ],
  };


  const outlookOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            family: "Poppins",
            weight: 'bold', 
          },
        },
      },
    },
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y1: {
        position: 'right',
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          max: 100,
          font: {
            family: "Poppins",
          },
          callback: function(value) {
            return value + "%"; // Append percentage sign
          },
        },
      },
    },
  };

  return (
    <div
      className="bg-white px-3 shadow-sm border mx-2 px-1 py-4 my-2 mt-4"
      style={{ borderRadius: "14px", width: "95%" }}
    >
      <div className="d-flex justify-content-between mb-5 mx-4">
        <div>
            <h4 className="m-0 fw-bold">
            {!toggle ? "Expiration Charts" : "Renewal Outlook Chart"}
            </h4>
            <span>
            {!toggle ? "Actual and Anticipated V/s Threshold" : "On Notice V/s Renewed V/s Pending"}
            </span>
        </div>

        <Space direction="vertical">
          <Switch checkedChildren="Renewal Outlook" unCheckedChildren="Expirations"  onChange={handleSwitch} />
        </Space>
        </div>
      
      {
        !toggle ? <Line width="30%" height="12%" options={options} data={data} />
        :
        <Line width="30%" height="12%" options={outlookOptions} data={outlookData} />
      }
      
    </div>
  );
}
