import { Button } from "react-bootstrap";
import React from "react";
import "./obtn.css";
function OutlinedPrimaryBtn(props) {
  const { text, className, ...rest } = props;
  return (
    <Button
      size="lg"
      {...rest}
      variant="outline-primary"
      style={{
        borderRadius: "22px",
        fontSize: "14px",
      }}
      className={`d-flex mx-2 text-center align-content-center btn-outline-primary ${className}`}
    >
      <span className="fw-bold px-3 py-1 text-center">{text}</span>
    </Button>
  );
}

export default OutlinedPrimaryBtn;
