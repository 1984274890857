import React, { useEffect, useState } from "react";
import {
  formatExpirationLabels,
  formatExpirationChartData,
  formatThresholds,
} from "./Utils/formatters";
import ExpirationChart from "./ExpirationChart";
import { Spinner } from "react-bootstrap";


export default function ExpirationsWrapper (
    {
        expirations,
        thresholds,
        anticipatedRenConv,
        renewalOutlook,
        renewalOutlookLabels,
    }
) {

  const [formattedExpirations, setFormattedExpirations] = useState([]);

    useEffect(() => {
        if (expirations && thresholds) {
          setFormattedExpirations({
            expirations: formatExpirationChartData(expirations),
            labels: formatExpirationLabels(expirations),
            thresholds: formatThresholds(expirations, thresholds),
          });
        }
      }, [expirations, thresholds]);


    return (
        <>
        {expirations && expirations.length !== 0 && formattedExpirations.length !== 0  && renewalOutlook && renewalOutlookLabels ?(
          <ExpirationChart
            exp={formattedExpirations}
            anticipatedRenConv = {anticipatedRenConv}
            renewalOutlook={renewalOutlook}
            renewalOutlookLabels={renewalOutlookLabels}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        </>
    )
}