import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import makeAnimated from "react-select/animated";
import Select from "react-select";

const BarChart = ({chartData}) => {
  const animatedComponents = makeAnimated();
  const [floorplan, setFloorplan] = useState('all')

  const shortenLables = () => {
    const labels = chartData.labels
    return labels.map((name) => {
      return name.slice(0,10) + '..'
    });
  }

  function combineRentData(data) {
    let valid_floorplans = Object.values(chartData.valid_floorplans)
    return Object.values(data).reduce((combinedData, categoryData, index, array) => {
      Object.keys(categoryData).forEach((key) => {
        if (!combinedData[key]) {
          combinedData[key] = new Array(categoryData[key].length).fill(0);
        }
        categoryData[key].forEach((value, i) => {
          combinedData[key][i] += value / valid_floorplans[i]; // Calculate the average
        });
      });
      return combinedData;
    }, {});
  }

  const backgroundColor = [
    '#cc33ff', // Purple
    '#ff3366', // Pink
    '#339966',  // Teal
    '#ff9933', // Orange
    '#66cccc', // Turquoise
    '#cccc66', // Olive
    '#993333', // Maroon
    '#ff5733', // Red
    '#ffcc33', // Yellow
    '#33ccff', // Blue
    '#33ff57', // Green
    '#9966cc', // Lavender

  ];

  const chartOptions = {
    labels : shortenLables(),
    datasets: [
      {
        label: 'Chart 1',
        backgroundColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const barChartNames = ['Average Rent PSF (Today)', 'Average Weighted Rent PSF (Today)', 'Average Effective Rent (Today)', 'Average Weighted Effective Rent (Today)']
  const [avgRentPsf, setAvgRentPsf] = useState({
    ...chartOptions,
    datasets: [
      {
        ...chartOptions.datasets[0],
        data: [],
      },
    ],
  })

  const [avgWeightedRentPsf, setAvgWeightedRentPsf] = useState({
    ...chartOptions,
    datasets: [
      {
        ...chartOptions.datasets[0],
        data: [],
      },
    ],
  })

  const [avgEffectiveRent, setAvgEffectiveRent] = useState({
    ...chartOptions,
    datasets: [
      {
        ...chartOptions.datasets[0],
        data: [],
      },
    ],
  })

  const [avgWeightedEffectiveRent, setAvgWeightedEffectiveRent] = useState({
    ...chartOptions,
    datasets: [
      {
        ...chartOptions.datasets[0],
        data: [],
      },
    ],
  })

  const calculateData = () => {
    let selectedData = []
    
    if ( floorplan === 'all') {
      selectedData = combineRentData(chartData['floorplan_data'])
    }

    else {
      selectedData = chartData['floorplan_data'][floorplan]
    }

    setAvgRentPsf((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: selectedData.avg_rent_psf,
        },
      ],
    }));

    setAvgWeightedRentPsf((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: selectedData.avg_weighted_rent_psf,
        },
      ],
    }));

    setAvgEffectiveRent((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: selectedData.avg_effective_rent,
        },
      ],
    }));

    setAvgWeightedEffectiveRent((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: selectedData.avg_weighted_effective_rent,
        },
      ],
    }));
  }

  useEffect(()=> {
    calculateData()
  }, [floorplan])


  return (

    <div>

        <div style={{
          display:"flex",
          justifyContent:'flex-end',
          // width:"200px"
        }}>
          <Select
              style={{ borderRadius: "16px"}}
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={{value:"all", label:"all"}}
              onChange={(val) => setFloorplan(val.value)}
              options={chartData?.select_options}
              className="w-30"
              maxMenuHeight={150}
          />
        </div>

        <div className="d-flex mb-3" style={{
            flexDirection:"column",
          }}>
          <div className='d-flex tuot'>
              <div
                  className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                  style={{
                  borderRadius: "14px", 
                  }}
                  >
                      <h6 className="fw-bold mt-3 text-center">{barChartNames[0]}</h6>
                      <div className="my-auto px-2"
                        >
                          <Bar data={avgRentPsf} options={options} height={"100%"}/>
                      </div>
              </div>
              <div
                  className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                  style={{
                  borderRadius: "14px",
                  }}
                  >
                      <h6 className="fw-bold mt-3 text-center">{barChartNames[1]}</h6>
                      <div className="my-auto px-2">
                          <Bar data={avgWeightedRentPsf} options={options} height={"100%"}/>
                      </div>
              </div>
          </div>
            
          <div className='d-flex tuot mb-3'>
              <div
                  className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                  style={{
                  borderRadius: "14px",
                  }}
                  >
                      <h6 className="fw-bold mt-3 text-center">{barChartNames[2]}</h6>
                      <div className="d-flex justify-content-around my-auto px-2">
                          <Bar data={avgEffectiveRent} options={options} height={"100%"}/>
                      </div>
              </div>

              <div
                  className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                  style={{
                  borderRadius: "14px",
                  }}
                  >
                      <h6 className="fw-bold mt-3 text-center">{barChartNames[3]}</h6>
                      <div className="d-flex justify-content-around my-auto px-2">
                          <Bar data={avgWeightedEffectiveRent} options={options} height={"100%"}/>
                      </div>
              </div>
          </div>
        </div>
    </div>

  );
};

export default BarChart;

