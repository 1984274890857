import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SearchBar({ leaseProperties, setSelectedProperties }) {
  const formatedProperties = () => {
    if (leaseProperties !== undefined) {
      const props = [{ value: "all", label: "All" }];
      leaseProperties.forEach((property, idx) => {
        props.push({ value: property, label: property });
      });
      return props;
    }
  };
  const animatedComponents = makeAnimated();
  const options = formatedProperties();
  return (
    <div className="w-75">
      <Select
        style={{ borderRadius: "16px" }}
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={options[0]}
        isMulti
        onChange={(val) => setSelectedProperties(val)}
        options={options}
      />
    </div>
  );
}
