import { React, useState, useEffect, useContext } from "react";
import SearchBar from "../New Leases/SearchBar";

import Spinner from "../Spinner";
import { useSelector } from "react-redux";
import ReportsProperty from "./ReportsProperty";
import "./reports.css";

function Reports({ fetch }) {
  const propertiesRedux = useSelector(
    (state) => state.propertyReducer.properties
  );

  const [selectedProperties, setSelectedProperties] = useState([
    { value: "all", label: "All" },
  ]);
  const [selectedPropNames, setSelectedPropNames] = useState([]);
  const [isLoading, setLoading] = useState(true);


  const showProperties = (data) => {
    try {
      if (data || selectedProperties) {
        setLoading(true);
        const selectedPropNames = [];
        if (
          selectedProperties.length === 0 ||
          selectedProperties.some((prop) => prop.value === "all")
        ) {
          selectedPropNames.push(...Object.keys(data));
        } else {
          let final = selectedProperties.filter((prop) => prop.value !== "");
          final.forEach((prop) => {
            selectedPropNames.push(prop.label);
          });
        }
        setSelectedPropNames(selectedPropNames);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      fetch();
    }
  };
  useEffect(() => {
    showProperties(propertiesRedux);
  }, [selectedProperties, propertiesRedux]);


  return (
    <div className="reports bg-white d-flex w-75 h-100 flex-column mx-auto pt-5 mb-5">
      <div className="d-flex flex-column mt-4">
        <h3 className="m-0 fw-bold">Reports</h3>
      </div>

      <div className="d-flex align-items-center justify-content-between">
          <SearchBar
          leaseProperties={Object.keys(propertiesRedux).sort((a, b) => {
              return a.localeCompare(b);
          })}
          setSelectedProperties={setSelectedProperties}
          />
      </div>

      <div className="d-flex flex-column">
        {
        isLoading ? (
          <div className="d-flex w-75 h-150 align-items-center justify-content-center">
          <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
          </div>
        ) 
        :
        Object.keys(propertiesRedux).length !== 0 ? (
          Object.entries(
            Object.fromEntries(Object.entries(propertiesRedux).sort())
          ).map((property, index) => {
            return selectedPropNames.includes(property[0]) &&

          // Object.entries(propertiesRedux).map((property, idx) => {
          //   return (
              <ReportsProperty
                key={property[1].property_id}
                propertyId={property[1].property_id}
                name={property[0]}
                propertyData={property[1]}
              />
            // );
          })
        ) : (
          <Spinner animation="border" role="status" className="my-5">
            <h6 className="fw-bold mt-3">
              Please wait while we load the Reports...
            </h6>
            <span>Crunching some numbers :)</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}

export default Reports;
