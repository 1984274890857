/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LeaseRenewalsProperty from "./LeaseRenewalsProperty";
import "./leaseRenewals.css";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import SearchBar from "./SearchBar";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  loadExpirationsRange,
  loadExpiringUnits,
  loadReferenceRent,
} from "../../Actions/propertyActions";

function LeaseRenewals({ fetch }) {
  const dispatch = useDispatch();
  const [selectedProperties, setSelectedProperties] = useState([
    { value: "all", label: "All" },
  ]);
  const [selectedPropNames, setSelectedPropNames] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const temp = new Date();
  const now = new Date();
  const next30Days = new Date(temp.setDate(now.getDate() + 90));
  const [dateRange, setDateRange] = useState([now, next30Days]);

  const leaseProperties = useSelector(
    (state) => state.propertyReducer.properties
  );
  const referenceRent = useSelector(
    (state) => state.propertyReducer.referenceRent
  );
  const expirationsRange = useSelector(
    (state) => state.propertyReducer.expirationsRange
  );
  const expiringUnits = useSelector(
    (state) => state.propertyReducer.expiringUnits
  );
  useEffect(() => {
    if (!expirationsRange || Object.keys(expirationsRange).length === 0) {
      dispatch(loadExpirationsRange(dateRange));
    }

    if (!referenceRent || Object.keys(referenceRent).length === 0 ) {
      dispatch(loadReferenceRent());
    }

    dispatch(loadExpiringUnits(dateRange));
  }, [dateRange]);
  
  const showProperties = (data) => {
    try {
      if (data) {
        setLoading(true);
        const selectedPropNames = [];
        if (
          selectedProperties.length === 0 ||
          selectedProperties.some((prop) => prop.value === "all")
        ) {
          selectedPropNames.push(...Object.keys(data));
        } else {
          let final = selectedProperties.filter((prop) => prop.value !== "");
          final.forEach((prop) => {
            selectedPropNames.push(prop.label);
          });
        }
        setSelectedPropNames(selectedPropNames);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      fetch();
    }
  };

  useEffect(() => {
    showProperties(leaseProperties);
  }, [selectedProperties, leaseProperties]);

  return (
    <div className="new-leases mx-auto pt-5" style={{ width: "80%" }}>
      <div className="d-flex flex-column">
        <h3 className="fw-bold mt-4">Lease Renewals</h3>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <SearchBar
          leaseProperties={Object.keys(leaseProperties).sort((a, b) => {
            return a.localeCompare(b);
          })}
          setSelectedProperties={setSelectedProperties}
        />
        <PrimaryBtn onClick={fetch} text="Refresh" className="h-50 w-50" />
      </div>
      <div className="d-flex flex-column align-items-center">
        {isLoading ? (
          <Spinner animation="border" role="status" className="my-5">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : selectedPropNames.length !== 0 &&
          Object.keys(expiringUnits).length !== 0 ? (
          Object.entries(
            Object.fromEntries(Object.entries(leaseProperties).sort())
          ).map((property, index) => {
            return selectedPropNames.includes(property[0]) ? (
              <LeaseRenewalsProperty
                key={index}
                propertyId={property[1].property_id}
                name={property[0]}
                rawExpirations={expirationsRange[property[0]]}
                expiringUnits={expiringUnits[property[0]]}
                setDateRange={setDateRange}
                dateRange={dateRange}
                referenceRent={referenceRent[property[0]]}
                location={property[1].address}
                variants={property[1].unit_types}
              />
            ) : null;
          })
        ) : (
          <Spinner animation="border" role="status" className="my-5">
            <h6 className="fw-bold mt-3">
              Please wait while we load the Lease Renewals...
            </h6>
            <span>Crunching some numbers :)</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}
export default LeaseRenewals;
