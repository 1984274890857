import React, { useEffect, useState } from "react";
import Pin from "../assets/MapPin.svg";
import RentChartWrapper from "./RentChartWrapper";
import BarChart from "./BarChart";
import DataTable from "./DataTable";
import Spinner from "../Spinner";
import SearchBar from "../New Leases/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { loadBarChartsData, loadMarketProperties, loadCompetitorsRent } from "../../Actions/marketInsightActions";

const MarketInsight = () => {
    const dispatch = useDispatch();
    const marketProperties = useSelector((state) => state.marketReducers.marketProperties);
    const barChartdata = useSelector((state) => state.marketReducers.barChartData);
    // const competitorsRent = useSelector((state) => state.marketReducers.competitorsRent);
    const [selectedProperties, setSelectedProperties] = useState([
        { value: "all", label: "All" },
      ]);
    const [selectedPropNames, setSelectedPropNames] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const fetchData = () => {
        if (!marketProperties || Object.keys(marketProperties).length === 0) {
            dispatch(loadMarketProperties());
          }
        if (!barChartdata || Object.keys(barChartdata).length === 0) {
            dispatch(loadBarChartsData());
          }
        // if (!competitorsRent || Object.keys(competitorsRent).length === 0) {
        //     dispatch(loadCompetitorsRent());
        //   }
    }

    const showProperties = (data) => {
        try {
          if (data || selectedProperties) {
            setLoading(true);
            const selectedPropNames = [];
            if (
              selectedProperties.length === 0 ||
              selectedProperties.some((prop) => prop.value === "all")
            ) {
              selectedPropNames.push(...Object.keys(data));
            } else {
              let final = selectedProperties.filter((prop) => prop.value !== "");
              final.forEach((prop) => {
                selectedPropNames.push(prop.label);
              });
            }
            setSelectedPropNames(selectedPropNames);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          dispatch(loadMarketProperties())
        }
      };

    useEffect(()=> {
        showProperties(marketProperties);
        fetchData()
    }, [selectedProperties, marketProperties])

    return (
        <div  className="MarketWrapper"
            style={{
                // marginTop:"50px",
                margin:"50px 40px", 
                width:"100%",
                display:"flex",
                flexDirection:"column",
                gap:"1rem"
            }}
            >
            
            <div>
                <h3 className="fw-bold mt-4">Market Manager</h3>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <SearchBar
                leaseProperties={Object.keys(marketProperties).sort((a, b) => {
                    return a.localeCompare(b);
                })}
                setSelectedProperties={setSelectedProperties}
                />
            </div>

            {
                isLoading ||  selectedPropNames.length === 0 ||
                Object.keys(marketProperties).length === 0 ||
                Object.keys(barChartdata).length === 0 
                // ||Object.keys(competitorsRent).length === 0
                ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the Market Properties...
                        </h6>
                        <span>Crunching some numbers :)</span>
                </Spinner>
                )
                :
                (
                Object.entries(
                    Object.fromEntries(Object.entries(marketProperties).sort())
                    ).map((property, idx) => {
                    return selectedPropNames.includes(property[0]) &&               
                        <div key={idx} className="m-4 mt-4 py-4 shadow border p-3"
                            style={{ borderRadius: "14px" }}
                            >
                            <div className="d-flex flex-column">
                                <div className="property-details d-flex">
                                    <div className="d-flex flex-row" style={{width:"100%",justifyContent:"space-between"}}>
                                        <div className="d-flex flex-row">
                                            <span className="m-0 ms-2 fw-bold fs-4">{property[0]}</span>
                                            <div className="d-flex mx-3 align-items-center">
                                                <img src={Pin} alt="pin" />
                                                <span className="text-muted">{property[1].address}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <BarChart chartData={barChartdata[property[0]]} />
                            </div>

                            <RentChartWrapper 
                                propName={property[0]}
                                selectOptions={barChartdata[property[0]]?.select_options}
                                valid_floorplans={barChartdata[property[0]]?.valid_floorplans}
                                // competitorsRent={competitorsRent}
                                propertyId={property[1].id} 
                            />

                            {/* <MultiLineChart rentData={competitorsRent[property[0]]} 
                                            propName={property[0]}
                                            dates={competitorsRent["dates"]}
                                            selectOptions={barChartdata[property[0]]?.select_options}
                                            valid_floorplans={barChartdata[property[0]]?.valid_floorplans}
                            /> */}
                            <DataTable tableData={barChartdata[property[0]]}/>
                        </div>
                        })
                )
            }        
        </div>
    )
}

export default MarketInsight;