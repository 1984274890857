/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SolidPrimaryBtn from "../Buttons/SolidPrimaryBtn";
import axios from "../../auth/Axios";
import { Spinner } from "react-bootstrap";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
export default function PropertyReport({ propertyId, name }) {
  const temp = new Date();
  const now = new Date();
  const next30Days = new Date(temp.setDate(now.getDate() + 30));
  const [dateRange, setDateRange] = useState([now, next30Days]);
  const [leaseAuditDateRange, setLeaseAuditDateRange] = useState(null)
  const [gridMessage, setGridMessage] = useState(null)
  const [agedDays, setAgedDays] = useState(30);
  const reportsOptions = [
    { value: "get-occupancy-report", label: "Availability Report" },
    { value: "get-aged-vacant-report", label: "Aged Vacant Report" },
    {
      value: "get-renewal-conversion-report",
      label: "Renewal Conversion Report",
    },
    {
      value: "get-renewal-performance-report-v2",
      label: "Renewal Performance Report",
    },
    {
      value: "get-performance-analysis-report",
      label: "Performance Analysis Report",
    },
    { value: "get-weekly-call-report", label: "Weekly Call Report" },
    { value: "get-weekly-exec-report", label: "Weekly Exec Report" },
    { value: "get-pricing-grid-report", label: "Pricing Grid Report" },
    { value: "get-lease-audit-report", label: "New Lease Audit Report" },
    { value: "get-renewal-lease-audit-report", label: "Renewal Lease Audit Report" },
    { value: "get-market-rent", label: "Market Rent Report" },
    { value: "get-expiration-management-report", label: "Expiration Management Report" },
    { value: "get-pricing-offset-report", label: "Pricing Offset Report" },
  ];
  const [selectedReport, setSelectedReport] = useState(reportsOptions[0]);
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [conversionInfo, setConversionInfo] = useState({});
  const [performanceInfo, setPerformanceInfo] = useState({});
  const animatedComponents = makeAnimated();

  const options = {
    filter: true,
    filterType: "dropdown",
    rowsPerPage: 40,
    elevation: 0,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20, 30, 40, 50],
    downloadOptions: {
      filename: `${selectedReport.label} ${
        dateRange[0].getMonth() + 1
      }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`,
    },
  };

  function upperCase(argument) {
    return argument.replace(/\w+/g, function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const formatColumns = (cols) => {
    if (cols.length !== 0) {
      const formattedColumns = [];
      cols.forEach((col) => {
        if (col === "id") {
        } else {
          formattedColumns.push(upperCase(col.replace(/#|_/g, " ")));
        }
      });

      if (selectedReport.label === "") {
        console.log([...formattedColumns, "Best Rent"]);
        return [...formattedColumns, "Best Rent"];
      } else {
        // console.log(formattedColumns);
        return formattedColumns;
      }
    }
  };
  const catchFunc = (err) => {
    console.error(err, propertyId);
    setTimeout(() => {
      fetchReports(selectedReport, dateRange);
    }, 5000);
  };
  const dateFormat = ["MM/DD/YYYY", "MM/DD/YYYY"];

  const formatTableData = (data, columns) => {
    if (data.length !== 0) {
      const rowData = [];
      data.forEach((item) => {
        const row = [];
        columns.forEach((column, idx) => {
          if (selectedReport.label === "Availability Report") {
             
              row.push(item[idx]);
            
          }
          if (selectedReport.label === "Renewal Conversion Report") {
            switch (idx) {
              case 4:
                if (item[idx] !== "N/A") {
                  row.push(`$${item[idx]}`);
                } else {
                  row.push(`${item[idx]}`);
                }
                break;
              case 5:
                if (item[idx] !== "N/A") {
                  row.push(`$${item[idx]}`);
                } else {
                  row.push(`${item[idx]}`);
                }
                break;
              case 7:
                if (item[idx] !== "N/A") {
                  row.push(`$${item[idx]}`);
                } else {
                  row.push(`${item[idx]}`);
                }
                break;
              case 6:
                row.push(`${item[idx]}%`);
                break;
              default:
                row.push(item[idx]);
                break;
            }
          }
          if (selectedReport.label === "Renewal Performance Report") {
            if (
              Date.parse(dateRange[0]) <= Date.parse(item[3]) &&
              Date.parse(item[3]) <= Date.parse(dateRange[1])
            ) {
              if (item[idx] !== "N/A") {
                if (idx === 4 || idx === 5 || idx === 7) {
                  row.push(`$${item[idx]}`);
                } else {
                  row.push(item[idx]);
                }
              } else {
                row.push(item[idx]);
              }
            }
          }
          if (selectedReport.label === "Performance Analysis Report") {
            if (idx === 0) {
              row.push(upperCase(item[idx].replace(/#|_/g, " ")));
            } else {
              row.push(item[idx]);
            }
          }
        });
        if (row.length !== 0) {
          rowData.push(row);
        }
      });
      console.log(rowData);
      return rowData;
    }
  };

  const getPerformanceInfo = (data) => {
    if (data) {
      let noticeCount = 0;
      let renewedCount = 0;
      let pendingCount = 0;
      let avgIncrease = [];
      data.forEach((row) => {
        row.forEach((item, idx) => {
          if (idx === 9) {
            switch (item) {
              case "Notice":
                noticeCount++;
                break;
              case "Renewed":
                renewedCount++;
                break;
              case "Pending":
                pendingCount++;
                break;
              default:
                break;
            }
          }
          if (idx === 6 && item !== "N/A") {
            avgIncrease.push(item);
          }
        });
      });
      const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
      setPerformanceInfo({
        Notice: noticeCount,
        Renewed: renewedCount,
        Pending: pendingCount,
        "Total Expirations": data.length,
        "Renewal Conversion Rate": Math.round(
          (renewedCount / data.length) * 100
        ),
        "Avg Increase%": parseInt(average(avgIncrease)),
      });
    }
  };

  const fetchReports = async (type, date) => {
    if (type && date) {
      setLoading(true);
      setGridMessage(null);
      let initialDate = null
      let finalDate = null
      const startDate = `${
        date[0].getMonth() + 1
      }/${date[0].getDate()}/${date[0].getFullYear()}`;
      const endDate = `${
        date[1].getMonth() + 1
      }/${date[1].getDate()}/${date[1].getFullYear()}`;
      switch (type.value) {
        case "get-occupancy-report":
          setPerformanceInfo();
          setConversionInfo();
          await axios
            .get(`${type.value}?prop_id=${propertyId}`)
            .then((res) => {
              const data = formatTableData(res.data.data, res.data.columns);
              setTableData({
                data: data,
              });
              setColumns(formatColumns(res.data.columns));
            })
            .catch((err) => {
              catchFunc(err);
            });
          break;
        case "get-aged-vacant-report":
          setPerformanceInfo();
          setConversionInfo();
          await axios
            .get(`${type.value}?prop_id=${propertyId}&days=${agedDays}`)
            .then((res) => {
              setTableData(res.data);
              setColumns(formatColumns(res.data.columns));
            })
            .catch((err) => {
              catchFunc(err);
            });
          break;
          case "get-weekly-call-report":
            setPerformanceInfo();
            setConversionInfo();
            await axios
              .get(`${type.value}?prop_id=${propertyId}`)
              .then((res) => {
                setTableData(res.data);
                setColumns(formatColumns(res.data.columns));
              })
              .catch((err) => {
                catchFunc(err);
              });
            break;
          case "get-expiration-management-report":
              setPerformanceInfo();
              setConversionInfo();
              await axios
                .get(`${type.value}?prop_id=${propertyId}`)
                .then((res) => {
                  setTableData(res.data);
                  setColumns(formatColumns(res.data.columns));
                })
                .catch((err) => {
                  catchFunc(err);
                });
            break;
          case "get-weekly-exec-report":
              setPerformanceInfo();
              setConversionInfo();
              await axios
                .get(`${type.value}?prop_id=${propertyId}`)
                .then((res) => {
                  setTableData(res.data);
                  setColumns(formatColumns(res.data.columns));
                })
                .catch((err) => {
                  catchFunc(err);
                });
              break;
          case "get-market-rent":
              setPerformanceInfo();
              setConversionInfo();
              await axios
                .get(`${type.value}?prop_id=${propertyId}`)
                .then((res) => {
                  setTableData(res.data);
                  setColumns(formatColumns(res.data.columns));
                })
                .catch((err) => {
                  catchFunc(err);
                });
              break;
          case "get-pricing-offset-report":
                setPerformanceInfo();
                setConversionInfo();
                await axios
                  .get(`${type.value}?prop_id=${propertyId}`)
                  .then((res) => {
                    setTableData(res.data);
                    setColumns(formatColumns(res.data.columns));
                  })
                  .catch((err) => {
                    catchFunc(err);
                  });
                break;
          case "get-lease-audit-report":
              // let initialDate = null
              // let finalDate = null
              if (leaseAuditDateRange !== null) {
                  initialDate = `${
                        leaseAuditDateRange[0].getMonth() + 1
                      }/${leaseAuditDateRange[0].getDate()}/${leaseAuditDateRange[0].getFullYear()}`;
                  finalDate = `${
                        leaseAuditDateRange[1].getMonth() + 1
                      }/${leaseAuditDateRange[1].getDate()}/${leaseAuditDateRange[1].getFullYear()}`;
              } 
              setPerformanceInfo();
              setConversionInfo();
              await axios
                .get(`${type.value}?prop_id=${propertyId}&initial_date=${initialDate}&final_date=${finalDate}`)
                .then((res) => {
                  setTableData(res.data);
                  setColumns(formatColumns(res.data.columns));
                })
                .catch((err) => {
                  catchFunc(err);
                });
          break;
          case "get-renewal-lease-audit-report":
            if (leaseAuditDateRange !== null) {
                initialDate = `${
                      leaseAuditDateRange[0].getMonth() + 1
                    }/${leaseAuditDateRange[0].getDate()}/${leaseAuditDateRange[0].getFullYear()}`;
                finalDate = `${
                      leaseAuditDateRange[1].getMonth() + 1
                    }/${leaseAuditDateRange[1].getDate()}/${leaseAuditDateRange[1].getFullYear()}`;
            } 
            setPerformanceInfo();
            setConversionInfo();
            await axios
              .get(`${type.value}?prop_id=${propertyId}&start_date=${initialDate}&end_date=${finalDate}`)
              .then((res) => {
                setTableData(res.data);
                setColumns(formatColumns(res.data.columns));
              })
              .catch((err) => {
                catchFunc(err);
              });
        break;
          case "get-pricing-grid-report":
            setPerformanceInfo();
            setConversionInfo();
            await axios
              .get(`${type.value}?prop_id=${propertyId}&date=${startDate}`)
              .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a'); //hide this link
                link.href = url;
                link.setAttribute('download', name + ` -- ${startDate} --`+ 'Pricing Grid Report'+ '.csv' ); //or any other extension
                link.setAttribute('hidden', true);
                document.body.appendChild(link);
                link.click();
                setGridMessage("File Successfully Downloaded")
              })
              .catch((err) => {
                // console.log(err);
                setGridMessage("Sorry, No Pricing Grid Stored For The Given Date.")
              });
            break;
        case "get-renewal-conversion-report":
          setPerformanceInfo();
          setConversionInfo();
          await axios
            .get(
              `${type.value}?prop_id=${propertyId}&start_date=${startDate}&end_date=${endDate}`
            )
            .then((res) => {
              const data = formatTableData(
                res.data.csv.data,
                res.data.csv.columns
              );
              setTableData({
                data: data,
              });
              setColumns(formatColumns(res.data.csv.columns));
              setConversionInfo(res.data.info);
            })
            .catch((err) => {
              catchFunc(err);
            });
          break;
        case "get-renewal-performance-report-v2":
          setPerformanceInfo();
          setConversionInfo();
          await axios
            .get(
              `${type.value}?prop_id=${propertyId}&start_date=${startDate}&end_date=${endDate}`
            )
            .then((res) => {
              const data = formatTableData(
                res.data.csv.data,
                res.data.csv.columns
              );
              setTableData({
                data: data,
              });
              setColumns(formatColumns(res.data.csv.columns));
              getPerformanceInfo(data);
            })
            .catch((err) => {
              catchFunc(err);
            });
          break;
        case "get-performance-analysis-report":
          setPerformanceInfo();
          setConversionInfo();
          await axios
            .get(`${type.value}?prop_id=${propertyId}`)
            .then((res) => {
              const data = formatTableData(res.data.data, res.data.columns);
              setTableData({
                data: data,
              });
              setColumns(formatColumns(res.data.columns));
            })
            .catch((err) => {
              catchFunc(err);
            });
          break;
        default:
          console.log("default");
          break;
      }
      setLoading(false);
    } else {
      console.log("Report type or daterange is missing");
      fetchReports(selectedReport, dateRange);
    }
  };

  useEffect(() => {
    fetchReports(selectedReport, dateRange);
  }, []);

  return (
    <div className="property-report px-4">
      <div className="mt-3 d-flex report-selection justify-content-center">
        <div className="d-flex w-50 align-items-center report-type">
          <h6 className="m-0 fw-normal w-100 mx-2 me-4">
            Select Report Type :
          </h6>
          <Select
            style={{ borderRadius: "16px" }}
            closeMenuOnSelect={true}
            components={animatedComponents}
            defaultValue={selectedReport}
            onChange={(val) => setSelectedReport(val)}
            options={reportsOptions}
            className="w-100"
            maxMenuHeight={150}
          />
        </div>
        <div className="d-flex w-50 date-range justify-content-center align-items-center">
          {selectedReport.label === "Renewal Conversion Report" ||
          selectedReport.label === "Renewal Performance Report" ||
          selectedReport.label === "New Lease Audit Report"  || 
          selectedReport.label === "Renewal Lease Audit Report" ? (
            <>
              <RangePicker
                format={dateFormat}
                onChange={(newValue) => {
                  if (selectedReport.label === "New Lease Audit Report" || selectedReport.label === "Renewal Lease Audit Report" ) {
                    try {
                      setLeaseAuditDateRange([newValue[0]._d, newValue[1]._d])
                    }
                    catch {
                      setLeaseAuditDateRange(null)
                    }
                  }
                  else {
                    try {
                      setDateRange([newValue[0]._d, newValue[1]._d])
                    }
                    catch {
                      setDateRange([now, next30Days])
                    }
                  }
                }}
              />
            </>
          ) : null}
          {selectedReport.label === "Aged Vacant Report" ? (
            <TextField
              label="Days"
              type="number"
              value={agedDays}
              onChange={(e) => setAgedDays(e.target.value)}
              sx={{ borderRadius: 25 }}
            />
          ) : null}
          {
            selectedReport.label === "Pricing Grid Report" ? (
              <DatePicker 
              format={dateFormat}
              onChange={(dateVal) => {
                try {
                setDateRange([dateVal._d, dateVal._d])
                } catch (error) {
                setDateRange([now, now])
                }
              }}
              />
            ):null
          }
        </div>
      </div>
      <SolidPrimaryBtn
        onClick={() => fetchReports(selectedReport, dateRange)}
        className="mt-4 mx-auto"
        text="Get Report"
      />

      <div className="d-flex flex-column justify-content-center mt-5">
        <h5 className="text-start mb-3 fw-bold">{selectedReport.label}</h5>

        {/* {
              Object.keys(info).map((element, idx) => {
                return (
                  <div className="d-flex flex-column justify-content-center" key={idx}>
                    <span className="fw-normal">{element}</span> : <span>{info[element]}</span>
                  </div>
                );
              })
            } */}
        {performanceInfo ? (
          <div className="mx-auto d-flex flex-wrap justify-content-center">
            <div className="d-flex mx-3">
              <h6>Notice :</h6>
              <h6 className="mx-2">{performanceInfo.Notice}</h6>
            </div>
            <div className="d-flex mx-3">
              <h6>Pending :</h6>
              <h6 className="mx-2 ">{performanceInfo.Pending}</h6>
            </div>
            <div className="d-flex mx-3">
              <h6>Renewed :</h6>
              <h6 className="mx-2">{performanceInfo.Renewed}</h6>
            </div>
            <div className="d-flex mx-3">
              <h6>Total Expirations :</h6>
              <h6 className="mx-2">{performanceInfo["Total Expirations"]}</h6>
            </div>
            <div className="d-flex mx-3">
              <h6>Renewal Conversion Rate :</h6>
              <h6 className="mx-2">
                {performanceInfo["Renewal Conversion Rate"]}
              </h6>
            </div>
            <div className="d-flex mx-3">
              <h6>Avg Increase% :</h6>
              <h6 className="mx-2">{performanceInfo["Avg Increase%"]}</h6>
            </div>
          </div>
        ) : null}
        {conversionInfo ? (
          <div className="mx-auto d-flex">
            <div className="d-flex mx-2">
              <h6>Expirations :</h6>
              <h6 className="mx-2">{conversionInfo.Expirations}</h6>
            </div>
            <div className="d-flex mx-2">
              <h6>Total Renewed :</h6>
              <h6 className="mx-2">{conversionInfo.total_renewed}</h6>
            </div>
            <div className="d-flex mx-2">
              <h6>Avg. Increase $ :</h6>
              <h6 className="mx-2 ">${conversionInfo.avg_increase}</h6>
            </div>
            <div className="d-flex mx-2">
              <h6>Conversion rate :</h6>
              <h6 className="mx-2 ">{conversionInfo.conversion_rate}</h6>
            </div>
            <div className="d-flex mx-2">
              <h6>Avg. Increase % :</h6>
              <h6 className="mx-2">{conversionInfo.avg_increase_percent}%</h6>
            </div>
          </div>
        ) : null}

        {loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : 

        gridMessage !== null? (
          <>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <h5>{gridMessage} </h5>
            </div>
          </>
        )
        :
        (
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              data={tableData.data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        )}
      </div>
    </div>
  );
}
