export default function getUnitNumbers(data, variant, setAvailDates) {
  if (data) {
    const expUnits = {};
    const availableDates = {};
    Object.entries(data).forEach((item) => {
      // if (
      //   item[0] === "expiring-in-30-days" ||
      //   item[0] === "expiring-in-60-days" ||
      //   item[0] === "vacant"
      // ) {
        if ( item[0] === "additional") {
        let temp = item[1][variant]
        if (temp !== undefined) {
          temp.forEach((col) => {
            let available_date_UTC = new Date(col.availability_date);
            let available_date = `${
              available_date_UTC.getMonth() + 1
            }/${available_date_UTC.getDate()}/${available_date_UTC.getFullYear()}`;
  
            col.building_name
              ? (availableDates[`${col.unit_number} - ${col.building_name}`] =
                  available_date)
              : (availableDates[col.unit_number] = available_date);
            col.building_name
              ? (expUnits[`${col.unit_number} - ${col.building_name}`] = col.id)
              : (expUnits[col.unit_number] = col.id);
          });
        }
      }
    });
    setAvailDates(availableDates);
    return expUnits;
  } else {
    setTimeout(() => getUnitNumbers(data), 2000);
  }
}
