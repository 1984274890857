import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import {PlusOutlined, MinusOutlined, LoadingOutlined } from '@ant-design/icons';
import Spinner from "../../Spinner";
import  axios  from "../../../auth/Axios";
import swal from 'sweetalert';
import SelectBar from './SelectBar';

const ReportsTable = ({ dataSource, setReportData }) => {
  const [updating, setUpdating] = useState(false)
  const [selectedDays, setSelectedDays] = useState({
    "Availability Report":[],
    "Aged Vacant Report":[],
    "Performance Analysis Report":[],
    "Weekly Call Report":[],
    "Weekly Exec Report":[],
    "Pricing Grid Report":[],
    "Market Rent Report":[]
  });
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Daily"]
  const columns = [
    {
      title: 'Report',
      dataIndex: 'report',
      key: 'report',
      render: (text, record) => (text),
    },
    {
      title: 'Schedule',
      dataIndex: 'scheduled_days',
      key: 'report',
      render: (text, record) => {
        return (
          <>
            {
                <SelectBar days={Days} reportName= {record.report} selected = {record.scheduled_days} setSelectedDays={setSelectedDays}/>
            }
          </>
        )
      },
    },
    {
      title: 'Update',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <>
              {
                  updating?
                  <LoadingOutlined style={{ fontSize: '22px', fontWeight:"bold", color: 'red' }}/>
                  :
                  <PlusOutlined style={{ fontSize: '22px', color: '#52c41a' }} onClick={()=> handleUpdate(record.report, record.action)} />
              }
          </>
        )
      }
    },
  ];

  const handleUpdate = (report,action) => {
    setUpdating(true)
    let daysSelected = selectedDays[report]
    axios.post("report-assignment", {report, action, daysSelected})
    .then(res => {
      setReportData(res.data.response)
      setUpdating(false)
      swal("Success", "Successfully Updated", "success")
    })
    .catch(err => {
      setUpdating(false)
      swal("Error", "An Unknown Error Occured.", "error")
      })
  };


  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={false}
        rowKey={(record) => record.key}
      />
    </>
  );
};


const ReportAssignment = () => {
    const [reportData, setReportData] = useState([]);

    const getReports = () => {
      axios.get("report-assignment")
          .then(res => {
            setReportData(res.data.response)
          })
          .catch (err => {
              setTimeout(() => {
                  getReports();
              }, 2000);
          })
    }

    useEffect(() => {
        getReports();
    },[])

  return (
    <>
        <div  className="ReportAssignmentWrapper"
            style={{
                margin:"50px 40px", 
                width:"90%",
                display:"flex",
                flexDirection:"column",
                gap:"1rem"
            }}
            >
            <div>
                <h3 className="fw-bold mt-4">Schedule Reports</h3>
            </div>

            <div 
            style = {{
              display:"flex",
              justifyContent:"center",
              alignItems:"center"
            }}
            >
              <div className="m-4 mt-4 py-4 shadow border p-3"
                  style={{ borderRadius: "14px", width:"100%" }}
              >
                  {
                      reportData.length === 0
                      ?
                      (
                          <Spinner animation="border" role="status" className="my-5">
                              <h6 className="fw-bold mt-3">
                              Please wait while we load the Report Information...
                              </h6>
                              <span>Crunching some numbers :)</span>
                          </Spinner>
                      )
                      :
                      (
                          <ReportsTable dataSource={reportData} setReportData={setReportData} />
                      )

                  }
              </div>
            </div>
        </div>
    </>
  );
};

export default ReportAssignment;
