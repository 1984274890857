import { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExposureModal from "../Dashboard/Property/ExposureModal";
import { grey } from "@mui/material/colors";

function LPE({ expiryLeases, bed, bath }) {
  const [showModal, setShowModal] = useState(false);
  const [formattedExpLeases, setFormattedExpLeases] = useState([]);
  const formatExpLeasesV2 = (data) => {
    if (data) {
      const expData = [];
      Object.entries(data).forEach((item) => {
          if ( item[0] === "additional") {
          let temp = item[1][`${bed}b-${bath}b`]
          if (temp !== undefined){
            temp.forEach((col) => {
              const row = [];
              const end = col.availability_date
                ? new Date(col.availability_date)
                : "N/A";
              row.push(
                col.unit_number,
                col.unit_type_name,
                col.building_name ? col.building_name : "N/A",
                col.availability_status,
                col.availability_date
                  ? `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear()}`
                  : "N/A"
              );
              expData.push(row);
            });
          }
        }
      });
      return expData;
    } else {
      setTimeout(() => formatExpLeasesV2(expLeases), 2000);
    }
  };
  const expLeases = expiryLeases || {
    "expiring-in-60-days-total": 30,
    "expiring-in-30-days-total": 40,
    "vacant-total": 23,
  };

  let exp_60_days = expLeases["expiring-in-60-days-total"][`${bed}b-${bath}b`]
  let exp_30_days = expLeases["expiring-in-30-days-total"][`${bed}b-${bath}b`]
  let vacant = expLeases["vacant-total"][`${bed}b-${bath}b`]

  const data = {
    labels: ["Expiring (30 Day +)", "Expiring (30 Day)", "Vacant"],
    datasets: [
      {
        label: "Occupancy",
        data: [
          exp_60_days,
          exp_30_days,
          vacant,
        ],
        backgroundColor: ["#FFB067", "#F46141", "#37D159"],
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          font: {
            weight: "bold",
          },
          padding: 10,
        },
      },
    },
  };

  useEffect(() => {
    setFormattedExpLeases(formatExpLeasesV2(expLeases));
  }, [expLeases]);
  return (
    <div
      className="LPE d-flex flex-column bg-white shadow-sm border mt-1 mx-2 px-2 py-1 pb-3"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex mt-2 mb-3 justify-content-center">
        <h6 className="fw-bold w-75 my-auto expo-title ms-4 text-center">
          Exposure
        </h6>
        <IconButton
          sx={{ color: grey[700], border: 1 }}
          onClick={() => setShowModal(true)}
        >
          <VisibilityIcon sx={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
      <div className="flex-column justify-content-center my-4 align-items-center">
        {expiryLeases ? (
          <Pie data={data} options={options} height={270} id="exposure-pie" />
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5 mx-auto">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
      <ExposureModal
        showModal={showModal}
        setShowModal={setShowModal}
        formattedExpLeases={formattedExpLeases}
      />
    </div>
  );
}

export default LPE;
