import React from "react";
import { Line } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";

function LineWOLabels({ title, subTitle, data, options, percent }) {
  return (
    <div
      className="LWOL border shadow-sm flex-fill me-4 w-25 p-4 px-3 d-flex flex-column"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex flex-column mx-3 justify-content-center align-items-center mb-4">
        <h6 className="text-wrap fw-bold m-0 ms-2">{title}</h6>
        <small className="text-wrap fw-normal ms-2">{subTitle}</small>
      </div>
      {data ? (
        <Line data={data} width={30} height={7} options={options} />
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status" className="mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}

export default LineWOLabels;
