import { useState } from "react";
import Outlook from "./Outlook";
import Expirations from "./Expirations";
import SolidPrimaryBtn from "../Buttons/SolidPrimaryBtn";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";

export default function RenewalsSlider({
  propertyName,
  dateRange,
  setDateRange,
  expirations,
  referenceRent,
  unitIds,
  expiringUnits,
  propertyId,
}) {
  const [currentIndex, setCurrent] = useState(0);

  const panels = [
    <Expirations
      propertyName={propertyName}
      expiringUnits={expiringUnits}
      dateRange={dateRange}
      unitIds={unitIds}
      propertyId={propertyId}
      setDateRange={setDateRange}
    />,
    <Outlook expirations={expirations} referenceRent={referenceRent} />,
  ];
  const length = panels.length;

  const nextSlide = () => {
    setCurrent(currentIndex === length - 1 ? 0 : currentIndex + 1);
  };

  const prevSlide = () => {
    setCurrent(currentIndex === 0 ? length - 1 : currentIndex - 1);
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-column justify-content-center">
        {panels[currentIndex]}
      </div>
      <div className="d-flex justify-content-end my-2 mt-4">
        <OutlinedPrimaryBtn onClick={prevSlide} text="Previous" />
        <SolidPrimaryBtn onClick={nextSlide} text="Next" />
      </div>
    </div>
  );
}
