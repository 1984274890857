const marketInsightState = {
    marketProperties : {},
    barChartData : {}, 
    competitorsRent:{},
    competitorsRentByProp:{},
}


const marketReducers = (state = marketInsightState, action) => {
    switch (action.type) {
      case "GET_MARKET_PROPERTIES":
        return {
          ...state,
          marketProperties: action.payload.properties,
        };

        case "GET_BAR_CHART_DATA":
          return {
            ...state,
            barChartData: action.payload.chartData,
          };

          case "GET_ALL_COMPETITORS_RENT":
            return {
              ...state,
              competitorsRent: action.payload.competitorsRent,
            };
          
          case "GET_COMPETITORS_RENT_BY_PROP":
            state.competitorsRentByProp[action.payload.propertyId] = action.payload.competitorsRentByProp;
            return {
              ...state,
              // competitorsRentByProp: action.payload.competitorsRentByProp,
            };

        default:
      return {
        ...state,
      };
  }
};

export default marketReducers