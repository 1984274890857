import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SelectBar({ days, reportName, selected, setSelectedDays }) {
    const [newSelected, setNewSelected] = React.useState(selected)
    React.useEffect(()=> {
        setSelectedDays(prevState => ({
            ...prevState,
            [reportName]: selected
          }));
    }, [])


    const formatDays = () => {
        if (days !== undefined) {
        const allDays = [];
        days.forEach((day, idx) => {
            allDays.push({ value: day, label: day });
        });
        return allDays;
        }
    };

    const handleChange = (val) => {
        setNewSelected(val)
        setSelectedDays(prevState => ({
            ...prevState,
            [reportName]: val
          }));
    }

    const animatedComponents = makeAnimated();
    const options = formatDays();
    return (
        <div className="w-75">
        <Select
            style={{ borderRadius: "16px" }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={newSelected}
            // defaultValue={selected}
            isMulti
            onChange={(val) => handleChange(val)}
            options={options}
        />
        </div>
    );
}
