import React,  { useState } from 'react';
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import MUIDataTable from 'mui-datatables';
import InfoModal from './InfoModal';

const DataTable = ({tableData}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [floorplan, setFloorplan] = useState(null);
  const [propertyName, setPropertyName] = useState(null);
  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none", // Allow selecting a single row
    rowsPerPage: 5,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 15, 20, 50],
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const rowClick = (data, floorplan) => {
    setFloorplan(floorplan);
    setModalOpen(true);
    setPropertyName(data)
  }

  const todays_data = tableData.todays_data
  const columns = [
    {
      name: 'Floorplan',
      label: 'Floorplan',
    },
    {
      name: 'Avg SF',
      label: 'Avg SF',
    },
    {
      name: 'LowRent',
      label: 'Low Rent',
    },
    {
      name: 'HighRent',
      label: 'High Rent',
    },
    {
      name: 'AvgRent',
      label: 'Avg Rent',
    },
    {
      name: 'CompWeight',
      label: 'Comp Weight',
    },
    {
      name: 'AvgRentPsf',
      label: 'Avg Rent PSF',
    },
    {
      name: 'WeightedAvgRent',
      label: 'Weighted Avg Rent ',
    },
    {
      name: 'WeightedAvgRentPsf',
      label: 'Weighted Avg Rent PSF',
    },
  ];
  
  return (
    <div className="bg-white shadow-sm border mt-3"
        style={{
        borderRadius: "14px",
        padding:"20px",
        }}
      >
        <h3 className="fw-bold mt-3">Today's Data</h3>
        {
          Object.entries(todays_data).map((property,idx) => {
            return (
              <div key={idx}
                className="bg-white shadow-sm border mt-3"
                style={{
                borderRadius: "14px",
                padding:"20px",
                }}
                >
                  <ThemeProvider theme={createTheme()}>
                    <MUIDataTable
                        title= {property[0]}
                        data={property[1]}
                        columns={columns}
                        options={
                          {
                            ...options,
                            onRowClick: (e, rowData) => {
                              rowClick(property[0], e[0])
                            },
                          }
                        }
                    />
                  </ThemeProvider>

                  {
                    modalOpen ? 
                      <InfoModal
                        open={modalOpen}
                        handleClose={handleCloseModal}
                        floorplan={floorplan}
                        propertyName={propertyName}
                      />
                      : null
                  }
              </div>
            )
          })
        }
    </div>
  );
};

export default DataTable;
