import React from 'react';
import Spinner from "../Spinner";
import Reviews from "./Reviews";

const ReviewsWrapper = ({reviews, property}) => {
    return  (
        <>
            {
                Object.keys(reviews).length === 0
                ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the User Reviews...
                        </h6>
                        <span>Crunching some numbers :)</span>
                    </Spinner>
                )
                :
                (
                    <Reviews reviews = {reviews[property]}/>
                )
            }
        </>
    )
}

export default ReviewsWrapper;