import LeaseInfoComp from "./LineWOLabels";
import { Spinner } from "react-bootstrap";
import { getDates, reverseArr } from "./Utils/formatters";
export default function UnitLeaseInfo({
  referenceHistoryData,
  applicationsData,
  trendsData,
}) {
  // console.log("REFERENCE RENT HISTORY IN UNIT LEASE INFO: ", referenceHistoryData)
  if (referenceHistoryData === undefined) {
    referenceHistoryData = []
  }

  const dates = getDates();
  const trends = () => {
    return {
      labels: dates,
      datasets: [
        {
          label: "Trends",
          data: trendsData,
          borderColor: "#FF6746",
          borderWidth: 3,
          smooth: true,
        },
      ],
    };
  };

  const applications = () => {
    if (applicationsData) {
      const leadsData = [];
      const dates = [];
      applicationsData.forEach((application, idx) => {
        leadsData.push(application.leads);
        dates.push(application.date);
      });

      return {
        labels: reverseArr(dates),
        datasets: [
          {
            label: "Applications",
            data: reverseArr(leadsData),
            borderColor: "#FF6746",
            borderWidth: 3,
            smooth: true,
          },
        ],
      };
    } else console.log("Applications not found");
  };

  const referenceHistory = {
    labels: dates,
    datasets: [
      {
        label: "Reference Rent History",
        data: referenceHistoryData.slice(335,365),
        borderColor: "#FF6746",
        borderWidth: 3,
        smooth: true,
      },
    ],
  };
  const options = {
    elements: {
      line: {
        tension: 0.6,
      },
    },
    scales: {
      x: {
        display: false,
        // grid: {
        //   display: false,
        //   drawBorder: false,
        //   drawOnChartArea: false,
        //   drawTicks: false,
        // },
      },
      y: {
        // display: false,
      },
    },
    // responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "average",
      },
    },
    scaleShowLabels: false,
  };

  return referenceHistoryData && applicationsData && trendsData ? (
    <div className=" d-flex flex-wrap justify-content-center my-3">
      <LeaseInfoComp
        title="Trend"
        subTitle={"(for past 30 Days)"}
        data={trends}
        options={options}
      />
      <LeaseInfoComp
        title="Applications"
        subTitle={"(for past 12 Months)"}
        data={applications}
        options={options}
      />
      <LeaseInfoComp
        title="Reference Rent History"
        subTitle={"(for past 30 Days)"}
        data={referenceHistory}
        options={options}
      />
    </div>
  ) : (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status" className="my-5 mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
