import {React, useState, useEffect, useContext} from 'react';
import { Modal } from "react-bootstrap";
import { Input } from '@mui/material';
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import CreateIcon from '@mui/icons-material/Create';
import axios from "../../auth/Axios";
import { UpdateParameters } from "../../Actions/propertyActions";
import { useDispatch } from "react-redux";
import { AdminContext } from "../../auth/AdminContext";
import { SuperuserContext } from "../../auth/SuperuserContext";

export const ParamDiv = ({propertyName,val}) => {
  const [showModal, setShowModal] = useState(null)
  const [modalVal, setModalVal] = useState(1)
  const [header, setHeader] = useState('')
  const [updateVal, setUpdateVal] = useState(null)
  const [newVal, setNewVal] = useState(val)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [abc, setAbc] = useState(0)
  const [modalStack, setModalStack] = useState({
    chain : [],
    data: [],
    modalType: []
  })

  const dispatch = useDispatch();
  const { isAdmin, setAdmin } = useContext(AdminContext);
  const { isSuperuser, setSuperuser } = useContext(SuperuserContext);
  useEffect (()=> {
    const modalFunc = () => {
        if (modalStack.chain.length >0) {
        setModalVal(modalStack.data[modalStack.data.length -1])
        setHeader(Capitalise(modalStack.chain[modalStack.chain.length -1]))
        setShowModal(modalStack.modalType[modalStack.modalType.length -1])
        }
    }
    modalFunc();
  },[abc])


  const updateModalStack = (name,property,modalType) => {
    setModalStack ((prev) => {
        return {
            chain:[...prev.chain, name],
            data : [...prev.data,property],
            modalType : [...prev.modalType, modalType]
        }
    })
  }

  const handleHide = () => {

    setModalStack ((prev) => {
        return {
            chain:prev.chain.slice(0,-1),
            data:prev.data.slice(0,-1),
            modalType:prev.modalType.slice(0,-1),
        }
    })
    setShowModal(null)
    setError(null)
    setLoading(false)
    setAbc((prev)=> prev+1)
  }

  const Capitalise =(word) => {
    if (typeof word === "string") {
        if (word === "refrence_rents"){
            return "Reference Rents"
        }
        return word.split("_").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }
    else return word
}

  const ModalFunc = (initiator, name, property) => {
    setHeader(Capitalise(name))
    if (initiator === 0) {
        if (Array.isArray(property))
        {            
            updateModalStack(name,property,"array")
            // setChain((prev) => [...prev, name])
            setModalVal(property)
            setShowModal("array")
        }
        
        else {
            if (typeof (Object.values(property)[0]) === 'object') {
                updateModalStack(name,property,"object")
                // setChain((prev) => [...prev, name])
                setModalVal(property)
                setShowModal("object")
            }
            else {
                setModalVal(property)
                updateModalStack(name,property,"array")
                // setChain((prev) => [...prev, name])
                setShowModal("array")
            }
        }
    }

    else {
        updateModalStack(name,property,"array")
        setUpdateVal(property)
        // setChain((prev) => [...prev, name])
        setShowModal("update")
    }
  }

  const updateState = () => {
    setModalStack((prev) => {
        let data = prev.data[prev.data.length-2]
        data[prev.chain[prev.chain.length -1]] = updateVal
        let res = [...prev.data]
        res[prev.data.length-2] = data

        return {
            chain:prev.chain,
            data:res,
            modalType:prev.modalType
        }

    })
  }

  const updateParam = () => {

        setLoading(true)
        let newUpdateVal = updateVal;
        if (header !== "Goals Start Date" && header !== "Goals End Date" && header !== "Speedometer Segments") {
            newUpdateVal = parseFloat(newUpdateVal)
        }

        axios.post('update-params', {
            chain:modalStack.chain,
            // value:parseFloat(updateVal),
            value:newUpdateVal,
            name : propertyName
        })
        .then(resp => {
            setNewVal(resp.data.param)
            dispatch(UpdateParameters(propertyName,resp.data.param))
            updateState()
            setError("Successfully Updated.")
            setLoading(false)
        })
        .catch(err=> {
            setLoading(false)
            setError("Some Error Occured. Please Try Again.")
        })
  }

    return (
        <>

        {/* modal for object */}
        {
            showModal === "object"? 
            <Modal 
            size={"lg"}
            centered
            style={{
                fontFamily: "Poppins, sans-serif",
                zIndex: 1300,
                maxHeight: "100%",
                width:"100%",
            }}
            show={showModal==="object"}
            onHide={handleHide}
            animation="true">
    
            <Modal.Header closeButton>
                <h4 className="text-center m-0 m-2 fw-bold">{header}</h4>
            </Modal.Header>

            <Modal.Body>
                <div className='parent'>
                    {
                        (modalVal !== null && modalVal !== undefined) && (
                        Object.entries(modalVal).map((item, idx) => {
                            return (
                                <>
                                    <OutlinedPrimaryBtn key = {idx}
                                    className="child"
                                    onClick={()=> {
                                        ModalFunc(0,item[0], item[1])
                                    }}
                                    text = {Capitalise(item[0])}
                                    />
                                </>
                            )}))
                    }
                </div>
            </Modal.Body>
        </Modal> 
        : null
        }


        
        {/* modal for array and other */}
        {
            showModal === "array" ?
            <Modal 
            size={"lg"}
            centered
            style={{
                fontFamily: "Poppins, sans-serif",
                zIndex: 1300,
                maxHeight: "100%",
                width:"100%",
            }}
            show={showModal==="array"}
            onHide={handleHide}
            animation="true">

            <Modal.Header closeButton>
                <h4 className="text-center m-0 m-2 fw-bold">{header}</h4>
            </Modal.Header>

            <Modal.Body>
                <div className="parent">
                {
                    Array.isArray(modalVal) === true ?
                    modalVal.map((item,index) => {
                        return (
                            <div className='child array'>
                                <p key = {index}> <strong> {Capitalise(index)}: </strong> {item} </p> 
                                {
                                    ( isAdmin || isSuperuser) && (
                                        <CreateIcon sx={{ fontSize: 18 }} 
                                        className='create-icon'
                                        onClick= {() => {
                                            ModalFunc(1,index,item)
                                        }}
                                        >
                                        </CreateIcon>
                                    )
                                }
                            </div>
                        )
                    })
                    :
                    Object.entries(modalVal).map((item, index) => {
                    return (
                        <div className='child array'>
                            <p key = {index}> <strong> {Capitalise(item[0])}:</strong> {item[1]} </p> 
                            {
                                ( isAdmin || isSuperuser) && (
                                    <CreateIcon sx={{ fontSize: 18 }}
                                    className='create-icon'
                                    onClick= {() => {
                                        ModalFunc(1,item[0],item[1])
                                    }} />
                                )
                            }
                        </div>
                    )
                    })
                }
                </div>
                </Modal.Body>
            </Modal>
            : null
        }







        {/* modal to update */}

        {
            showModal === "update" ?
            <Modal
            size={"lg"}
            centered
            style={{
                fontFamily: "Poppins, sans-serif",
                zIndex: 1300,
                maxHeight: "100%",
                width:"100%",
            }}
            show={showModal==="update"}
            onHide={handleHide}
            animation="true">

            <Modal.Header closeButton>
                <h4 className="text-center m-0 m-2 fw-bold">{header}</h4>
            </Modal.Header>    

            <Modal.Body>
                <div className=" d-flex justify-content-around m-3">
                    {
                    error !== null ?
                    <p>{error}</p>
                    : null
                    }
                </div>

                <div className=" d-flex justify-content-around">
                    <Input type={"text"} value = {updateVal} onChange={
                        (e) => {
                            setUpdateVal(e.target.value)
                        }
                    } />
                    <OutlinedPrimaryBtn
                        disabled ={loading}
                        onClick={updateParam}
                        text= {loading?'updating':"update"}
                    />
                </div>
            </Modal.Body>
        </Modal>
        : null
        }

        <div className='parent'>
            {
                Object.entries(newVal).map((property, idx) => {
                return (
                    <>
                        <OutlinedPrimaryBtn key = {idx}
                        className = "child"
                        onClick={()=> {
                            ModalFunc(0,property[0], property[1])
                        }}
                        text = {Capitalise(property[0])}
                        />
                    </>
                )})
            }
        </div>
        </>
    )
}