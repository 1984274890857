import React from "react";
import Rhombus from "./assets/Rhombus.gif";
export default function Spinner({ children, className }) {
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${className}`}
    >
      <img src={Rhombus} alt="Loading" style={{ width: "3rem" }} />
      {children}
    </div>
  );
}
