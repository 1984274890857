import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
function Outlook({ expirations, referenceRent }) {
  const [labels, setLabels] = useState([]);
  const [showLine, setShowLine] = useState(true);
  const options = {
    interaction: {
      // mode: "x",
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label + " Days";
          },
          afterLabel: (tooltipItem) => {
            if (
              tooltipItem.dataset.label === "Signed Renewals" ||
              tooltipItem.dataset.label === "Notices" ||
              tooltipItem.dataset.label === "Pending Expirations"
            ) {
              const pointer =
                tooltipItem.dataset.label === "Signed Renewals"
                  ? expirations.rawSigned[tooltipItem.dataIndex]
                  : tooltipItem.dataset.label === "Notices"
                  ? expirations.rawNotice[tooltipItem.dataIndex]
                  : tooltipItem.dataset.label === "Pending Expirations"
                  ? expirations.rawPending[tooltipItem.dataIndex]
                  : null;
              const data = [
                "Unit Number : " + pointer.unit,
                "End Date : " + pointer.end,
                pointer.building ? "Building :" + pointer.building : null,
              ];
              return data;
            }
            return null;
          },
        },
      },
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          font: {
            size: 13,
            weight: "bold",
          },
          padding: 20,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins", // Your font family
          },
        },
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Reference Rent",
        data: Array(labels.length).fill(referenceRent),
        borderColor: "#0997F2",
        backgroundColor: "#0997F2",
        order: 0,
        borderWidth: 2,
        smooth: true,
      },
      {
        label: "Notices",
        data: expirations.noticeData,
        backgroundColor: "#FF507A",
        order: 2,
        type: "scatter",
        pointRadius: [5, 0, 5, 5],
        borderRadius: "16px",
      },
      {
        label: "Pending Expirations",
        // data: Array.from({ length: 10 }, () => ({
        //   x: faker.datatype.number({ min: 0, max: 100 }),
        //   y: faker.datatype.number({ min: 0, max: 100 }),
        // })),
        data: expirations.pendingData,
        backgroundColor: "#FFB067",
        type: "scatter",
        order: 1,
        borderRadius: "16px",
        pointRadius: [5, 0, 5, 5],
      },
      {
        label: "Signed Renewals",
        data: expirations.signedData,
        backgroundColor: "#37D159",
        type: "scatter",
        order: 3,
        borderRadius: "16px",
        pointRadius: [5, 0, 5, 5],
      },
    ],
  };
  const scatterLabels = (notice, signed, pending) => {
    if ((notice, signed, pending)) {
      const noticeDates = [];
      notice.forEach((item) => {
        noticeDates.push(item.x);
      });
      const signedDates = [];
      signed.forEach((item) => {
        signedDates.push(item.x);
      });
      const pendingDates = [];
      pending.forEach((item) => {
        pendingDates.push(item.x);
      });
      const labels = [];
      noticeDates.forEach((item, idx) => {
        if (!signedDates.includes(item)) {
          labels.push(item);
        } else return;
      });
      signedDates.forEach((item, idx) => {
        if (!labels.includes(item)) {
          labels.push(item);
        } else return;
      });
      pendingDates.forEach((item, idx) => {
        if (!labels.includes(item)) {
          labels.push(item);
        } else return;
      });
      return labels.sort((a, b) => a - b);
    } else {
      console.log("notice, signed not found");
    }
  };
  useEffect(() => {
    setLabels(
      scatterLabels(
        expirations.noticeData,
        expirations.signedData,
        expirations.pendingData
      )
    );
  }, [expirations]);
  const referenceData = (labels, ref) => {
    if (labels) {
      const refData = [];
      labels.forEach((label) => {
        refData.push({ x: label, y: ref });
      });
      return refData;
    } else {
      setTimeout(() => referenceData(labels), 3000);
    }
  };
  const apexOptions = {
    series: [
      {
        name: "Notices",
        type: "scatter",
        data: expirations.noticeData,
      },
      {
        name: "Pending Expirations",
        type: "scatter",
        data: expirations.pendingData,
      },
      {
        name: "Signed Renewals",
        type: "scatter",
        data: expirations.signedData,
      },
      {
        name: "Reference Rent",
        type: "line",
        data: referenceData(labels, referenceRent),
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 350,
        type: "line",
      },
      colors: ["#FF507A", "#FFB067", "#37D159", "#0997F2"],
      fill: {
        type: "solid",
      },
      markers: {
        size: [7, 7, 7, 0],
      },

      legend: {
        show: true,
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
        offsetY: 5,
      },
      xaxis: {
        type: "numeric",
        labels: {
          formatter: function (val, opts) {
            return val.toFixed(0) + " Days";
          },
        },
      },
      tooltip: {
        theme: "dark",
        shared: false,
        intersect: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const name = apexOptions.series[seriesIndex].name;
          const data = series[seriesIndex][dataPointIndex];
          if (
            name === "Signed Renewals" ||
            name === "Notices" ||
            name === "Pending Expirations"
          ) {
            const pointer =
              name === "Signed Renewals"
                ? expirations.rawSigned[dataPointIndex]
                : name === "Notices"
                ? expirations.rawNotice[dataPointIndex]
                : name === "Pending Expirations"
                ? expirations.rawPending[dataPointIndex]
                : null;
            return (
              "<div className='outlook-tooltip'>" +
              "<p className='item'>" +
              "Rent : $" +
              data +
              "</p>" +
              "<p> Unit Number : " +
              pointer.unit +
              "</p>" +
              "<p> End Date : " +
              pointer.end +
              "</p>" +
              "<p> Building : " +
              pointer.building +
              "</p>" +
              "</div>"
            );
          } else {
            return (
              "<div className='outlook-tooltip'>" +
              "<p className='item'>" +
              name +
              " : " +
              data +
              "</p>" +
              "</div>"
            );
          }
        },
        x: {
          show: true,
        },
      },
    },
  };
  useEffect(() => {
    setTimeout(() => setShowLine(false), 1);
  }, []);
  return (
    <div
      className="bg-white px-3 shadow-sm border mx-auto py-4 my-2 mt-4"
      style={{ borderRadius: "14px", width: "95%" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column my-2 mx-4">
          <h4 className="m-0 fw-bold">90 Day Outlook</h4>
          <span className="text-muted">
            Signed renewals, pending expirations & new lease pricing
          </span>
        </div>
      </div>
      <div id="chart"></div>

      {showLine ? (
        <Line options={options} width={30} height={10} data={data} />
      ) : (
        <ReactApexChart
          series={apexOptions.series}
          options={apexOptions.options}
          type="line"
          height={350}
        />
      )}
      {/* <ReactApexChart
        series={apexOptions.series}
        options={apexOptions.options}
        type="line"
        height={350}
      /> */}
      {/* {apexOptions.series.length !== 0 ? (
        <ReactApexChart
          series={apexOptions.series}
          options={apexOptions.options}
          type="line"
          height={350}
        />
      ) : (
        <div className="d-flex w-100 justify-content-center">
          <Spinner animation="border" role="status" className="my-5">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )} */}
    </div>
  );
}

export default Outlook;
