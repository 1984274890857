import axios from "../auth/Axios";

export const loadMarketProperties = () => async (dispatch) => {
    let properties;
    try {
      await axios.get("market/subjectproperties").then((res) => {
        properties = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadMarketProperties();
      }, 5000);
    }
    dispatch({
      type: "GET_MARKET_PROPERTIES",
      payload: {
        properties: Object.fromEntries(Object.entries(properties).sort()),
      },
    });
  };


  export const loadBarChartsData = () => async (dispatch) => {
    let chartData;
    try {
      await axios.get("market/get-bar-chart-data").then((res) => {
        chartData = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadBarChartsData();
      }, 5000);
    }
    dispatch({
      type: "GET_BAR_CHART_DATA",
      payload: {
        chartData: chartData,
      },
    });
  };

  export const loadCompetitorsRent = () => async (dispatch) => {
    let competitorsRent;
    try {
      await axios.get("market/get-all-competitors-rent").then((res) => {
        competitorsRent = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadCompetitorsRent();
      }, 5000);
    }
    dispatch({
      type: "GET_ALL_COMPETITORS_RENT",
      payload: {
        competitorsRent: competitorsRent,
      },
    });
  };

  export const loadCompetitorsRentByProp = (propId) => async(dispatch) => {
    let competitorsRentByProp;
    try {
      await axios.get(`market/get-competitors-rent-by-prop?propId=${propId}`).then((res) => {
        competitorsRentByProp = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadCompetitorsRent();
      }, 5000);
    }
    dispatch({
      type: "GET_COMPETITORS_RENT_BY_PROP",
      payload: {
        competitorsRentByProp: competitorsRentByProp,
        propertyId: propId,
      },
    });

  }