import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import StarRatings from 'react-star-ratings';

const Reviews = ({reviews}) => {

    const[days,setDays] = useState(365);
    const[userReviews, setUserReviews] = useState(JSON.parse(JSON.stringify(reviews)))
    const[newoptions,setNewptions] = useState({});

    const handleDaysChange = (event,newAlignment) => {
        if (newAlignment === null || newAlignment === undefined ){
            return
        }
        setDays(parseInt(newAlignment));
        setUserReviews(JSON.parse(JSON.stringify(reviews)))
    }

    const handleStarChange = (newAlignment,property) => {
        if (newAlignment === null || newAlignment === undefined ){
            return
        }
        setNewptions(prevState => {
            let temp = {
                ...prevState,
            }
            temp[property] = newAlignment
            return temp
        })
        let review = reviews[property]['reviews'][days]
        if (newAlignment !== "all"){
            review = review.filter((item,idx) => item.ratings === parseInt(newAlignment))
        }
        let newUserReview = JSON.parse(JSON.stringify(userReviews))
        newUserReview[property]['reviews'][days] = review
        setUserReviews(newUserReview)
    }

    function filterRatings(item,property) {
        if (newoptions[property] === "all" ){
            return true
        }
        return item.ratings === parseInt(newoptions[property])
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();
        return `${month}, ${year}`;
    }

    useEffect(() => {
        if (reviews) {
          const keys = Object.keys(reviews);
          const updatedObject = {};
          keys.forEach(key => {
            updatedObject[key] = 'all';
          });
          setNewptions(updatedObject);
        }
      }, [reviews]);

    return (

        <div
        className="bg-white occupancy shadow-sm border mx-2 my-3"
        style={{
        borderRadius: '14px',
        padding:"50px",
        }}
        >

        <div style = {{
            display:"flex",
            flexDirection:"column",
        }}>
            <div style = {{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between",
                width:"100%"
            }}>
                <h4 className="fw-bold mt-3 mx-3">Reputation Activity</h4>
                <ToggleButtonGroup
                    color="primary"
                    value={`${days}`}
                    exclusive
                    onChange={handleDaysChange}
                    aria-label="Platform"
                    >
                    <ToggleButton value="30">30</ToggleButton>
                    <ToggleButton value="60">60</ToggleButton>
                    <ToggleButton value="90">90</ToggleButton>
                    <ToggleButton value="365">365</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {
                Object.entries(userReviews).map((property,idx)=> {

                    return(
                        <div key={idx} className="bg-white occupancy shadow-sm border mx-2 my-3"
                        style={{
                        borderRadius: "14px", 
                        display:"flex",
                        flexDirection:"column"
                        }} 
                    >
                        <div style = {{
                            display:"flex",
                            flexDirection:"row",
                            justifyContent:"space-between",
                            width:"100%"
                        }}>
                            <h6 className="fw-bold mt-3 mx-3">{property[0]}</h6>
                        </div>
        
                        <div className="review_section" style={{
                            display:"flex",
                            flexDirection:"row",
                            gap:"3rem",
                            margin:"3vh 2vh 0vh 2vh",
        
                        }}>
                            <div className="left_section">
                                <div style={{
                                    display:"flex",
                                    flexDirection:"row",
                                    gap:"3rem",
                                }}>
                                    <div>
                                        <h6 className="fw-bold mt-3 "> Score</h6>
                                        <p>5 Star</p>
                                        <p>4 Star</p>
                                        <p>3 Star</p>
                                        <p>2 Star</p>
                                        <p>1 Star</p>
                                    </div>
        
                                    <div>
                                        <h6 className="fw-bold mt-3 ">Count</h6>
                                        {
                                            property[1].ratings[days].map((rating,idx) => {
                                                return(
                                                    <p key={idx}>{rating}</p>
                                                )
                                            })
                                        }
                                    </div>

                                    <div>
                                        <h6 className="fw-bold mt-3 ">%</h6>
                                        {
                                            property[1].percentage[days].map((percent,idx) => {
                                                return(
                                                    <p key={idx}>{percent}</p>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
        
                            <div className="right_section" style = {{
                            }}>
                                <div style={{
                                    display:"flex",
                                    flexDirection:"row",
                                    alignItems:"center",
                                    gap:"1rem",
                                    width:"100%"
                                }}>
                                    <h6 className="fw-bold mt-3 mx-3">Recent Reviews</h6>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={`${newoptions[property[0]]}`}
                                            exclusive
                                            onChange={(event, newAlignment) => {
                                                handleStarChange(newAlignment,property[0])
                                            }}
                                            aria-label="Platform"
                                            >
                                            <ToggleButton value="all">all</ToggleButton>
                                            <ToggleButton value="5">5</ToggleButton>
                                            <ToggleButton value="4">4</ToggleButton>
                                            <ToggleButton value="3">3</ToggleButton>
                                            <ToggleButton value="2">2</ToggleButton>
                                            <ToggleButton value="1">1</ToggleButton>
                                        </ToggleButtonGroup>
                                </div>
        
                                <div style={{
                                    margin:"2vh 0vh",
                                    height: '150px',
                                    overflowY: 'auto',
                                }}>
                                    {
                                        property[1].reviews[days].filter(item => filterRatings(item,property[0])).map((review,idx)=> {
                                            return (
                                                <div key={idx}>
                                                    <span style={{ display:"flex", 
                                                        gap:"1.5rem", 
                                                        alignItems:"center"
                                                    }}>
                                                        <p className=''> <i> <u>{`${formatDate(review.date)}`}</u> </i></p>
                                                        <div style={{
                                                            marginTop:"-2vh"
                                                        }}>
                                                            <StarRatings
                                                            rating={5}
                                                            starDimension="20px"
                                                            starSpacing="5px"
                                                            starRatedColor="gold"
                                                            numberOfStars={review.ratings}
                                                            />
                                                        </div>
                                                    </span>
                                                    <span>
                                                        <p> {review.text} </p>
                                                    </span>
                                                </div>
                                            )})
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    )})}

        </div>
    </div>
    )
}

export default Reviews