import React from "react";
import Pin from "../assets/MapPin.svg";
import PropertyReport from "./PropertyReport";
export default function ReportsProperty({ propertyData, name, propertyId }) {
  return (
    <div
      className="d-flex m-0 mt-4 py-4 shadow border p-3 w-100"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex flex-column w-100">
        <div className="property-details d-flex flex-row justify-content-start">
          <span className="m-0 ms-2 fw-bold fs-4">{name}</span>
          <div className="d-flex mx-3 align-items-center">
            <img src={Pin} alt="pin" />
            <span className="text-muted">{propertyData.address}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <PropertyReport propertyId={propertyId} name = {name}/>
        </div>
      </div>
    </div>
  );
}
