/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Pin from "../assets/MapPin.svg";
import axios from "../../auth/Axios";
import { Spinner } from "react-bootstrap";
import Outlook from "./Outlook";
import RenewalsSlider from "./RenewalsSlider";
import Expirations from "./Expirations";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import sync from "../assets/sync.png";
import doneIcon from "../assets/done.png";
import { useSelector } from "react-redux";

function LeaseRenewalsProperty({
  name,
  location,
  propertyId,
  referenceRent,
  setDateRange,
  dateRange,
  rawExpirations,
}) {
  const [isLoading, setLoading] = useState(false);
  const [expirations, setExpirations] = useState([]);
  const [formattedExpiringUnits, setFormattedExpiringUnits] = useState([]);
  const [formattedReferenceRents, setFormattedReferenceRents] = useState({});
  const [unitIds, setUnitIds] = useState({});
  const [showMore, setShowMore] = useState(false);
  const handleClick = () => {
    setShowMore(!showMore);
  };
  
  const expiringUnits = useSelector(
    (state) => state.propertyReducer.expiringUnits[name]
  );
  const getReferenceRents = (data) => {
    if (data) {
      const obj = {};
      Object.entries(data).forEach((ref) => {
        if (ref[0] === "id") {
        } else {
          obj[ref[0]] = ref[1].refrence_rent;
        }
      });
      return obj;
    } else {
      console.log("Reference Rent not found");
    }
  };
  const formatExpirations = (data) => {
    if (data) {
      const raw = Object.entries(data).map((variant) => {
        return variant[1];
      });
      const now1 = new Date();
      const getX = (d) => {
        return parseInt((new Date(d) - now1) / (1000 * 60 * 60 * 24), 10);
      };
      const notice = [];
      const signed = [];
      const pending = [];
      const rawNotice = [];
      const rawSigned = [];
      const rawPending = [];
      raw.forEach((type) => {
        type.notice.forEach((item, idx) => {
          notice.push({ x: getX(item.end_date), y: item.rent });
          rawNotice.push({
            unit: item.unit_number,
            end: item.end_date,
            building: item.building,
          });
        });
        type.signed_renewals.forEach((item) => {
          signed.push({ x: getX(item.end_date), y: item.rent });
          rawSigned.push({
            unit: item.unit_number,
            end: item.end_date,
            building: item.building,
          });
        });
        type.pending_expirations.forEach((item) => {
          pending.push({ x: getX(item.end_date), y: item.rent });
          rawPending.push({
            unit: item.unit_number,
            end: item.end_date,
            building: item.building,
          });
        });
      });
      return {
        noticeData: notice.sort(function (a, b) {
          return a.x - b.x;
        }),
        rawNotice: rawNotice.sort((a, b) => {
          return getX(a.end) - getX(b.end);
        }),
        signedData: signed.sort(function (a, b) {
          return a.x - b.x;
        }),
        rawSigned: rawSigned.sort((a, b) => {
          return getX(a.end) - getX(b.end);
        }),
        pendingData: pending.sort(function (a, b) {
          return a.x - b.x;
        }),
        rawPending: rawPending.sort((a, b) => {
          return getX(a.end) - getX(b.end);
        }),
      };
    } else {
      formatExpirations(rawExpirations);
    }
  };
  const fetchExpirationsRange = async (dateRange) => {
    setLoading(true);
    const startDate = `${
      dateRange[0].getMonth() + 1
    }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`;
    const endDate = `${
      dateRange[1].getMonth() + 1
    }/${dateRange[1].getDate()}/${dateRange[1].getFullYear()}`;
    await axios
      .get(
        `get-expirations-in-range?start_date=${startDate}&end_date=${endDate}`
      )
      .then((res) => {
        setExpirations(formatExpirations(res.data[name]));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          fetchExpirationsRange();
        }, 5000);
      });
  };
  // const formatExpiringUnits = (data) => {
  //   if (data) {
  //     const syncing = <img src={sync} style={{ width: "40px" }} alt="sync" />;
  //     const done = <img src={doneIcon} style={{ width: "35px" }} alt="done" />;
  //     const columns = [
  //       "unit_number",
  //       "building_name",
  //       "unit_type",
  //       "current_rent",
  //       "new_rent",
  //       "new_lease_price",
  //       "discount_to_pricing",
  //       "increase%",
  //       "increase_amount",
  //       "lease_end_date",
  //       "synced",
  //     ];
  //     const rowData = [];
  //     data.forEach((item, idx) => {
  //       const row = [];
  //       columns.forEach((column, idx) => {
  //         if (idx === 3 || idx === 4 || idx === 5 || idx === 8) {
  //           row.push(`$${Math.round(item[column])}`);
  //         } else if (idx === 7) {
  //           row.push(`${item[column]}%`);
  //         } else if (idx === 6) {
  //           row.push(`$${Math.round(item.new_lease_price - item.new_rent)}`);
  //         } else if (idx === 10) {
  //           item[column] ? row.push(done) : row.push(syncing);
  //         } else {
  //           row.push(item[column]);
  //         }
  //       });
  //       rowData.push(row);
  //     });
  //     return rowData;
  //   } else {
  //     console.log("expiringUnits not found");
  //     setTimeout(() => formatExpiringUnits(expiringUnits), 5000);
  //   }
  // };
  const getUnitIds = (data) => {
    if (data) {
      const obj = {};
      data.forEach((item) => {
        obj[item.unit_number] = item.unit_id;
      });
      setUnitIds(obj);
    }
  };

  useEffect(() => {
    setFormattedReferenceRents(getReferenceRents(referenceRent));
  }, [referenceRent]);
  useEffect(() => {
    setExpirations(formatExpirations(rawExpirations));
  }, [rawExpirations]);
  useEffect(() => {
    // setFormattedExpiringUnits(formatExpiringUnits(expiringUnits));
    getUnitIds(expiringUnits);
  }, [expiringUnits]);

  return (
    <div
      className="bg-white border shadow px-3 my-2 py-4 mb-4 w-100"
      style={{ borderRadius: "14px" }}
    >
      {!isLoading ? (
        <div className="d-flex flex-column">
          <div className="d-flex ms-2 flex-row">
            <h4 className="m-0 fw-bold">{name}</h4>
            <div className="d-flex mx-3 align-items-center">
              <img src={Pin} alt="pin" />
              <span className="text-muted">{location}</span>
            </div>
          </div>
          {/* <div className="d-flex property-misc justify-content-end">
            <OutlinedPrimaryBtn
              onClick={handleClick}
              className="more-btn"
              text={`${showMore ? "Collapse" : "More"}`}
            />
          </div> */}
          <div className="d-flex align-items-stretch">
            <div className="d-flex flex-column"></div>
            {!isLoading && expirations.length !== 0 && showMore ? (
              <div className="w-100">
                <Expirations
                  propertyName={name}
                  propertyId={propertyId}
                  expiringUnits={expiringUnits}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  unitIds={unitIds}
                />
                {expirations.length !== 0 ? (
                  <Outlook
                    expirations={expirations}
                    referenceRent={formattedReferenceRents}
                  />
                ) : null}
              </div>
            ) : !isLoading && expirations ? (
              <RenewalsSlider
                propertyName={name}
                propertyId={propertyId}
                expiringUnits={expiringUnits}
                expirations={expirations}
                referenceRent={formattedReferenceRents}
                dateRange={dateRange}
                unitIds={unitIds}
                setDateRange={setDateRange}
              />
            ) : (
              <div className="d-flex justify-content-center w-100">
                <Spinner animation="border" role="status" className="my-5">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex w-100 justify-content-center">
          <Spinner animation="border" role="status" className="my-5">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}

export default LeaseRenewalsProperty;
