import React, { useEffect, useState } from "react";
import CompetitorChart from "./CompetitorChart";

export default function CompWrapper ({
  competitors,
  bednum,
  bathnum,
}) {

    const [formattedCompetitors, setFormattedCompetitors] = useState([]);

    const formatCompetitors_v2 = (data) => {
        if (
          data && 
          Object.keys(data).length !== 0 &&
          data[`${bednum}b-${bathnum}b`] !== null &&
          bednum !== null &&
          bathnum !== null &&
          Object.keys(data[`${bednum}b-${bathnum}b`]).length !== 0
        ) {
          var formattedCompetitors = {
            psf: [],
            comp_psf: [],
            labels: [],
            effective_rent: [],
            comp_effective_rent: [],
            dates:[]
          };
          Object.keys(data[`${bednum}b-${bathnum}b`])
            .sort((a, b) => Date.parse(a) - Date.parse(b))
            .forEach((key, idx) => {
              var temp = new Date(key);
              const d = `${
                temp.getMonth() + 1
              }/${temp.getDate()}/${temp.getFullYear()}`;
    
              if(data[`${bednum}b-${bathnum}b`][key].effective_rent === 0){
                return;
              }
    
              formattedCompetitors.psf.push(
                data[`${bednum}b-${bathnum}b`][key].psf
              );
              formattedCompetitors.comp_psf.push(
                data[`${bednum}b-${bathnum}b`][key].comp_psf
              );
              formattedCompetitors.effective_rent.push(
                data[`${bednum}b-${bathnum}b`][key].effective_rent
              );
              formattedCompetitors.comp_effective_rent.push(
                data[`${bednum}b-${bathnum}b`][key].comp_effective_rent
              );
              formattedCompetitors.labels.push(
                `${temp.getMonth() + 1}/${temp.getDate()}`
              );
              formattedCompetitors.dates.push(d)
    
            });
          return formattedCompetitors;
        } else {
          console.log("No Competitors found");
        }
      };
    
      useEffect(() => {
        setFormattedCompetitors(formatCompetitors_v2(competitors));
      }, [competitors]);
      

      return (
        <>
            {
                formattedCompetitors !== undefined &&
                formattedCompetitors !== null &&
                Object.keys(formattedCompetitors).length !== 0 ? (
                <CompetitorChart competitors={formattedCompetitors} />
                ) : (
                <h5 className="fw-bold text-center h-100 my-auto w-100">
                    Competitors Not found
                </h5>
                )
            }
        </>
      )


}

