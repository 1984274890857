import React, { useState, useEffect } from 'react';
import MultiLineChart from "./LowerChart";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import { loadCompetitorsRentByProp } from "../../Actions/marketInsightActions";

const RentChartWrapper = ({propertyId, propName, selectOptions, valid_floorplans}) => {
    const dispatch = useDispatch();
    const compRentByProp = useSelector((state) => state.marketReducers.competitorsRentByProp[propertyId]);

    const fetchRentByIds = () => {
        if (!compRentByProp || Object.keys(compRentByProp).length === 0) {
            dispatch(loadCompetitorsRentByProp(propertyId));
          }
    }

    useEffect(()=> {
        fetchRentByIds();
    },[])

    return (
        <div>
            {/* {
                competitorsRent===undefined || Object.keys(competitorsRent).length === 0 ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the Competitors Chart Properties...
                        </h6>
                        <span>Crunching some numbers :)</span>
                    </Spinner>
                )
                :
                (
                    <MultiLineChart 
                        rentData={competitorsRent[propName]} 
                        propName={propName}
                        dates={competitorsRent["dates"]}
                        selectOptions={selectOptions}
                        valid_floorplans={valid_floorplans}
                    /> 
                )
            } */}

            {
                compRentByProp===undefined || Object.keys(compRentByProp).length === 0 ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the Competitors Chart...
                        </h6>
                        <span>Crunching some numbers :)</span>
                    </Spinner>
                )
                :
                (
                    <MultiLineChart 
                        rentData={compRentByProp[propName]} 
                        propName={propName}
                        dates={compRentByProp["dates"]}
                        selectOptions={selectOptions}
                        valid_floorplans={valid_floorplans}
                    /> 
                )
            }
        </div>
    )

};

export default RentChartWrapper;