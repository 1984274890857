import React from "react";
import Bed from "./bed.svg";
import Bathroom from "./bathroom.svg";
import "./propertyInfo.css";
function PropertyInfo({ bednum, bathnum }) {
  return (
    <div
      size="lg"
      variant="primary"
      style={{
        backgroundColor: "#CEEAFC",
        color: "#0997F2",
        borderRadius: "22px",
        border: "1px",
        fontSize: "14px",
      }}
      className="d-flex property-info mx-2 me-3 my-2 py-2 px-2 align-items-center"
    >
      <div className="d-flex mx-2 bed">
        <img src={Bed} className="mx-2" alt="bedroom" />
        <span className="fw-bold text-center">{bednum} Bedroom</span>
      </div>
      <div className="d-flex mx-2 bath">
        <img src={Bathroom} className="mx-2" alt="bathroom" />
        <span className="fw-bold text-center">{bathnum} Bathroom</span>
      </div>
    </div>
  );
}

export default PropertyInfo;
