import { combineReducers } from "redux";
import propertyReducer from "./propertyReducer";
import marketReducers from "./MarketReducers";
import reputationReducers from "./ReputationReducer";

const appReducer = combineReducers({ propertyReducer, marketReducers, reputationReducers });

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
