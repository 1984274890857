import React from "react";
import { Line } from "react-chartjs-2";
import { Switch, Space } from 'antd';

export default function ExpirationChart({ exp, anticipatedRenConv, renewalOutlook, renewalOutlookLabels }) {
  const [toggle, setToggle] = React.useState(false)
  const anticipatedExp = exp.expirations.map(expiration => expiration - (expiration * anticipatedRenConv) );

  function forecastExpirations(expiration, index) {
    if (index < 12) {
      return 0
    }
    if (index >= 12 && index < 18){
      return expiration + anticipatedExp[index - 12]
    }
    return expiration
  }
const forecastedExp = anticipatedExp.map((expiration, index) => forecastExpirations(expiration, index))

const handleSwitch = (value) => {
  setToggle(value)
}
  const expirationsData = {
    labels: exp.labels,
    datasets: [
      {
        label: "Threshold",
        data: exp.thresholds,
        borderColor: "#FF507A",
        backgroundColor: "#FF507A",
        type: "line",
        yAxisID: 'y',
        order: 0,
      },
      {
        label: "Expiration",
        data: exp.expirations,
        backgroundColor: "rgba(243, 156, 18, 0.8)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 3,
      },
      {
        label: "Anticipated",
        data: anticipatedExp,
        backgroundColor: "rgba(72, 201, 176, 0.8)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 1,
      },
      {
        label: "Forecasted",
        data: forecastedExp,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        maxBarThickness: 20,
        borderRadius: 15,
        type: "bar",
        yAxisID: 'y',
        stack: 'stack1',
        order: 2,
      },
    ],
  };

  const expirationsOptions = {
    plugins: {
      legend: {
        display: true, // Set to true to display the legend
        labels: {
          font: {
            family: "Poppins",
            weight: 'bold', 
          },
        },
      },
    },
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };


  const outlookData = {
    labels: renewalOutlookLabels,
    datasets: [
      {
        label: "Pending",
        data: renewalOutlook["pending"],
        backgroundColor: "rgba(243, 156, 18, 0.8)",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 1,
      },
      {
        label: "On Notice",
        data: renewalOutlook["notice"],
        backgroundColor: "#FF507A",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 0,
      },
      {
        label: "Renewed",
        data: renewalOutlook["renewed"],
        backgroundColor: "rgba(72, 201, 176, 0.8)",
        pointStyle: 'circle',
        pointRadius: 6,
        type: "scatter",
        yAxisID: 'y',
        stack: 'stack1',
        order: 2,
      },
      {
        label: "60 Days Avg Retention",
        data: renewalOutlookLabels.map(() => renewalOutlook["avg_retention"]),
        borderColor: "rgba(0, 123, 255, 0.8)",
        backgroundColor: "rgba(0, 123, 255, 0.8)",
        borderWidth: 3,
        pointRadius: 0,
        fill: false,
        type: "line",
        yAxisID: 'y1',
        order: 3,
      },
    ],
  };


  const outlookOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            family: "Poppins",
            weight: 'bold', 
          },
        },
      },
    },
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y1: {
        position: 'right',
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          max: 100,
          font: {
            family: "Poppins",
          },
          callback: function(value) {
            return value + "%"; // Append percentage sign
          },
        },
      },
    },
  };

  return (
    <div
      className="bg-white px-3   shadow-sm border mx-auto py-4 my-2 mt-4"
      style={{ borderRadius: "14px", width: "95%" }}
    >
      <div className="d-flex justify-content-between mb-5 mx-4">
        <div>
          <h4 className="m-0 fw-bold">
            {!toggle ? "Expiration Charts" : "Renewal Outlook Chart"}
          </h4>
          <span>
          {!toggle ? "Actual and Anticipated V/s Threshold" : "On Notice V/s Renewed V/s Pending"}
          </span>
        </div>
        <Space direction="vertical">
          <Switch checkedChildren="Renewal Outlook" unCheckedChildren="Expirations"  onChange={handleSwitch} />
        </Space>
      </div>


      {!toggle? 
        <Line width={30} height={10} options={expirationsOptions} data={expirationsData} />
      :
        <Line width={30} height={10} options={outlookOptions} data={outlookData} />
      }
    </div>
  );
}
