import React from "react";
import BlueCircularProgressBar from "../../../Progress Bar/BlueCircularProgressBar";

function Occupancy({ occupiedRate, occupiedUnits }) {
  // const data = {
  //   labels: ["Occupied", "Unoccupied"],
  //   datasets: [
  //     {
  //       label: "Occupancy",
  //       data: [71, 29],
  //       backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
  //       borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  return (
    <div
      className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
      style={{
        // width: "318px",
        height: "100%",
        borderRadius: "14px",
      }}
    >
      <h6 className="fw-bold mt-3 text-center">Occupancy</h6>
      <div className="d-flex justify-content-around my-auto px-2">
        <div className="d-flex flex-column fw-bold">
          <h4 className="m-0 fw-bold">{occupiedUnits}</h4>
          <span>Units Occupied</span>
        </div>
        <BlueCircularProgressBar
          strokeWidth="8"
          sqSize="90"
          percentage={occupiedRate}
        />
      </div>
    </div>
  );
}

export default Occupancy;
