import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const ReputationHistory = ({labels,dataset}) => {

  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
      {
        label: labels[0].slice(0,10),
        borderColor: '#339966',
        borderWidth: 3,
        fill: false,
        data : []
      },
    ],
  })

  const colorCodes = [
    '#cc33ff', // Purple
    '#ff3366', // Pink
    '#339966',  // Teal
    '#ff9933', // Orange
    '#66cccc', // Turquoise
    '#cccc66', // Olive
    '#993333', // Maroon
    '#ff5733', // Red
    '#ffcc33', // Yellow
    '#33ccff', // Blue
    '#33ff57', // Green
    '#9966cc', // Lavender
  ];
  
  const options = {
    maintainAspectRatio: false, // To allow chart to be centered
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const formatData = () => {
    const datasets = Object.entries(dataset).map((property, idx) => {
      return ({
        label: property[0].slice(0,10),
        borderColor: colorCodes[idx],
        borderWidth: 3,
        fill: false,
        data: property[1].ratings,
      })
    })

    setChartData(prevState => ({
      ...prevState, 
      labels : labels,
      datasets : datasets
    }))

  }


  useEffect(()=> {
    formatData();
  },[])

  return (
      <div
        className="bg-white occupancy shadow-sm border mx-2 my-3"
        style={{
        borderRadius: '14px',
        padding:"50px",
        }}
        >

        <div style = {{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center"
        }}>
            <h4 className="fw-bold mt-3 mx-3">Reputation Chart</h4>
        
            <ToggleButtonGroup
            color="primary"
            // value={`${prevDays}`}
            exclusive
            // onChange={handleChange}
            aria-label="Platform"
          >
              <ToggleButton value="30">30</ToggleButton>
              <ToggleButton value="60">60</ToggleButton>
              <ToggleButton value="90">90</ToggleButton>
              <ToggleButton value="365">365</ToggleButton>
          </ToggleButtonGroup>

        </div>
        
        <div className="my-auto px-2 py-2" >
        <Line data={chartData} options={options} height={500} />
        </div>
      </div>
  );
};

export default ReputationHistory;
