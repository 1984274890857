import { useEffect, useState, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { AdminContext } from "../../auth/AdminContext";
import { SuperuserContext } from "../../auth/SuperuserContext";
import MUIDataTable from "mui-datatables";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import { TextField } from "@mui/material";
import axios from "../../auth/Axios";
import Success from "../assets/success.gif";
export default function RenewalsOptions({
  setShow,
  show,
  isLoading,
  renewals,
  unitId,
}) {
  const { isAdmin } = useContext(AdminContext);
  const { isSuperuser, setSuperuser } = useContext(SuperuserContext);

  const [lastSynced, SetLastSynced] = useState("");
  const [editRents, setEditRents] = useState(false);
  const [customRents, setCustomRents] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pushLoad, setPushLoad] = useState(false);
  const [syncFailed, setSyncFailed] = useState(false)
  const columns = [
    "Terms",
    "Renewal options",
    "% Change",
    "$ Change",
    "New Expiration",
  ];
  const options = {
    filter: true,
    filterType: "dropdown",
    rowsPerPageOptions: [15, 20, 30, 50],
    rowsPerPage: 15,
    tableBodyHeight: "325px",
    selectableRows: "none",
    elevation: 0,
    customFooter: () => {
      return null;
    },
  };

  const extractRents = (data) => {
    if (data) {
      const custemp = [];
      data.forEach((item) => {
        custemp.push(parseInt(item[1].replace("$", "")));
      });
      return custemp;
    }
  };

  useEffect(() => {
    setCustomRents(extractRents(renewals.data));
  }, [renewals.data]);

  const pushRents = async () => {
    setPushLoad(true);
    await axios
      .post("push-renewal-rents", {
        unit_id: unitId,
        custom_rents: customRents,
        real_rents: extractRents(renewals.data),
      })
      .then((res) => {
        if (res.status === 200) {
          setPushLoad(false);
          setSuccess(true);
          setTimeout(() => {
            setShow(false);
            setSuccess(false);
          }, 3000);
        } else {
          setError("Something went wrong");
        }
      })
      .catch(err => {
        setPushLoad(false);
        setSyncFailed(true)
        setTimeout(() => {
          setSuccess(false);
          setSyncFailed(false);
          setShow(false);
        }, 5000);
      });
  };

  const handleChange = (idx) => (e) => {
    if (!isNaN(e.target.value)) {
      customRents[idx] = parseInt(e.target.value);
      setCustomRents([...customRents]);
    } else if (isNaN(e.target.value)) {
      setError(true);
    }
  };

  return (
    <Modal
      size="xl"
      centered
      style={{
        fontFamily: "Poppins, sans-serif",
        zIndex: 1300,
        width: "110%",
      }}
      show={show}
      onHide={() => {
        setEditRents(false);
        setPushLoad(false);
        setShow(false);
      }}
      animation="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="fw-bold">Renewal Options</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center w-100">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : pushLoad ? (
          <div className="d-flex justify-content-center w-100">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) :syncFailed ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h6 className="fw-bold">Sync Failed. Property is not allowed to manually push rents.</h6>
          </div> 
        ) : success ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={Success} alt="success gif" className="w-50" />
            <h6 className="fw-bold">Successfully synced with PMs</h6>
          </div>
        ) : editRents ? (
          <div className="d-flex flex-wrap">
            {renewals.data.map((row, idx) => {
              return (
                <TextField
                  key={row[0]}
                  type="number"
                  label={row[0] + " months"}
                  value={customRents[idx]}
                  onChange={handleChange(idx)}
                  sx={{ m: 2 }}
                />
              );
            })}
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-around flex-wrap px-3">
              <h6 className="my-2">Unit Number : {renewals.unit_number}</h6>
              <h6 className="my-2">Unit Type : {renewals.unit_type}</h6>
              <h6 className="my-2">
                Current Rent : {`$${renewals.current_rent}`}
              </h6>
              <h6 className="my-2">
                Current Expiration : {renewals.current_exp}
              </h6>
            </div>
            <MUIDataTable
              data={renewals.data}
              columns={columns}
              options={options}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center">
        {error ? <span className="text-danger">Enter a valid Rent</span> : null}
        {lastSynced ? (
          <span className="fw-bold mx-5">
            Last Synced at {lastSynced.getHours()}:
            {lastSynced.getMinutes() < 10
              ? `0${lastSynced.getMinutes()}`
              : lastSynced.getMinutes()}
          </span>
        ) : null}
        {editRents ? (
          <OutlinedPrimaryBtn
            text={"Go Back"}
            onClick={() => setEditRents(false)}
            style={{ height: "50%" }}
          />
        ) : (isAdmin || isSuperuser) ? (
          <OutlinedPrimaryBtn
            text={"Edit & Sync"}
            onClick={() => setEditRents(true)}
            style={{ height: "50%" }}
          />
        ) : null}
        <PrimaryBtn
          onClick={pushRents}
          className="w-50"
          style={{ height: "50%" }}
        >
          Sync with PMS
        </PrimaryBtn>
      </Modal.Footer>
    </Modal>
  );
}
