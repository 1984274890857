import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ data, width, height, resp, optionsP }) => {
  const options = optionsP || {
    elements: {
      line: {
        tension: 0.4,
      },
    },
    responsive: resp,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins", // Your font family
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "average",
      },
    },
  };
  return <Line data={data} width={width} height={height} options={options} />;
};
export default LineChart;
