import React from "react";
import "./page404.css";
export default function Page404() {
  return (
    <figure className="w-50 d-flex flex-column align-items-center">
      <div className="sad-mac"></div>
      <span className="fs-2 text-dark fw-bold">Error 404 - Page Not Found</span>
    </figure>
  );
}
