/*referenceRentAll eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  getUnitNumbers,
} from "./Utils/formatters";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import PropertyInfo from "./PropertyInfo/PropertyInfo";
import LeaseNeeded from "./LeaseNeeded";
import UnitLeaseInfo from "./UnitLeaseInfo";
import LPE from "./LPE";
import GeneratePricing from "./GeneratePricing";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { AdminContext } from "../../auth/AdminContext";
import { ReactComponent as Info } from "../assets/info.svg";
import { useSelector, useDispatch } from "react-redux";
import CreateIcon from '@mui/icons-material/Create';
import { Modal } from "react-bootstrap";
import { Input } from '@mui/material';
import axios from "../../auth/Axios";
import { updateTodaysRent } from "../../Actions/propertyActions";
import { SuperuserContext } from "../../auth/SuperuserContext";
import Preleases from "./Students/PreLeases";
import GoalChart from "./Students/GoalsChart";
import CompWrapper from "./CompWrapper";
import ExpirationsWrapper from "./ExpirationsWrapper";
import DstWrapper from "./DstWrapper";
import OutlookWrapper from "./OutlookWrapper";

export default function PropertyVariant({
  name,
  bednum,
  bathnum,
  leasingAvg,
  propertyId,
  targetOccupancy,
  trends,
  propertyData,
  referenceRent,
  unitType,
  expirations,
  thresholds,
  dailyLeads,
  applications,
  outlook,
  setDateRange,
  dateRange,
  expLeasesV2,
  referenceHistory,
  competitors,
  newLeases,
  isStudentProp,
  goalsData,
  preLeases,
  leaseTerms,
  renewalOutlook,
  renewalOutlookLabels,
}) {
  const { isAdmin } = useContext(AdminContext);
  const { isSuperuser } = useContext(SuperuserContext);
  const [showMore, setShowMore] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [occupancy, setOccupancy] = useState("");
  const [exposureUnits, setExposureUnits] = useState([]);
  const [availDates, setAvailDates] = useState([]);
  const [todayVal, setTodayVal] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const[todayValUpdating, setTodayValUpdating] = useState(false)
  const[updateError,setUpdateError] = useState(null)

  const dispatch = useDispatch();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <small>{referenceRent.log}</small>
    </Tooltip>
  );

  const infoStyle = showMore ? "d-inline" : "d-none";
  const handleClick = () => {
    setShowMore(!showMore);
  };

  const occupancyData = (data) => {
    data.forEach((unit) => {
      if (unit.bed === bednum && unit.bath === bathnum) {
        setOccupancy(unit.occupancy_rate);
      }
    });
  };


  useEffect(() => {
    setExposureUnits(
      getUnitNumbers(expLeasesV2, `${bednum}b-${bathnum}b`, setAvailDates)
    );
  }, [expLeasesV2, bednum, bathnum]);

  useEffect(() => {
    occupancyData(propertyData);
  }, [propertyData]);

  const onChangeFunc = (e) => {
    setTodayVal(e.target.value)
  }

  const handleHide = () => {
    setShowModal(false)
    setTodayValUpdating(false)
    setUpdateError(null)
  }

  const updateRent = () => {
    setTodayValUpdating(true)

    axios.post('update-todays-rent',{
      property_name: name,
      unit_type : unitType,
      initial_refrence_rent: referenceRent.reference_rent,
      updated_refrence_rent: todayVal,
      log: referenceRent.log
    })
    .then((resp)=> {
      dispatch(updateTodaysRent(name,unitType,todayVal))
      setTodayValUpdating(false)
      setShowModal(false)
    })
    .catch((err)=> {
      setTodayValUpdating(false)
      setUpdateError("Some Error Occured. Please Try Again.")
    })
  }

  return (
    <div
      className="bg-white shadow border px-3 my-2 py-4"
      style={{ borderRadius: "14px" }}
    >

    {todayVal !== null && (
      <Modal 
      size={"lg"}
      centered
      style={{
        fontFamily: "Poppins, sans-serif",
        zIndex: 1300,
        maxHeight: "100%",
        width:"100%",
      }}
      show={showModal}
      onHide={handleHide}
      animation="true">
        <Modal.Header closeButton>
            <h4 className="text-center m-0 m-2 fw-bold">Update Today's Rent</h4>
        </Modal.Header>

        <Modal.Body >
        <div className=" d-flex justify-content-around m-3">
            {
              updateError !== null ?
              <p>{updateError}</p>
              : null
            }
          </div>

          <div className=" d-flex justify-content-around">
            <Input value = {todayVal} onChange={onChangeFunc} />
            <OutlinedPrimaryBtn
                disabled ={todayValUpdating}
                onClick={updateRent}
                text= {todayValUpdating===true?"Updating...":"Update"}
              />
          </div>
        </Modal.Body>
    </Modal>
    )}
      <div className="d-flex property-misc-lease justify-content-between">
        <div className="d-flex props-info">
          <PropertyInfo bednum={bednum} bathnum={bathnum} />
          <div className="d-flex">
            <div className="d-flex align-items-center text-center">
              <h6 className="mb-0 mx-4 fw-bold">Recent Leasing Avg: <b style={{ color: "#0997F2"}}>${leasingAvg.recent_leasing_average}</b> </h6>
            </div>
            <div className="d-flex align-items-center text-center">
              <h6 className="mb-0 fw-bold">Reference Rent:</h6>
              <div className="d-flex">
                <div className="d-flex mx-2 flex-column">
                  {
                    isStudentProp ? 
                  <span>Last Week</span>
                  :
                  <span>Yesterday</span>
                  }
                  <h5 style={{ color: "#37D159" }} className="fw-bold">
                    ${referenceRent?.yesterday_refrence_rent}
                  </h5>
                </div>
                <div className="d-flex mx-2 flex-column">
                {
                    isStudentProp ? 
                  <span>This Week</span>
                  :
                  <span>Today</span>
                  }
                  <h5 style={{ color: "#37D159" }} className="fw-bold">
                    ${referenceRent?.reference_rent}
                  </h5>
                </div>
                {
                  isSuperuser === true ?
                    <div className="d-flex m-1 pe-4 align-items-center justify-content-center">
                      <CreateIcon style = {{color:"rgba(0, 0, 0, 0.65)", fontSize:20, marginTop:'0.5rem'}} className = 'create-icon'
                        onClick={()=> {
                        setTodayVal(referenceRent.reference_rent)
                        setShowModal(true)
                      }}> </CreateIcon>
                    </div>
                  :null
                }
              </div>
            </div>
            {isAdmin && (
              <OverlayTrigger
                placement="right"
                className="d-flex align-items-center justify-content-center"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Info className="my-auto mx-auto" />
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div className="d-flex align-items-center prop-btns ">
          <OutlinedPrimaryBtn
            onClick={handleClick}
            text={`${showMore ? "Collapse" : "More"}`}
          />
          <PrimaryBtn
            onClick={() => setModalShow(true)}
            text="Generate Pricing"
          />
        </div>
      </div>
      <div className={infoStyle}>
        {referenceHistory && applications && trends ? (
          <UnitLeaseInfo
            applicationsData={
              applications && applications[`${bednum}b-${bathnum}b`]
            }
            trendsData={trends[`${bednum}b-${bathnum}b`]}
            referenceHistoryData={referenceHistory[`${bednum}b-${bathnum}b`]}
          />
        ) : !referenceHistory || !applications || !trends ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : null}
      </div>
      <div className="d-flex main-graphs align-items-stretch">
        <div className="d-flex flex-column LNLPE h-100 my-auto">
          {
            isStudentProp ?
            <Preleases 
            occupancy = {occupancy}
            preLeaseOccupancy = {preLeases[name][`${bednum}b-${bathnum}b`]}
            /> 
            :
            targetOccupancy ? (
              <LeaseNeeded
                targetOccupancy={targetOccupancy[`${bednum}b-${bathnum}b`]}
                occupancy={occupancy}
              />
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" className="my-5">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )
          }
          <LPE bed={bednum} bath={bathnum} expiryLeases={expLeasesV2} />
        </div>
        { showMore ? 
            (
              competitors !== undefined &&
              competitors !== null ?
              <CompWrapper competitors={competitors} bednum = {bednum} bathnum = {bathnum} />
              :
              <div className="d-flex w-75 h-150 align-items-center justify-content-center">
              <Spinner animation="border" role="status" className="my-5">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
        ) 
        :
          isStudentProp ? (
            goalsData[name] !== undefined && goalsData[name]['labels'].length !== 0 ?
              <GoalChart 
                goalsData = {goalsData}
                propertyName = {name}
                unitType = {`${bednum}b-${bathnum}b`}
              />
            :
              <div className="d-flex w-100 h-100 justify-content-center">
                <Spinner animation="border" role="status" className="my-auto">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
        )
        :
        dailyLeads ? 
          <DstWrapper 
            name={name}
            bednum={bednum}
            bathnum={bathnum}
            dailyLeads={dailyLeads[unitType]}
            newLeases={newLeases}
            referenceHistory={referenceHistory}
            showMore={showMore}
          /> 
          :
          <div style={{
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          </div>
        }
      </div>
      <div className={`${infoStyle} d-flex flex-column justify-space-around`}>
        {
        dailyLeads ? 
          <DstWrapper 
          name={name}
          bednum={bednum}
          bathnum={bathnum}
          dailyLeads={dailyLeads[unitType]}
          newLeases={newLeases}
          referenceHistory={referenceHistory}
          showMore={showMore}
          /> 
          :
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }

        {
        expirations && thresholds && renewalOutlook ?
        < ExpirationsWrapper expirations={expirations[unitType]} thresholds={thresholds[unitType]} 
        anticipatedRenConv = {expirations.anticipated_ren_conv} renewalOutlook = {renewalOutlook[unitType]} renewalOutlookLabels = {renewalOutlookLabels}/> 
        : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        { 
        outlook ?
          <OutlookWrapper 
            propertyId={propertyId}
            dateRange={dateRange}
            setDateRange={setDateRange}
            outlook={outlook[unitType]}
            referenceRent={referenceRent}
          />
        :(
          <div className="d-flex w-100 justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
        }
      </div>
      {showMore ? (
        <div className="d-flex justify-content-end mt-3">
          <PrimaryBtn
            onClick={() => setModalShow(true)}
            text="Generate Pricing"
          />
        </div>
      ) : null}
      {exposureUnits.length !== 0 && (
        <GeneratePricing
          units={exposureUnits}
          unitType={`${bednum}b-${bathnum}b`}
          refRent={referenceRent === undefined? 0 : referenceRent.reference_rent}
          unitnumbers={Object.keys(exposureUnits)}
          show={modalShow}
          availDates={availDates}
          propertyid={propertyId}
          setmodalshow={setModalShow}
          leaseTerms={leaseTerms}
        />
      )}
    </div>
  );
}
