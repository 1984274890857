/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LeaseProperty from "./LeaseProperty";
import "./newleases.css";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import SearchBar from "./SearchBar";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import { 
  loadExpirationsRange,
  loadExpirationsUG,
  loadReferenceRent,
  loadExpiringLeases,
  loadExpirationsThresholds,
  loadTrends,
  loadRecentLeasingAverage,
  loadBrains,
  loadNewLeases,
  loadReferenceHistory,
  loadGoalsData,
  loadPreLeases,
  loadLeads,
  loadCompetitorsStats,
  loadDailyLeads,
  loadRenewalOutlookChart,
} from "../../Actions/propertyActions";

function NewLeases({ fetch }) {
  const [selectedProperties, setSelectedProperties] = useState([
    { value: "all", label: "All" },
  ]);

  const [selectedPropNames, setSelectedPropNames] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const temp = new Date();
  const now = new Date();
  const next30Days = new Date(temp.setDate(now.getDate() + 90));
  const [dateRange, setDateRange] = useState([now, next30Days]);

  const leaseProperties = useSelector((state) => state.propertyReducer.properties);
  const expirationsUG = useSelector((state) => state.propertyReducer.expirationsUG);
  const referenceRent = useSelector((state) => state.propertyReducer.referenceRent);
  const expLeasesV2 = useSelector((state) => state.propertyReducer.expiringLeases);
  const thresholds = useSelector((state) => state.propertyReducer.thresholds);
  const trends = useSelector((state) => state.propertyReducer.trends);
  const recentLeasingAverage = useSelector((state) => state.propertyReducer.recentLeasingAverage);
  const targetOccupancy = useSelector((state) => state.propertyReducer.brains);
  const newLeases = useSelector((state) => state.propertyReducer.newLeases);
  const applications = useSelector((state) => state.propertyReducer.leads);
  const competitors = useSelector((state) => state.propertyReducer.competitors);
  const outlook = useSelector((state) => state.propertyReducer.expirationsRange);
  const referenceHistory = useSelector((state) => state.propertyReducer.referenceHistory);
  const goalsData = useSelector((state) => state.propertyReducer.goalsData);
  const preLeases = useSelector((state) => state.propertyReducer.preLeases);
  const dailyLeads = useSelector((state) => state.propertyReducer.dailyLeads);
  const renewalOutlook = useSelector((state) => state.propertyReducer.renewalOutlook);
  const dispatch = useDispatch();
  
  const isEmpty = (variable) => {
    if (variable === null || variable === undefined){
      return true
    }
    if (Array.isArray(variable)) {
      return variable.length === 0;
    }
    if (typeof variable === 'object') {
      return Object.keys(variable).length === 0;
    }
  }

  const fetchData = () => {
    if (isEmpty(recentLeasingAverage) ) dispatch(loadRecentLeasingAverage());
    if (isEmpty(referenceRent) ) dispatch(loadReferenceRent());
    if (isEmpty(targetOccupancy) ) dispatch(loadBrains());
    if (isEmpty(expLeasesV2) ) dispatch(loadExpiringLeases());
    if (isEmpty(expirationsUG) ) dispatch(loadExpirationsUG());
    if (isEmpty(thresholds) ) dispatch(loadExpirationsThresholds());
    if (isEmpty(trends) ) dispatch(loadTrends());
    if (isEmpty(goalsData) ) dispatch(loadGoalsData());
    if (isEmpty(preLeases) ) dispatch(loadPreLeases());
    if (isEmpty(newLeases) ) dispatch(loadNewLeases());
    if (isEmpty(referenceHistory) ) dispatch(loadReferenceHistory());
    if (isEmpty(outlook) ) dispatch(loadExpirationsRange());
    if (isEmpty(applications) ) dispatch(loadLeads());
    if (isEmpty(competitors) ) dispatch(loadCompetitorsStats());
    if (isEmpty(dailyLeads) ) dispatch(loadDailyLeads());
    if (isEmpty(renewalOutlook) ) dispatch(loadRenewalOutlookChart());
  }
  
  useEffect(() => {
    fetchData();
  },[])
  
  useEffect(() => {
    if (!outlook || Object.keys(outlook).length === 0) {
      dispatch(loadExpirationsRange(dateRange));
    }
  }, [dateRange]);
  const showProperties = (data) => {
    try {
      if (data || selectedProperties) {
        setLoading(true);
        const selectedPropNames = [];
        if (
          selectedProperties.length === 0 ||
          selectedProperties.some((prop) => prop.value === "all")
        ) {
          selectedPropNames.push(...Object.keys(data));
        } else {
          let final = selectedProperties.filter((prop) => prop.value !== "");
          final.forEach((prop) => {
            selectedPropNames.push(prop.label);
          });
        }
        setSelectedPropNames(selectedPropNames);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      fetch();
    }
  };
  useEffect(() => {
    showProperties(leaseProperties);
  }, [selectedProperties, leaseProperties]);
  return (
    <div className="new-leases mx-auto pt-5" style={{ width: "80%" }}>
      <div className="d-flex flex-column">
        <h3 className="fw-bold mt-4">New Leases</h3>
        <span style={{ fontSize: "18px" }} className="text-muted fw-normal">
          Generate a custom quote for new leases.
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <SearchBar
          leaseProperties={Object.keys(leaseProperties).sort((a, b) => {
            return a.localeCompare(b);
          })}
          setSelectedProperties={setSelectedProperties}
        />
        <PrimaryBtn onClick={fetch} text="Refresh" className="h-50 w-50" />
      </div>
      <div className="d-flex flex-column align-items-center">
        {isLoading ? (
          <div className="d-flex w-75 h-150 align-items-center justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : selectedPropNames.length !== 0 && outlook ? (
          Object.entries(
            Object.fromEntries(Object.entries(leaseProperties).sort())
          ).map((property, index) => {
            return selectedPropNames.includes(property[0]) &&
              <LeaseProperty
                key={index}
                targetOccupancy={targetOccupancy}
                propertyId={property[1].property_id}
                trends={trends[property[0]]}
                referenceRent={referenceRent[property[0]]}
                propertyData={leaseProperties[property[0]].unit_types}
                name={property[0]}
                referenceHistory={referenceHistory[property[0]]}
                leasingAvg = {recentLeasingAverage[property[0]]}
                outlook={outlook[property[0]]}
                dateRange={dateRange}
                setDateRange={setDateRange}
                expLeasesV2={expLeasesV2[property[0]]}
                expirations={expirationsUG[property[0]]}
                thresholds={thresholds[property[0]]}
                newLeases={newLeases}
                location={property[1].address}
                variants={property[1].unit_types}
                isStudentProp={property[1].is_student_prop}
                goalsData={goalsData}
                preLeases={preLeases}
                applications={applications[property[0]]}
                competitors={competitors[property[0]]}
                dailyLeads={dailyLeads[property[0]]}
                renewalOutlook = {renewalOutlook[property[0]]}
                renewalOutlookLabels = {renewalOutlook["labels"]}
              />
          })
        ) : (
          <Spinner animation="border" role="status" className="my-5">
            <h6 className="fw-bold mt-3">
              Please wait while we load the New Leases...
            </h6>
            <span>Crunching some numbers :)</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}

export default NewLeases;
