/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";
import "./exposure.css";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExposureModal from "./ExposureModal";
import { grey } from "@mui/material/colors";
function PropertyExposure({ expiryLeases }) {
  const [showModal, setShowModal] = useState(false);
  const [formattedExpLeases, setFormattedExpLeases] = useState([]);
  const expLeases = expiryLeases || {
    "expiring-in-60-days-total": 30,
    "expiring-in-30-days-total": 40,
    "vacant-total": 23,
  };
  const data = {
    labels: ["Expiring (30 Day +)", "Expiring (30 Day)", "Vacant"],
    datasets: [
      {
        label: "Occupancy",
        data: [
          Object.values(expLeases["expiring-in-60-days-total"]).reduce((sum, value) => sum + value, 0),
          Object.values(expLeases["expiring-in-30-days-total"]).reduce((sum, value) => sum + value, 0),
          Object.values(expLeases["vacant-total"]).reduce((sum, value) => sum + value, 0),
        ],
        backgroundColor: ["#FFB067", "#F46141", "#37D159"],
      },
    ],
  };
  const options = {
    responsive: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          font: {
            weight: "bold",
          },
          padding: 10,
        },
      },
    },
  };
  const formatExpiringLeasesData = (data) => {
    if (data) {
      const expData = [];
      Object.entries(data).forEach((item) => {
        if (item[0] === "additional-dashboard") {
          item[1].forEach((col) => {
            const row = [];
            const end = col.availability_date
              ? new Date(col.availability_date)
              : "N/A";
            row.push(
              col.unit_number,
              col.unit_type_name,
              col.building_name ? col.building_name : "N/A",
              col.availability_status,
              col.availability_date
                ? `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear()}`
                : "N/A"
            );
            expData.push(row);
          });
        }
      });
      return expData;
    } else {
      setTimeout(() => formatExpiringLeasesData(expLeases), 2000);
    }
  };
  useEffect(() => {
    setFormattedExpLeases(formatExpiringLeasesData(expLeases));
  }, [expLeases]);
  return (
    <div
      className="bg-white exposure w-25  shadow-sm border mt-4 px-2"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex mt-2 mb-4 justify-content-center">
        <h6 className="fw-bold w-75 my-auto expo-title ms-4 text-center">
          Exposure
        </h6>
        <IconButton
          sx={{ color: grey[700], border: 1 }}
          onClick={() => setShowModal(true)}
        >
          <VisibilityIcon sx={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
      <div className="expo-seperate mt-4 h-75 justify-content-center align-items-center">
        {expLeases ? (
          <>
            <Pie data={data} options={options} height={250} id="exposure-pie" />
            <div className="d-flex expo-details pb-4 ps-2 flex-column mt-4">
            </div>
          </>
        ) : (
          <Spinner animation="border" role="status" className="my-5 mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
      <ExposureModal
        formattedExpLeases={formattedExpLeases}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default PropertyExposure;
