import { Modal } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
export default function ExposureModal({
  showModal,
  setShowModal,
  formattedExpLeases,
}) {
  const cellStyle = {
    setCellHeaderProps: () => {
      return {
        style: {
          fontFamily: "Poppins,sans-serif",
          fontWeight: 800,
        },
      };
    },
    setCellProps: () => {
      return {
        style: {
          fontFamily: "Poppins,sans-serif",
          textAlign: "center",
        },
      };
    },
  };

  const renderTableHeadCell = (columnMeta, updateDirection, idx) => {
    // return (
    //   <th
    //     key={idx}
    //     onClick={() => updateDirection(idx)}
    //     style={{ cursor: "pointer", fontFamily: "Poppins,sans-serif" }}
    //     className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium MUIDataTableHeadCell-root-46 MUIDataTableHeadCell-fixedHeader-47 css-1ygcj2i-MuiTableCell-root"
    //     scope="col"
    //     data-colindex="3"
    //     data-tableid="07334627144479584"
    //   >
    //     <span className="MUIDataTableHeadCell-contentWrapper-56  mx-auto">
    //       <button
    //         className="MuiButton-root mx-auto MuiButton-Primary MuiButton-sizeMedium MuiButton-SizeMedium MuiButtonBase-root MUIDataTableHeadCell-toolButton-55 css-1w1rijm-MuiButtonBase-root-MuiButton-root"
    //         tabIndex="0"
    //         type="button"
    //         data-testid="headcol-1"
    //         aria-label="Sort"
    //         data-mui-internal-clone-element="true"
    //       >
    //         <div className="MUIDataTableHeadCell-sortAction-51">
    //           <div className="MUIDataTableHeadCell-data-50">
    //             {columnMeta.name}
    //           </div>
    //         </div>
    //         <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
    //       </button>
    //     </span>
    //   </th>
    // );
  };

  const columns = [
    {
      name: "Unit Number",
      options: cellStyle,
    },
    {
      name: "Unit Type",
      options: {
        // customHeadRender: (columnMeta, updateDirection) =>
        //   renderTableHeadCell(columnMeta, updateDirection, columnMeta.index),
        setCellProps: () => {
          return {
            style: {
              fontFamily: "Poppins,sans-serif",
              textAlign: "center",
            },
          };
        },
      },
    },
    {
      name: "Unit Building",
      options: {
        // customHeadRender: (columnMeta, updateDirection) =>
        //   renderTableHeadCell(columnMeta, updateDirection, columnMeta.index),
        setCellProps: () => {
          return {
            style: {
              fontFamily: "Poppins,sans-serif",
              textAlign: "center",
            },
          };
        },
      },
    },
    {
      name: "Availability Status",
      options: {
        // customHeadRender: (columnMeta, updateDirection) =>
        //   renderTableHeadCell(columnMeta, updateDirection, columnMeta.index),
        setCellProps: () => {
          return {
            style: {
              fontFamily: "Poppins,sans-serif",
              textAlign: "center",
            },
          };
        },
      },
    },
    {
      name: "Availability Date",
      options: {
        // customHeadRender: (columnMeta, updateDirection) =>
        //   renderTableHeadCell(columnMeta, updateDirection, columnMeta.index),
        setCellProps: () => {
          return {
            style: {
              fontFamily: "Poppins,sans-serif",
              textAlign: "center",
            },
          };
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    rowsPerPage: 100,
    selectableRows: "none",
    tableBodyHeight: "350px",
    elevation: 0,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return null;
    },
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="m-0 ms-3 fw-bold">Exposure Info</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-2 py-4 mx-3">
        <ThemeProvider theme={createTheme()}>
          <MUIDataTable
            data={formattedExpLeases}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Modal.Body>
    </Modal>
  );
}
