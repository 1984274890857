import React from "react";
import BlueCircularProgressBar from "../Progress Bar/BlueCircularProgressBar";
function LeaseNeeded({ occupancy, targetOccupancy }) {
  //   const data = {
  //     labels: ["Occupied", "Unoccupied"],
  //     datasets: [
  //       {
  //         label: "Occupancy",
  //         data: [71, 29],
  //         backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
  //         borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
  //         borderWidth: 1,
  //       },
  //     ],
  //   };
  return (
    <div
      className="LN border shadow-sm mx-2 px-2 my-3 py-4 d-flex align-items-center"
      style={{ borderRadius: "14px" }}
    >
      {/* <p className="fw-bold mt-2 text-center">Total Revenue</p> */}
      <div className="d-flex align-items-center">
        <div className="d-flex ms-1 flex-column fw-bold">
          <h6 className="m-0 mb-1 fw-bold">Current Occupancy</h6>
          <span className="m-0 mt-3">Targeted Occupancy</span>
          <h5 className="fw-bold m-0">{targetOccupancy}%</h5>
        </div>
        {/* <Doughnut data={data} style={{ width: "81px", height: "81px" }} /> */}
        <BlueCircularProgressBar
          strokeWidth="8"
          sqSize="90"
          percentage={occupancy}
        />
      </div>
    </div>
  );
}

export default LeaseNeeded;
