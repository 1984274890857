import axios from "../auth/Axios";


export const loadReputationProperties = () => async (dispatch) => {
    let properties;
    try {
      await axios.get("reputation/properties").then((res) => {
        properties = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadReputationProperties();
      }, 5000);
    }
    dispatch({
      type: "GET_REPUTATION_PROPERTIES",
      payload: {
        reputationProperties: Object.fromEntries(Object.entries(properties).sort()),
      },
    });
  };

  export const loadTodaysData = () => async (dispatch) => {
    let todaysReputation;
    try {
      await axios.get("reputation/reputation-score").then((res) => {
        todaysReputation = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadTodaysData();
      }, 5000);
    }
    dispatch({
      type: "GET_TODAY_REPUTAION_DATA",
      payload: {
        todaysReputation: todaysReputation,
      },
    });
  };

  export const loadReputationChart = () => async (dispatch) => {
    let reputationChartData;
    try {
      await axios.get("reputation/reputation-chart").then((res) => {
        reputationChartData = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadReputationChart();
      }, 5000);
    }
    dispatch({
      type: "GET_REPUTAION_CHART_DATA",
      payload: {
        reputationChartData: reputationChartData,
      },
    });
  };


  export const loadRecentReviews = () => async (dispatch) => {
    let recentReviews;
    try {
      await axios.get("reputation/recent-reviews").then((res) => {
        recentReviews = res.data;
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadRecentReviews();
      }, 5000);
    }
    dispatch({
      type: "GET_RECENT_REVIEWS",
      payload: {
        recentReviews: recentReviews,
      },
    });
  };