import React, { useState, useEffect } from 'react';
import { Table, Input, Space } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import Spinner from "../Spinner";
import  axios  from "../../auth/Axios";
import swal from 'sweetalert';
import { useSelector } from "react-redux";


const EditableTable = ({ dataSource, setUserData }) => {
  const [editableRowKey, setEditableRowKey] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [updating, setUpdating] = useState(false)

  const columns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        editableRowKey === record.key ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleInputChange(record.key, 'username', e.target.value)}
          />
        ) : text
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        editableRowKey === record.key ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleInputChange(record.key, 'email', e.target.value)}
          />
        ) : text
      ),
    },
    {
      title: 'Password',
      width:"25%",
      ellipsis: true,
      dataIndex: 'password',
      key: 'password',
      render: (text, record) => (
        editableRowKey === record.key ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleInputChange(record.key, 'password', e.target.value)}
          />
        ) : text
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {editableRowKey === record.key ? (
            <>
              {
                updating ? <LoadingOutlined />
                :
                <CheckOutlined onClick={() => handleUpdate(record.key)} />
              }
              <CloseOutlined onClick={() => handleCancel()} />
            </>
          ) : (
            <EditOutlined onClick={() => handleEdit(record.key)} />
          )}
        </Space>
      ),
    },
  ];

  const handleEdit = (key) => {
    setEditableRowKey(key);
    const currentValues = dataSource.find((item) => item.key === key);
    setEditedValues({ ...currentValues });
  };

  const handleUpdate = (key) => {
    setUpdating(true)
    const allData = dataSource.map(item => 
      item.key === key ?
      {
        ...item,
        username: editedValues.username,
        email: editedValues.email,
        password: editedValues.password,
      }
      : item
    )

    const updatedData = allData.filter(item => item.key === key)
    setUserData(allData)

    axios.post("user-management", updatedData[0])
    .then(resp => {
      setUpdating(false)
      swal("Success", "Successfully Updated.", "success")
    })
    .catch(err => {
      setUpdating(false)
      swal("Error", "An Unknown Error Occured.", "error")
      })
    
    setEditableRowKey(null);
  };


  const handleCancel = () => {
    setEditableRowKey(null);
  };

  const handleInputChange = (key, field, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={false}
        rowKey={(record) => record.key}
      />
    </>
  );
};


const UserManagement = () => {
    const [userData, setUserData] = useState([]);
    const associatedProperty = useSelector((state) => state.propertyReducer.associatedProperty);

    const fetchUsers = () => {
        const associatedProp = localStorage.getItem("associatedProp")
        if (associatedProp === null || associatedProp === undefined || associatedProp === "") {
          associatedProp = associatedProperty
        }

        axios.get("user-management", {
          params:{
            groupName:associatedProp
          }
        })
            .then(res => {
                setUserData(res.data.response)
            })
            .catch (err => {
                setTimeout(() => {
                    fetchUsers();
                }, 2000);
            })
        }

    useEffect(() => {
        fetchUsers();
    },[])

  return (
    <div>
        <div  className="UserMngmtWrapper"
            style={{
                margin:"50px 40px", 
                width:"90%",
                display:"flex",
                flexDirection:"column",
                gap:"1rem"
            }}
            >
            <div>
                <h3 className="fw-bold mt-4">Users Management</h3>
            </div>

            <div style = {{
              display:"flex",
              justifyContent:"center",
              alignItems:"center"
            }}>
              <div className="m-4 mt-4 py-4 shadow border p-3"
                  style={{ borderRadius: "14px" }}
              >
                  {
                      userData.length === 0
                      ?
                      (
                          <Spinner animation="border" role="status" className="my-5">
                              <h6 className="fw-bold mt-3">
                              Please wait while we load the User Information...
                              </h6>
                              <span>Crunching some numbers :)</span>
                          </Spinner>
                      )
                      :
                      (
                          <EditableTable dataSource={userData} setUserData={setUserData} />
                      )

                  }
              </div>
            </div>
        </div>
    </div>
  );
};

export default UserManagement;
