/* eslint-disable react-hooks/exhaustive-deps */
import { AdminContext } from "./auth/AdminContext";
import Login from "./components/Login/Login";
import VerfifyEmail from "./components/Login/VerfifyEmail";
import PasswordReset from "./components/Login/PasswordReset";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Competitors from "./components/Competitors/Competitors";
import NewLeases from "./components/New Leases/NewLeases";
import LeaseRenewals from "./components/LeaseRenewals/LeaseRenewals";
import Reports from "./components/Reports/Reports";
import PersistentDrawer from "./components/Navigation/PersistentDrawer";
import useCheckUser from "./auth/useCheckUser";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./auth/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import Page404 from "./components/404/Page404";
import { useDispatch, useSelector } from "react-redux";
import Parameters from "./components/Parameters/Parameters";
import MarketInsight from "./components/Market/Market";
import Reputation from "./components/Reputation/Reputation";
import { loadProperties } from "./Actions/propertyActions";
import { useEffect, useState } from "react";
import { SuperuserContext } from "./auth/SuperuserContext";
import { MarketAccessContext } from "./auth/MarketAccessContext";
import UserManagement from "./components/UserManagement/UserMngmt";
import { UserManagementContext } from "./auth/UserMngmtContext";
import { ReputationContext } from "./auth/ReputatationContext";
import ReportAssignment from "./components/Reports/AssignReports/AssignReports";

function App() {
  const { user, setUser } = useCheckUser();
  const dispatch = useDispatch();
  const [isAdmin, setAdmin] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(null)
  const [hasMarketAccess, setHasMarketAccess] = useState(null)
  const [hasUserMngmtAccess, setHasUserMngmtAccess] = useState(null)
  const [hasReputationContext, setHasReputationContext] = useState(null)
  
  const fetch = () => {
    dispatch(loadProperties());
  };

  useEffect(() => {
    if (user) {
      fetch();
    }
  }, [user]);

  return (
    <div
      style={{ fontFamily: "Poppins, sans-serif", backgroundColor: "#F6F6F6" }}
      className="App d-flex bg-white"
    >
      <AuthContext.Provider value={{ user, setUser }}>
        <AdminContext.Provider value={{ isAdmin, setAdmin }}>
          <SuperuserContext.Provider value={{ isSuperuser, setIsSuperuser }}>
          <MarketAccessContext.Provider value={{ hasMarketAccess, setHasMarketAccess }}>
          <UserManagementContext.Provider value={{ hasUserMngmtAccess, setHasUserMngmtAccess }}>
          <ReputationContext.Provider value={{ hasReputationContext, setHasReputationContext }}>
          {user ? (
            <>
              <PersistentDrawer setUser={setUser} />
              <Routes>
                {user ? (
                  <Route path="/" element={<Dashboard />} />
                ) : (
                  <Navigate to="/login" />
                )}
                <Route
                  path="/new-leases"
                  element={<NewLeases fetch={fetch} />}
                />
                <Route path="/lease-renewals" element={<LeaseRenewals fetch={fetch}/>} />
                <Route path="/competitors" element={<Competitors fetch={fetch}/>} />
                <Route path="/reports" element={<Reports fetch={fetch}/>} />
                <Route path="/parameters" element={<Parameters fetch={fetch}/>} />
                <Route path="/market-insight" element={<MarketInsight />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/reputation" element={<Reputation />} />
                <Route path="/assign-report" element={<ReportAssignment />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<Login setUser={setUser} />} />
              <Route path="/verify-email" element={<VerfifyEmail />} />
              <Route path="/reset-password/:user_token" element={<PasswordReset />} />
            </Routes>
          )}
          </ReputationContext.Provider>
          </UserManagementContext.Provider>
          </MarketAccessContext.Provider>
          </SuperuserContext.Provider>
        </AdminContext.Provider>
      </AuthContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
