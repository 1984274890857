/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import axios from "../../auth/Axios";
import SolidPrimaryBtn from "../Buttons/SolidPrimaryBtn";

export default function CompetitorsData({
  record,
  competitorPropertyId,
  floorPlan,
}) {
  const [unitType, setUnitType] = useState(record.unit_type);
  const [sqft, setSqft] = useState(parseInt(parseInt(record.squarefeet)) || 0);
  const [lowPrice, setLowPrice] = useState(record.lowest_price || 0);
  const [effecRent, setEffecRent] = useState(record.effective_rent || 0);
  const [compPSF, setCompPSF] = useState(effecRent / sqft);
  const [occupancy, setOccupancy] = useState(record.occupancy_rate || 0);
  const [leased, setLeased] = useState(record.leased || 0);
  const [concession, setConcession] = useState(record.concession || 0);
  const [SSPSF, setSSPSF] = useState(record.subject_site_psf);
  const [SRR, setSRR] = useState(record.refrence_rent);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const utcDate = new Date();
  const d = new Date(record.date)
  const [date, setDate] = useState(
    `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`,
  );

  useEffect(() => {
    setEffecRent(Math.round(lowPrice - concession / 12));
  }, [lowPrice, concession]);

  useEffect(() => {
    setCompPSF((effecRent / sqft).toFixed(2));
  }, [effecRent, sqft]);

  // useEffect(() => {
  //   if (sqft === "") setSqft(0);
  //   if (sqft !== 0) {
  //     setCompPSF((effecRent / sqft).toFixed(0));
  //   } else if (sqft === 0 || sqft === "") setCompPSF(0);
  // }, [effecRent, sqft]);
  const updateRecord = async () => {
    const data = {
      unit_type: floorPlan,
      comp_property_id: competitorPropertyId,
      concession: parseInt(concession),
      floor_plan_mapping: unitType,
      date: `${utcDate.getMonth() + 1}/${utcDate.getDate()}/${utcDate.getFullYear()}`,
      lowest_price: parseInt(lowPrice),
      occupancy_rate: occupancy,
      leased: leased,
      squarefeet: parseInt(sqft),
    };
    await axios
      .post(`/competitor_history`, data)
      .then((res) => {
        if (res.status === 200) {
          setStatus(true);
        }
      })
      .catch((err) => {
        setError("Something went wrong. Please try again later.");
        console.error(err);
      });
  };

  return (
    <div className="mx-auto mt-1 px-2 d-flex flex-column">
      <div
        className="my-2 mt-4 d-flex flex-column align-items-start mx-auto"
        style={{ width: "85%" }}
      >
        <div className="d-flex w-100 my-2 mb-4 flex-wrap align-items-center">
          <div className="mx-2 d-flex text-center">
            <h6 className="m-0 ml-1 ">Last Updated :</h6>
            <h6 className="m-0 mx-1 text-center">{date}</h6>
          </div>
          <div className=" mx-2 d-flex text-center ">
            <h6 className="m-0 mx-1">Subject Site Mapping :</h6>{" "}
            <h6 className="m-0 mx-1 text-center">{unitType}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-start editable-values">
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <div className="mb-3 mx-2 d-flex align-items-center justify-content-start">
              <h6 className="m-0 me-4">Lowest Price</h6>
              <Form.Control
                type="number"
                size="sm"
                className="w-50"
                onChange={(e) => setLowPrice(e.target.value)}
                value={lowPrice}
              />
            </div>
            <div className="mb-3 mx-2 d-flex align-items-center justify-content-start">
              <h6 className="m-0 me-4">Concession</h6>
              <Form.Control
                type="number"
                className="w-50"
                size="sm"
                onChange={(e) => setConcession(e.target.value)}
                value={concession}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <div className="mb-3 mx-2 d-flex align-items-center justify-content-start">
              <h6 className="m-0 me-4">Occupancy %</h6>
              <Form.Control
                type="number"
                className="w-50"
                size="sm"
                onChange={(e) => setOccupancy(e.target.value)}
                value={occupancy}
              />
            </div>
            <div className="mb-3 mx-2 d-flex align-items-center justify-content-start">
              <h6 className="m-0 me-4">Leased %</h6>
              <Form.Control
                type="number"
                className="w-50"
                size="sm"
                onChange={(e) => setLeased(e.target.value)}
                value={leased}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap my-1 justify-content-center">
          <div className="mb-3 d-flex m-2 align-items-center">
            <h6 className="m-0 ml-1">Square feet :</h6>
            <h6 className="m-0 mx-1 text-center ">
              {sqft !== 0 ? <u>{sqft}</u> : "--"}
            </h6>
          </div>
          <div className="mb-3 d-flex m-2 align-items-center">
            <h6 className="m-0 mx-1">Comp PSF :</h6>
            <h6 className="m-0 mx-1 text-center">
              {compPSF !== 0 ? <u>{compPSF}</u> : "--"}
            </h6>
          </div>
          <div className="mb-3 d-flex m-2 align-items-center">
            <h6 className="m-0 mx-1">Effective Rent :</h6>
            <h6 className="m-0 mx-1 text-center">
              {effecRent !== 0 ? <u>{effecRent}</u> : "--"}
            </h6>
          </div>
          <div className="mb-3 d-flex m-2 align-items-center">
            <h6 className="m-0 mx-1">Subject Site PSF :</h6>
            <h6 className="m-0 mx-1 text-center">
              {SSPSF !== 0 ? <u>{SSPSF}</u> : "--"}
            </h6>
          </div>
          <div className="mb-3 d-flex m-2 align-items-center">
            <h6 className="m-0 mx-1">Site Ref Rent :</h6>
            <h6 className="m-0 mx-1 text-center">
              {SRR !== 0 ? <u>{SRR}</u> : "--"}
            </h6>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center w-100 justify-content-center">
          {error ? <span className="text-danger my-2">{error}</span> : null}
          {status && (
            <span className="text-success fw-bold my-2">
              Updated Successfully
            </span>
          )}
          <SolidPrimaryBtn
            onClick={updateRecord}
            text="Update"
            className="mx-auto mt-2"
          />
        </div>
      </div>
    </div>
  );
}
