/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Pin from "../assets/MapPin.svg";
import PropertyVariant from "./PropertyVariant";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
function LeaseProperty({
  name,
  location,
  variants,
  leasingAvg,
  propertyId,
  targetOccupancy,
  referenceHistory,
  thresholds,
  referenceRent,
  propertyData,
  expirations,
  outlook,
  setDateRange,
  dateRange,
  expLeasesV2,
  trends,
  newLeases,
  isStudentProp,
  goalsData,
  preLeases,
  applications,
  competitors,
  dailyLeads,
  renewalOutlook,
  renewalOutlookLabels,
}) {
  const [isLoading, setLoading] = useState(true);
  const sortedVariants = variants.sort((a, b) => {
    return a.bed - b.bed || a.bath - b.bath;
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="py-3 mb-4 w-100">
      {!isLoading ? (
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <h4 className="m-0 fw-bold">{name}</h4>
            <div className="d-flex mx-3 align-items-center">
              <img src={Pin} alt="pin" />
              <span className="text-muted">{location}</span>
            </div>
          </div>

          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status" className="my-5">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : leasingAvg &&
            referenceRent &&
            targetOccupancy &&
            expLeasesV2 ? (
            sortedVariants.map((variant, index) => {
              const unitType = `${variant.bed}b-${variant.bath}b`;
              return (
                <PropertyVariant
                  key={index}
                  idx={index}
                  propertyId={propertyId}
                  bednum={variant.bed}
                  propertyData={propertyData}
                  bathnum={variant.bath}
                  trends={trends}
                  leasingAvg={leasingAvg[unitType]}
                  referenceRent={referenceRent[unitType]}
                  unitType={unitType}
                  dailyLeads={dailyLeads}
                  outlook={outlook}
                  dateRange={dateRange}
                  competitors={competitors}
                  expLeasesV2={expLeasesV2}
                  newLeases={newLeases}
                  setDateRange={setDateRange}
                  applications={applications}
                  referenceHistory={referenceHistory}
                  targetOccupancy={targetOccupancy[name].occupancy_target}
                  thresholds={thresholds}
                  expirations={expirations}
                  name={name}
                  isStudentProp={isStudentProp}
                  goalsData={goalsData}
                  preLeases={preLeases}
                  leaseTerms = {targetOccupancy[name]["rent_autopush_params"]["new_lease_rent_terms"][1]}
                  renewalOutlook = {renewalOutlook}
                  renewalOutlookLabels={renewalOutlookLabels}
                />
              );
            })
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status" className="my-5">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default LeaseProperty;
