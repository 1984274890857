export function formatExpirationChartData(expirations) {
  const keys = Object.keys(expirations);
  const expirationArr = [];
  keys.forEach((unit) => {
    expirationArr.push(expirations[unit]);
  });
  return expirationArr;
}
export function formatThresholds(expirations, thresholds) {
  const keys = Object.keys(expirations);
  const thresholdArr = [];
  keys.forEach((unit) => {
    thresholdArr.push(thresholds[unit]);
  });
  return thresholdArr;
}
export function formatExpirationLabels(expirations) {
  const months = [];
  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  Object.entries(expirations).forEach((entry) => {
    months.push(capitalizeFirstLetter(entry[0]));
  });
  return months;
}
