import React from "react";
import unitLogo from "../../Buttons/Vector.svg";
import { ProgressBar } from "react-bootstrap";
import "./unitsInfo.css";
function UnitsInfo({ totalUnits, occupancyTarget }) {
  return (
    <div
      className="units-info mt-4 shadow-sm border mx-2 d-flex align-items-center"
      style={{
        backgroundColor: "#FF6746",
        // width: "650px",
        minHeight: "40%",
        maxHeight: "45%",
        borderRadius: "14px",
      }}
    >
      <img
        src={unitLogo}
        alt="unitLogo"
        className="ms-5"
        style={{ width: "71px", height: "100%" }}
      />
      <div>
        <div className="d-flex align-items-center mx-4">
          <div className="d-flex flex-column">
            <span className="lead fw-bold text-white">Total Units</span>
            <div>
              <ProgressBar
                variant="white"
                now={(occupancyTarget / totalUnits) * 100}
                style={{
                  height: "8px",
                  width: "315px",
                  backgroundColor: "#FF7B5F",
                }}
              />
              <div className="d-flex justify-content-between mt-1">
                <small className="fw-bold text-white">
                  Target {Math.floor(occupancyTarget)} units
                </small>
                <small className=" fw-bold text-white m-0">
                  {((occupancyTarget / totalUnits) * 100).toFixed(1)}%
                </small>
              </div>
            </div>
          </div>
          <h2 className="fw-bold text-white m-0 ms-4">{totalUnits}</h2>
        </div>
      </div>
    </div>
  );
}

export default UnitsInfo;
