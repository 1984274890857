import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Success from "../assets/success.gif";
import Error from "../assets/error.png";

export default function PushStatusModal({
  pushLoading,
  setPushLoading,
  pushMessage,
  setPushMessage,
}) {
  console.log(pushMessage);
  return (
    <Modal
      show={pushLoading}
      onHide={() => setPushLoading(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {pushMessage === "Success" ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={Success} alt="success gif" className="w-50" />
            <h6 className="fw-bold">{pushMessage}</h6>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={Error} alt="error gif" style={{ width: "18%" }} />
            <h5 className="fw-bold text-center my-3 text-danger">
              {pushMessage}
            </h5>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
