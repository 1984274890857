import React from "react";
import BlueCircularProgressBar from "../../../Progress Bar/BlueCircularProgressBar";

const PreLeases = ({totalPreLeases}) =>{
    return (
        <div
        className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
        style={{
          // width: "318px",
          height: "100%",
          borderRadius: "14px",
        }}
      >
        <h6 className="fw-bold mt-3 text-center">Pre Leases</h6>
        <div className="d-flex justify-content-around my-auto px-2">

          <BlueCircularProgressBar
            strokeWidth="8"
            sqSize="90"
            percentage={parseInt(totalPreLeases)}
          />
        </div>
      </div>
    )
}

export default PreLeases