import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./Axios";
import { unAuthAxios } from "./unAuthAxios";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const useAuth = () => {
  let navigate = useNavigate();
  const { user, setUser, isLoading } = useContext(AuthContext);
  const [error, setError] = useState(null);
  //set user
  const setUserContext = (newUser) => {
    setUser(newUser);
    navigate("/");
  };
  const saveUserToken = (token) => {
    localStorage.setItem("access", token);
  };

  const saveRefreshToken = (token) => {
    localStorage.setItem("refresh", token);
  };

  const login = (username, password) => {
    return unAuthAxios
      .post("token", {
        username: username,
        password: password,
      })
      .then(async (res) => {
        saveUserToken(res.data.access);
        saveRefreshToken(res.data.refresh);
        setUserContext(res.data.access);
        toast.success("Login Successful", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.error("fail", err);
        setError(err.response?.data);
        toast.error("Incorrect Username or Password", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const logout = () => {
    try {
      localStorage.clear();
      setUser(null);
      navigate("/login");
      toast.success("Logout Successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch {
      toast.error("Logout Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const logout = (scb, fcb) => {
  //   return axios
  //     .post("logout", {
  //       refresh_token: localStorage.getItem("refresh"),
  //     })
  //     .then((data) => {
  //       console.log("success", data);
  //       localStorage.clear();
  //       setUser(null);
  //       scb();
  //     })
  //     .catch((err) => {
  //       console.log("fail", err);
  //       fcb();
  //     });
  // };

  return {
    error,
    login,
    logout,
  };
};

export default useAuth;
