import React from "react";
import CompetitorFloorPlan from "./CompetitorFloorPlan";

export default function CompetitorProperty({
  name,
  competitorPropertyId,
  records,
  unitTypes,
}) {
  return (
    <div
      className="d-flex flex-column my-2 shadow-sm border pb-4 p-3"
      style={{ borderRadius: "14px" }}
    >
      <div>
        <h5 className="fw-normal ms-2 mt-1">{name}</h5>
      </div>
      <div className="flex flex-column justify-content-center align-items-center">
        {Object.keys(records).length !== 0 ? (
          Object.entries(records).map((record, idx) => {
            return (
              <CompetitorFloorPlan
                key={idx}
                floorPlan={record[0]}
                record={record[1]}
                unitTypes={unitTypes}
                competitorPropertyId={competitorPropertyId}
              />
            );
          })
        ) : (
          <h6 className="fw-bold mx-auto my-4 text-center">
            No floorplans found
          </h6>
        )}
      </div>
    </div>
  );
}
