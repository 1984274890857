export default function formatExpirations(data) {
  if (data) {
    const now1 = new Date();
    const getX = (d) => {
      return parseInt((new Date(d) - now1) / (1000 * 60 * 60 * 24), 10);
    };
    const notice = [];
    const signed = [];
    const pending = [];
    const rawNotice = [];
    const rawSigned = [];
    const rawPending = [];
    data.notice.forEach((item, idx) => {
      notice.push({ x: getX(item.end_date), y: item.rent });
      rawNotice.push({
        unit: item.unit_number,
        end: item.end_date,
        building: item.building,
      });
    });
    data.signed_renewals.forEach((item) => {
      signed.push({ x: getX(item.end_date), y: item.rent });
      rawSigned.push({
        unit: item.unit_number,
        end: item.end_date,
        building: item.building,
      });
    });
    data.pending_expirations.forEach((item) => {
      pending.push({ x: getX(item.end_date), y: item.rent });
      rawPending.push({
        unit: item.unit_number,
        end: item.end_date,
        building: item.building,
      });
    });
    return {
      noticeData: notice.sort(function (a, b) {
        return a.x - b.x;
      }),
      rawNotice: rawNotice.sort((a, b) => {
        return getX(a.end) - getX(b.end);
      }),
      signedData: signed.sort(function (a, b) {
        return a.x - b.x;
      }),
      rawSigned: rawSigned.sort((a, b) => {
        return getX(a.end) - getX(b.end);
      }),
      pendingData: pending.sort(function (a, b) {
        return a.x - b.x;
      }),
      rawPending: rawPending.sort((a, b) => {
        return getX(a.end) - getX(b.end);
      }),
    };
  } else return [];
}
