import React, { useEffect, useState } from "react";
import DecisionSupportTools from "./DST/DecisionSupportTools";
import { Spinner } from "react-bootstrap";

export default function DstWrapper({
    name,
    bednum,
    bathnum,
    dailyLeads,
    newLeases,
    referenceHistory,
    showMore
}) {

    const [formattedDailyLeads, setFormattedDailyLeads] = useState([]);

    const formatDailyLeads = (leads) => {
        if (leads) {
          const data = [];
          const labels = [];
          leads.forEach((lead, idx) => {
            data.push(lead.leads);
            labels.push(lead.date);
          });
          return {
            data: data.reverse(),
            labels: labels.reverse(),
          };
        } else return [];
      };


      useEffect(() => {
        if (dailyLeads) {
          setFormattedDailyLeads(formatDailyLeads(dailyLeads));
        }
      }, [dailyLeads]);


    return (
        <>
        {formattedDailyLeads !== undefined &&
        formattedDailyLeads.length !== 0 &&
        newLeases && referenceHistory ? (
          <DecisionSupportTools
            months={null}
            bed={bednum}
            name={name}
            bath={bathnum}
            newLeases={newLeases[name]?.[`${bednum}b-${bathnum}b`]}
            rentRoll={null}
            dailyLeads={formattedDailyLeads.data}
            dailyLeadsLabels={formattedDailyLeads.labels}
            referenceHistoryData={referenceHistory[`${bednum}b-${bathnum}b`]}
            showMore={showMore}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        </>
    )
} 