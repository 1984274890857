import React from "react";
import { Button } from "react-bootstrap";

function SolidPrimaryBtn(props) {
  const { text, className, ...rest } = props;

  return (
    <Button
      size="lg"
      {...rest}
      variant="primary"
      style={{
        backgroundColor: " #0997F2",
        color: "white",
        borderRadius: "22px",
        border: "1px",
        fontSize: "14px",
      }}
      className={`d-flex mx-2 align-content-center ${className}`}
    >
      <span className="fw-bold px-3 py-1 text-center">{text}</span>
    </Button>
  );
}

export default SolidPrimaryBtn;
