/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "../../auth/Axios";
import "./competitors.css";
import { useDispatch, useSelector } from "react-redux";
import ClientProperty from "./ClientProperty";
import SearchBar from "../New Leases/SearchBar";
import Spinner from "../Spinner";
import { loadCompetitorsHistory } from "../../Actions/propertyActions";

function Competitors({ fetch }) {
  const [selectedProperties, setSelectedProperties] = useState([
    { value: "all", label: "All" },
  ]);
  const [loading, setLoading] = useState(false);
  // const propertiesRedux = useSelector(
  //   (state) => state.propertyReducer.properties
  // );
  const [selectedPropNames, setSelectedPropNames] = useState([]);
  const properties = useSelector((state) => state.propertyReducer.properties);
  const showProperties = (data) => {
    try {
      if (data || selectedProperties) {
        setLoading(true);
        const selectedPropNames = [];
        if (
          selectedProperties.length === 0 ||
          selectedProperties.some((prop) => prop.value === "all")
        ) {
          selectedPropNames.push(...Object.keys(data));
        } else {
          let final = selectedProperties.filter((prop) => prop.value !== "");
          console.log(final);
          final.forEach((prop) => {
            selectedPropNames.push(prop.label);
          });
        }
        setSelectedPropNames(selectedPropNames);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      fetch();
    }
  };
  const dispatch = useDispatch();
  const competitors = useSelector(
    (state) => state.propertyReducer.competitorsHistory
  );

  useEffect(() => {
    if (Object.keys(competitors).length === 0) {
      dispatch(loadCompetitorsHistory());
    }
  }, []);
  useEffect(() => {
    showProperties(properties);
  }, [selectedProperties, properties]);

  return (
    <div className="competitors mx-auto py-5" style={{ width: "80%" }}>
      <div className="d-flex flex-column">
        <h3 className="fw-bold mt-4">Competitors</h3>
        <div className="d-flex align-items-center justify-content-between">
          <SearchBar
            leaseProperties={Object.keys(properties).sort((a, b) => {
              return a.localeCompare(b);
            })}
            setSelectedProperties={setSelectedProperties}
          />
          {/* <PrimaryBtn
            onClick={fetchRecords}
            text="Refresh"
            className="h-50 w-50"
          /> */}
        </div>
      </div>

      {selectedPropNames.length !== 0 && loading === false ? (
        Object.entries(
          Object.fromEntries(Object.entries(properties).sort())
        ).map((property, idx) => {
          return selectedPropNames.includes(property[0]) ? (
            <ClientProperty
              key={idx}
              name={property[0]}
              propertyId={property[1].property_id}
              propertyData={property[1]}
              competitors={competitors}
            />
          ) : null;
        })
      ) : (
        <div className="d-flex w-100 align-items-center justify-content-center">
          <Spinner animation="border" role="status" className="my-5">
            <h6 className="fw-bold mt-3">
              Please wait while we load the Competitors...
            </h6>
            <span>Crunching some numbers :)</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}

export default Competitors;
