import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import DisabledPrimaryBtn from "../Buttons/DisabledPrimaryBtn";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box, Checkbox } from "@mui/material";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import RenewalsOptions from "./RenewalsOptions";
import axios from "../../auth/Axios";
import sync from "../assets/sync.png";
import doneIcon from "../assets/done.png";
import { Spinner } from "react-bootstrap";
import PushStatusModal from "./PushStatusModal";
import { DatePicker, Switch } from "antd";

const { RangePicker } = DatePicker;
export default function Expirations({
  propertyName,
  dateRange,
  setDateRange,
  expiringUnits,
  unitIds,
  propertyId,
}) {
  const [areRowsSelected, setareRowsSelected] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [renewals, setRenewals] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pushLoading, setPushLoading] = useState(false);
  const [pushMessage, setPushMessage] = useState("");
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [formattedExpiringUnits, setFormattedExpiringUnits] = useState([]);
  const dateFormat = ["MM/DD/YYYY", "MM/DD/YYYY"];

  const formatExpiringUnits = (data) => {
    if (data) {
      setLoading(true);
      const syncing = <img src={sync} style={{ width: "40px" }} alt="sync" />;
      const done = <img src={doneIcon} style={{ width: "35px" }} alt="done" />;
      const columns = [
        "unit_number",
        "building_name",
        "unit_type",
        "current_rent",
        "new_rent",
        "new_lease_price",
        "discount_to_pricing",
        "increase%",
        "increase_amount",
        "lease_end_date",
        "synced",
        "unit_id"
      ];
      const rowData = [];
      data.forEach((item, idx) => {
        const row = [];
        columns.forEach((column, idx) => {
          if (idx === 3 || idx === 4 || idx === 5 || idx === 8) {
            row.push(`$${Math.round(item[column])}`);
          } else if (idx === 7) {
            row.push(`${item[column]}%`);
          } else if (idx === 6) {
            row.push(`$${Math.round(item.new_lease_price - item.new_rent)}`);
          } else if (idx === 10) {
            item[column] ? row.push(done) : row.push(syncing);
          } else {
            row.push(item[column]);
          }
        });
        rowData.push(row);
      });
      setLoading(false);
      return rowData;
    } else {
      console.log("expiringUnits not found");
      setTimeout(() => formatExpiringUnits(expiringUnits), 5000);
    }
  };

  const formattingExpiringUnits = (expiringUnits) => {
    setFormattedExpiringUnits(formatExpiringUnits(expiringUnits));
  };

  useEffect(() => {
    formattingExpiringUnits(expiringUnits);
  }, [expiringUnits]);

  const cellStyle = {
    setCellProps: () => {
      return {
        style: {
          fontFamily: "Poppins,sans-serif",
          cursor: "pointer",
          textAlign: "center",
        },
      };
    },
    // customHeadRender: (columnMeta, updateDirection) => (
    //   <th className="text-center py-3" onClick={() => updateDirection(1)}>
    //     <h6 className="fw-bold">{columnMeta.name}</h6>
    //   </th>
    // ),
  };
  const columns = [
    { name: "Unit number", options: cellStyle },
    { name: "Building", options: cellStyle },
    { name: "Unit Type", options: cellStyle },
    { name: "Current Rent", options: cellStyle },
    { name: "Max Lease Term Price", options: cellStyle },
    { name: "New Lease Price", options: cellStyle },
    { name: "Discount to Asking Rent", options: cellStyle },
    {
      name: "% Increase",
      options: cellStyle,
    },
    {
      name: "$ Increase",
      options: cellStyle,
    },
    {
      name: "Lease End Date",
      options: cellStyle,
    },
    {
      name: "Status",
      options: cellStyle,
    },
    {
      name: "unit_id",
      options: {display:false}
    }
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    rowsPerPage: 20,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 15, 20, 50],
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div
          className="mt-4 d-flex justify-content-center"
          style={{ fontFamily: "Poppins,sans-serif" }}
        >
          <OutlinedPrimaryBtn
            onClick={() => applyNewFilters()}
            text={"Apply Filters"}
          ></OutlinedPrimaryBtn>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      if (rowsSelected.length === 0) {
        setareRowsSelected(false);
      } else {
        setRowsSelected(allRowsSelected);
        setareRowsSelected(true);
      }
    },
    onRowClick: (rowData, dataIndex, rowIndex) => {
      console.log("Row data:", rowData)
      setSelectedUnitId(unitIds[rowData[0]]);
      fetchRenewalOptions({
        new_lease_price: rowData[4],
        unit_id: rowData[11],
        end_date: rowData[9],
        unit_type: rowData[2],
        unit_number: rowData[0],
        twelveMonth: rowData[5],
      });
    },
    // customRowRender: (data, dataIndex, rowIndex) => {
    //   console.log(data);
    //   return (
    //     <tr>
    //       {data.map((item) => {
    //         return <td>{item}</td>;
    //       })}
    //     </tr>
    //   );
    // },
  };

  const CustomCheckbox = (props) => {
    return <Checkbox {...props} />;
  };

  const formatRenewalOptions = (data, selectedRow) => {
    if ((data, selectedRow)) {
      const options = [];
      const end = new Date(selectedRow.end_date);
      console.log("END DATE:", end, selectedRow.end_date)
      data.renewal_options.forEach((item, idx) => {
        const row = [];
        const increment = 30 * (idx + 1);
        const expUTC = new Date(
          new Date(end).setDate(end.getDate() + increment)
        );
        const newExpiry =
          expUTC.getMonth() +
          1 +
          "/" +
          expUTC.getDate() +
          "/" +
          expUTC.getFullYear();

        row.push(
          idx + 1,
          `$${item.rent}`,
          `${parseFloat(item["change%"]).toFixed(1)}%`,
          `$${item["change$"]}`,
          newExpiry
        );
        options.push(row);
      });

      return {
        current_rent: data.current_rent,
        unit_number: selectedRow.unit_number,
        unit_type: selectedRow.unit_type,
        current_exp: selectedRow.end_date,
        twelveMonth: selectedRow.twelveMonth,
        data: options,
      };
    }
  };

  const extractNLPTMO = (data, sRows) => {
    if (data && sRows) {
      let temp = [];
      console.log(unitIds);
      const rowsDataIndices = sRows.map((row) => {
        return row.dataIndex;
      });
      rowsDataIndices.forEach((row) => {
        temp.push({
          unit_id: unitIds[data[row][0]],
          renewal_offer: data[row][4],
          new_lease_price: data[row][5],
        });
      });
      return temp;
    } else {
      extractNLPTMO(formattedExpiringUnits, rowsSelected);
    }
  };

  const batchPushtoEntrata = async (data) => {
    await axios
      .post("batch-push-renewal-rents", {
        property_id: propertyId,
        units: data,
      })
      .then((res) => {
        setPushMessage(res.data.message);
      })
      .catch((err) => {
        console.error(err);
        if (err.message.search("403"))
          setPushMessage("Pushing rents for this property is not allowed");
      });
  };

  const fetchRenewalOptions = async (data) => {
    setOptionsLoading(true);
    setShow(true);
    await axios
      .post("get-renewal-options", {
        renewal_offer: parseInt(data.new_lease_price.split("$")[1]),
        new_lease_price: parseInt(data.twelveMonth.split("$")[1]),
        unit_id: data.unit_id,
      })
      .then((res) => {
        setRenewals(formatRenewalOptions(res.data, data));
        setOptionsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  const onSwitchClick = (checked) => {
    // console.log(expiringUnits)
    if (!checked){
      let temp = []
      expiringUnits.map((unit, idx)=>{
        if (unit['lease_end_date'] == '-'){
          // console.log(unit)
          temp.push(unit)
        }
      })
      formattingExpiringUnits(temp)
    }
    else{
      formattingExpiringUnits(expiringUnits)
    }
  }

  return (
    <div
      className="mx-2 mt-3 shadow-sm border w-100 px-4 py-2"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex justify-content-between">
        <h4 className="fw-bold my-3">Expirations</h4>

        <PrimaryBtn
            text="Renewal Logic Report"
            onClick={
              () => {
                axios.get('get-renewal-logic-report', {
                  params: {
                    propertyId: propertyId
                  },
                })
                .then(res => {
                  console.log(res.data)
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a'); //hide this link
                  link.href = url;
                  link.setAttribute('download', 'Renewal Logic Report--' + propertyName + '.csv' ); //or any other extension
                  link.setAttribute('hidden', true);
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(err => {
                  console.log(err)
                })
              }
            }
            className="w-50 my-2"
            style={{ height: "37%" }}
          />


        <PrimaryBtn
            text="Renewal Offer Report"
            onClick={
              () => {
                const startDate = `${
                  dateRange[0].getMonth() + 1
                }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`;
                const endDate = `${
                  dateRange[1].getMonth() + 1
                }/${dateRange[1].getDate()}/${dateRange[1].getFullYear()}`;
                axios.get('get-renewal-offer-report', {
                  params: {
                    propertyId: propertyId,
                    start_date: startDate,
                    end_date: endDate
                  }
                })
                .then(res => {
                  console.log(res.data)
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a'); //hide this link
                  link.href = url;
                  link.setAttribute('download', 'Renewal Offer Report--' + propertyName + '.csv' ); //or any other extension
                  link.setAttribute('hidden', true);
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(err => {
                  console.log(err)
                })
              }
            }
            className="w-50 my-2"
            style={{ height: "37%"}}
          />


        {pushLoading ? (
          <PushStatusModal
            pushLoading={pushLoading}
            setPushLoading={setPushLoading}
            pushMessage={pushMessage}
          />
        ) : null}
        {areRowsSelected ? (
          <PrimaryBtn
            text="Sync with PMS"
            onClick={async () => {
              setPushLoading(true);

              const pushData = await extractNLPTMO(
                formattedExpiringUnits,
                rowsSelected
              );
              batchPushtoEntrata(pushData);
            }}
            // onClick={() => formattingExpiringUnits(expiringUnits)}
            className="w-50 my-2"
            style={{ height: "50%" }}
          />
        ) : (
          <DisabledPrimaryBtn
            text="Sync with PMS"
            className="w-50  my-2"
            style={{ height: "50%" }}
          />
        )}
      </div>
      <div className="d-flex justify-content-between my-2">
        <div>
      <Switch checkedChildren="All units" unCheckedChildren="MTM units" defaultChecked onChange={onSwitchClick} />
      </div>
        <RangePicker
          format={dateFormat}
          onChange={(newValue) => {
            setDateRange([newValue[0]._d, newValue[1]._d]);
          }}
        />
      </div>
      <ThemeProvider theme={createTheme()}>
        {isLoading ? (
          <div className="d-flex justify-content-center w-100">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <MUIDataTable
            title="Expirations Data Table"
            data={formattedExpiringUnits}
            columns={columns}
            options={options}
          />
        )}
        <RenewalsOptions
          show={show}
          unitId={selectedUnitId}
          isLoading={optionsLoading}
          renewals={renewals}
          setShow={setShow}
        />
      </ThemeProvider>
    </div>
  );
}
