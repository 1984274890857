import React from "react";
// import GreenCircularProgressBar from "../../Progress Bar/Green/GreenCircularProgressBar";
import { Line } from "react-chartjs-2";
function Trend({ trendsData }) {
  //   const data = {
  //     labels: ["Occupied", "Unoccupied"],
  //     datasets: [
  //       {
  //         label: "Occupancy",
  //         data: [71, 29],
  //         backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
  //         borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
  //         borderWidth: 1,
  //       },
  //     ],
  //   };
  const func = () => {
    const dates = [];
    for (let prior = 0; prior <= 14; prior++) {
      let now = new Date();
      let last30days = new Date(now.setDate(now.getDate() - prior));
      dates.push(`${last30days.getMonth() + 1}/${last30days.getDate()}`);
    }
    return dates.reverse();
  };
  const data = {
    labels: func(),
    datasets: [
      {
        label: "Trend",
        data: trendsData,
        borderColor: "#FF6746",
        borderWidth: 3,
        smooth: true,
      },
    ],
  };
  const options = {
    elements: {
      line: {
        tension: 0.6,
      },
    },
    scales: {
      x: {
        display: false,
        // grid: {
        //   display: false,
        //   drawBorder: false,
        //   drawOnChartArea: false,
        //   drawTicks: false,
        // },
      },
      y: {
        // display: false,
      },
    },
    // responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "average",
      },
    },
    scaleShowLabels: false,
  };
  return (
    <div
      className="border shadow-sm mx-2 w-50 my-3 px-2 d-flex flex-column"
      style={{ borderRadius: "14px", height: "100%" }}
    >
      <div className="my-3">
        <h6 className="text-center mb-0 fw-bold">Trends</h6>
        <small className="text-center fw-normal d-block">
          (for past 15 Days)
        </small>
      </div>
      <Line
        style={{ marginTop: "10px" }}
        data={data}
        width={30}
        height={10}
        options={options}
      />
    </div>
  );
}
export default Trend;
