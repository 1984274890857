import { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SelectUnit(props) {
  const { units, selectedUnit, setSelectedUnit, ...rest } = props;
  const [options, setOptions] = useState([]);
  const animatedComponents = makeAnimated();
  const formatOptions = (data) => {
    const rawData = [];
    data.forEach((item) => {
      rawData.push({ value: item, label: item });
    });
    setOptions(rawData);
  };
  useEffect(() => {
    formatOptions(units);
  }, [units]);
  return (
    <div className="w-100">
      <Select
        {...rest}
        style={{ borderRadius: "16px" }}
        closeMenuOnSelect={true}
        components={animatedComponents}
        // defaultValue={{ value: options[0], label: options[0] }}
        onChange={(val) => setSelectedUnit(val.value)}
        options={options}
      />
    </div>
  );
}
