import React from "react";
import House from "./Vector.svg";
function LegendBtn({ label, subLabel, color }) {
  return (
    <div className="d-flex justify-content-end align-items-center m-2">
      <div
        className="p-3 d-flex legend-w align-items-center justify-content-center"
        style={{
          borderRadius: "20%",
          width: "3.25rem",
          height: "90%",
          background: color,
          display: "inline-block",
        }}
      >
        <img src={House} alt="legendBtn" />
      </div>
      <div className="d-flex flex-column justify-content-center mx-2">
        <span className="text-muted">{subLabel}</span>
        <h6 className="fw-bold m-0">{label}</h6>
      </div>
    </div>
  );
}

export default LegendBtn;
