import React from 'react';
import { Line } from 'react-chartjs-2';

const GoalChart = ({goalsData, propertyName, unitType}) => {

  const data = {
    labels: goalsData[propertyName].labels,

    datasets: [
      {
        label: 'Target Line',
        data: goalsData[propertyName].target_data,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        lineTension: 0.1
      },
      {
        label: 'Actual Line',
        data: goalsData[propertyName][unitType].actual_data,
        fill: false,
        borderColor: 'rgba(192,75,75,1)',
        lineTension: 0.1
      }
    ]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 120,
        stepSize: 20
      }
    }
  };

  return (

    <div
        className="bg-white px-3 shadow-sm border mx-2 px-1 py-4 my-2 mt-4"
        style={{ borderRadius: "14px", width: "95%" }}
    >
        <div className="d-flex justify-content-between mb-5 mx-4">
        <div>
            <h4 className="m-0 fw-bold">Goals Charts</h4>
            <span>Actual V/s Threshold</span>
        </div>
        </div>

        <Line data={data} options={options} />

    </div>

  );
};

export default GoalChart;
