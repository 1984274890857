import React from "react";
import BlueCircularProgressBar from "../../Progress Bar/BlueCircularProgressBar";

const Preleases = ({occupancy, preLeaseOccupancy}) => {
    return (
        <div className="preleases_wrapper d-flex flex-row">
            <div
                className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                style={{
                // width: "318px",
                height: "100%",
                borderRadius: "14px",
                }}
            >
                <h6 className="fw-bold mt-3 text-center">Occupancy</h6>
                <div className="d-flex justify-content-around my-auto px-2 py-2">
                <BlueCircularProgressBar
                    strokeWidth="8"
                    sqSize="90"
                    percentage={occupancy}
                />
                </div>
            </div>


            <div
                className="bg-white occupancy w-50 shadow-sm border d-flex flex-column mx-2 my-3"
                style={{
                // width: "318px",
                height: "100%",
                borderRadius: "14px",
                }}
            >
                <h6 className="fw-bold mt-3 text-center">Pre Leases</h6>
                <div className="d-flex justify-content-around my-auto px-2 py-2">
                <BlueCircularProgressBar
                    strokeWidth="8"
                    sqSize="90"
                    percentage={preLeaseOccupancy}
                />
                </div>
            </div>

        </div>
    )
}
export default Preleases;