import { useState, useEffect } from "react";
// import { Line, Scatter } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import "./OutlookTooltip.css";
function Outlook({ expirations, referenceRent }) {
  const [referenceRentCharts, setReferenceRentCharts] = useState([]);
  const scatterLabels = (notice, signed, pending) => {
    if (notice && signed && pending) {
      const noticeDates = [];
      notice.forEach((item) => {
        noticeDates.push(item.x);
      });
      const signedDates = [];
      signed.forEach((item) => {
        signedDates.push(item.x);
      });
      const pendingDates = [];
      pending.forEach((item) => {
        pendingDates.push(item.x);
      });
      const labels = [];
      noticeDates.forEach((item, idx) => {
        if (!signedDates.includes(item)) {
          labels.push(item);
        } else return;
      });
      signedDates.forEach((item, idx) => {
        if (!labels.includes(item)) {
          labels.push(item);
        } else return;
      });
      pendingDates.forEach((item, idx) => {
        if (!labels.includes(item)) {
          labels.push(item);
        } else return;
      });
      return labels.sort((a, b) => a - b);
    } else {
      console.log("notice, signed not found");
    }
  };
  const labels = scatterLabels(
    expirations.noticeData,
    expirations.signedData,
    expirations.pendingData
  );
  const referenceData = (labels, referenceRent) => {
    if (labels && referenceRent) {
      const series = [];
      // const refData = [];
      // labels.forEach((label) => {
      //   refData.push({ x: label, y: ref });
      // });
      // return refData;
      Object.entries(referenceRent).forEach((ref) => {
        const unit = {};
        const data = [];
        labels.forEach((label) => {
          data.push({ x: label, y: ref[1] });
        });
        unit.name = `${ref[0]} Reference Rent`;
        unit.type = "line";
        unit.data = data;
        series.push(unit);
      });
      return series;
    } else {
      setTimeout(() => referenceData(labels), 3000);
    }
  };
  useEffect(() => {
    setReferenceRentCharts(referenceData(labels, referenceRent));
  }, []);
  const getMarkers = (referenceRentCharts) => {
    if (referenceRentCharts) {
      const m = [];
      referenceRentCharts.forEach(() => {
        m.push(0);
      });
      return [7, 7, 7, ...m];
    } else return [7, 7];
  };
  const apexOptions = {
    series: [
      {
        name: "Notices",
        type: "scatter",
        data: expirations.noticeData,
      },
      {
        name: "Signed Renewals",
        type: "scatter",
        data: expirations.signedData,
      },
      {
        name: "Pending Expirations",
        type: "scatter",
        data: expirations.pendingData,
      },
      ...referenceRentCharts,
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 350,
        type: "line",
      },
      colors: [
        "#FF507A",
        "#FFB067",
        "#37D159",
        "#0997F2",
        "#000",
        "#F1E05A",
        "#F1E05A",
      ],
      fill: {
        type: "solid",
      },

      markers: {
        size: getMarkers(referenceRentCharts),
      },

      legend: {
        show: true,
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
        offsetY: 5,
      },
      xaxis: {
        type: "numeric",
        labels: {
          formatter: function (val, opts) {
            return val.toFixed(0) + " Days";
          },
        },
      },
      tooltip: {
        theme: "dark",
        shared: false,
        intersect: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const name = apexOptions.series[seriesIndex].name;
          const data = series[seriesIndex][dataPointIndex];
          if (
            name === "Signed Renewals" ||
            name === "Notices" ||
            name === "Pending Expirations"
          ) {
            const pointer =
              name === "Signed Renewals"
                ? expirations.rawSigned[dataPointIndex]
                : name === "Notices"
                ? expirations.rawNotice[dataPointIndex]
                : name === "Pending Expirations"
                ? expirations.rawPending[dataPointIndex]
                : null;
            return (
              "<div className='outlook-tooltip'>" +
              "<p className='item'>" +
              "Rent : $" +
              data +
              "</p>" +
              "<p> Unit Number : " +
              pointer.unit +
              "</p>" +
              "<p> End Date : " +
              pointer.end +
              "</p>" +
              "<p> Building : " +
              pointer.building +
              "</p>" +
              "</div>"
            );
          } else {
            return (
              "<div className='outlook-tooltip'>" +
              "<p className='item'>" +
              name +
              " : " +
              data +
              "</p>" +
              "</div>"
            );
          }
        },
        x: {
          show: true,
        },
      },
    },
  };

  return (
    <div
      className="bg-white px-3 shadow-sm border mx-auto py-4 my-2 mt-4"
      style={{ borderRadius: "14px", width: "95%" }}
    >
      <div className="d-flex flex-column my-2 mx-4">
        <h4 className="m-0 fw-bold">90 Day Outlook</h4>
        <span className="text-muted">
          Signed Renewals, pending expirations & new lease pricing
        </span>
      </div>
      {/* <Line options={options} width={30} height={10} data={data} /> */}
      <ReactApexChart
        series={apexOptions.series}
        options={apexOptions.options}
        type="line"
        height={450}
      />
    </div>
  );
}

export default Outlook;
