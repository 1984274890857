import { React, useState, useEffect, useContext } from "react";
import Spinner from "../Spinner";
import { useSelector, useDispatch } from "react-redux";
import { ParamDiv } from "./ParamDiv";
import { AdminContext } from "../../auth/AdminContext";
import { SuperuserContext } from "../../auth/SuperuserContext";
import axios from "../../auth/Axios";
import swal from 'sweetalert';
import { Button } from "react-bootstrap";
import SearchBar from "../New Leases/SearchBar";
import { loadBrains } from "../../Actions/propertyActions";

const Parameters = ({ fetch }) => {
    const { isAdmin, setAdmin } = useContext(AdminContext);
    const { isSuperuser, setSuperuser } = useContext(SuperuserContext);
    const [selectedProperties, setSelectedProperties] = useState([
        { value: "all", label: "All" },
      ]);
    const [selectedPropNames, setSelectedPropNames] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const parameters = useSelector(
        (state) => state.propertyReducer.brains
      );

    const showProperties = (data) => {
        try {
          if (data || selectedProperties) {
            setLoading(true);
            const selectedPropNames = [];
            if (
              selectedProperties.length === 0 ||
              selectedProperties.some((prop) => prop.value === "all")
            ) {
              selectedPropNames.push(...Object.keys(data));
            } else {
              let final = selectedProperties.filter((prop) => prop.value !== "");
              final.forEach((prop) => {
                selectedPropNames.push(prop.label);
              });
            }
            setSelectedPropNames(selectedPropNames);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          fetch();
        }
      };

      const dispatch = useDispatch();
      const fetchBrains = () => {
        if (!parameters || Object.keys(parameters).length === 0) {
            dispatch(loadBrains());
          }
      }

      useEffect(() => {
        showProperties(parameters);
        fetchBrains();
      }, [selectedProperties, parameters]);

    const pushPropertyRent = (property) => {
        const data = {
            property_name:property
        }
        axios.post('update-property-rent', data)
        .then(res => {
            swal("Started", "Manual price push has started.", "success")
        })
        .catch(err => {
            swal("Oops!!", "Manual price push not allowed for this property.", "error")
        })
    }

    const fetchPropertyData =(property) => {
        const data = {
            params: {
                property_name:property
            }
        }
        axios.get('trigger-scheduler-run', data)
        .then(res => {
            swal("Started", "Pulling Data for " + property, "success")
        })
        .catch(err => {
            swal("Oops!!", "Some Data Occured. Please Try Later.", "error")
        })
    }

    return (
        <>
            <div className="bg-white d-flex w-75 h-100 flex-column mx-auto pt-5 mb-5">
                <div className="d-flex flex-column mt-4">
                    <h3 className="m-0 fw-bold">Parameters</h3>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <SearchBar
                    leaseProperties={Object.keys(parameters).sort((a, b) => {
                        return a.localeCompare(b);
                    })}
                    setSelectedProperties={setSelectedProperties}
                    />
                </div>

                    {
                        isLoading ? (
                            <div className="d-flex w-75 h-150 align-items-center justify-content-center">
                            <Spinner animation="border" role="status" className="my-5">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            </div>
                        ) 
                        :
                        selectedPropNames.length !== 0 ? (
                            Object.entries(
                                Object.fromEntries(Object.entries(parameters).sort())
                              ).map((property, index) => {
                                return selectedPropNames.includes(property[0]) &&
                        // Object.entries(parameters).map((property, idx) => {
                        // return (
                            <>
                                <div key = {index} className="d-flex flex-column shadow border m-3 mt-4 py-4 p-3"
                                    style={{ borderRadius: "14px" }}
                                    >
                                    <div className="d-flex flex-column w-100">
                                        <div className="property-details d-flex flex-row justify-content-start gap-5">
                                            <span className="m-0 ms-2 fw-bold fs-4">{property[0]}</span>
                                        </div>
                                    </div>
                                    <ParamDiv propertyName ={property[0]}  val = {property[1]}  />

                                    <div style = {{display:"flex", justifyContent:"flex-end"}}>
                                        <>
                                            {(isAdmin || isSuperuser) && (
                                                    <Button
                                                        size="md"
                                                        variant="primary"
                                                        style={{
                                                            borderRadius: "22px",
                                                            fontSize: "14px",
                                                        }}
                                                        className={`d-flex mx-2 text-center align-content-center btn-primary`}
                                                        onClick = {
                                                            ()=> {
                                                                pushPropertyRent(property[0])
                                                            }
                                                        }
                                                        >
                                                        <span className="fw-bold px-3 py-1 text-center">Push Rent</span>
                                                    </Button>
                                                )}
                                        </>

                                        <>
                                            {(isAdmin || isSuperuser) && (
                                                <div style = {{display:"flex", justifyContent:"flex-end"}}>
                                                    <Button
                                                        size="md"
                                                        variant="primary"
                                                        style={{
                                                            borderRadius: "22px",
                                                            fontSize: "14px",
                                                        }}
                                                        className={`d-flex mx-2 text-center align-content-center btn-primary`}
                                                        onClick = {
                                                            ()=> {
                                                                fetchPropertyData(property[0])
                                                            }
                                                        }
                                                        >
                                                        <span className="fw-bold px-3 py-1 text-center">Run Nightly</span>
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </>
                        // )
                    })
                        )
                        : (
                            <Spinner animation="border" role="status" className="my-5">
                            <h6 className="fw-bold mt-3">
                                Please wait while we load the Parameters...
                            </h6>
                            <span>Crunching some numbers :)</span>
                            </Spinner>
                        )
                    }
            </div>
        </>
    )
}

export default Parameters;