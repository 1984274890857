import Pin from "../assets/MapPin.svg";
import CompetitorProperty from "./CompetitorProperty";

export default function ClientProperty({ propertyData, name, competitors }) {
  var competitorCount = 0;
  return (
    <div className="d-flex m-0 mt-3 w-100" style={{ borderRadius: "14px" }}>
      <div className="d-flex flex-column w-100">
        <div className="property-details d-flex flex-row justify-content-start">
          <span className="m-0 ms-2 fw-bold fs-3">{name}</span>
          <div className="d-flex mx-3 align-items-center">
            <img src={Pin} alt="pin" />
            <span className="text-muted">{propertyData.address}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          {competitors.length !== 0
            ? Object.entries(competitors).map((competitor, idx) => {
                return competitor[1].competitor_of === name ? (
                  <div key={idx}>
                    <span className="d-none">{competitorCount++}</span>
                    <CompetitorProperty
                      competitorPropertyId={competitor[1].comp_property_id}
                      records={competitor[1].records}
                      name={competitor[0]}
                    />
                  </div>
                ) : null;
              })
            : null}
          {/* (
            <h6> No Competitors Found </h6>
          ) */}
          {competitorCount === 0 ? (
            <div
              className="my-2 shadow-sm border w-100 py-5"
              style={{ borderRadius: "14px" }}
            >
              <h6 className="fw-bold text-center">No Competitors Found</h6>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
