import React, { useState, useEffect } from "react";
import Pin from "../assets/MapPin.svg";
import Spinner from "../Spinner";
import SearchBar from "../New Leases/SearchBar";
import ReputaionScore from "./ReputationChart";
import ReputationHistory from "./ReputationHistory";
import ReviewsWrapper from "./ReviewsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { loadReputationProperties, loadTodaysData, loadReputationChart, loadRecentReviews } from "../../Actions/reputationActions";


const Reputation = () => {
    const dispatch = useDispatch();
    const reputationProperties = useSelector((state) => state.reputationReducers.reputationProperties);   
    const todaysReputation = useSelector((state) => state.reputationReducers.todaysReputation);  
    const reputationChartData = useSelector((state) => state.reputationReducers.reputationChartData);   
    const recentReviews = useSelector((state) => state.reputationReducers.recentReviews);   

    const [selectedProperties, setSelectedProperties] = useState([
        { value: "all", label: "All" },
      ]);
    const [selectedPropNames, setSelectedPropNames] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const fetchData = () => {
        if (!reputationProperties || reputationProperties === undefined|| Object.keys(reputationProperties).length === 0) {
            dispatch(loadReputationProperties());
          }
        if (!todaysReputation || todaysReputation === undefined|| Object.keys(todaysReputation).length === 0) {
            dispatch(loadTodaysData());
          }
        if (!reputationChartData || reputationChartData === undefined|| Object.keys(reputationChartData).length === 0) {
            dispatch(loadReputationChart());
          }
        if (!recentReviews || recentReviews === undefined|| Object.keys(recentReviews).length === 0) {
            dispatch(loadRecentReviews());
          }
    }

    const showProperties = (data) => {
        try {
          if (data || selectedProperties) {
            setLoading(true);
            const selectedPropNames = [];
            if (
              selectedProperties.length === 0 ||
              selectedProperties.some((prop) => prop.value === "all")
            ) {
              selectedPropNames.push(...Object.keys(data));
            } else {
              let final = selectedProperties.filter((prop) => prop.value !== "");
              final.forEach((prop) => {
                selectedPropNames.push(prop.label);
              });
            }
            setSelectedPropNames(selectedPropNames);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          dispatch(loadReputationProperties());
        }
      };


    useEffect(()=> {
        showProperties(reputationProperties)
        fetchData();
    }, [selectedProperties, reputationProperties])


    return (
        <div  className="ReputationWrapper"
        style={{
            margin:"50px 40px", 
            width:"100%",
            display:"flex",
            flexDirection:"column",
            gap:"1rem"
        }}
        >
            <div>
                <h3 className="fw-bold mt-4">Reputation Manager</h3>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                    <SearchBar
                    leaseProperties={Object.keys(reputationProperties).sort((a, b) => {
                        return a.localeCompare(b);
                    })}
                    setSelectedProperties={setSelectedProperties}
                    />
            </div>

            {
                isLoading ||  selectedPropNames.length === 0 ||
                Object.keys(reputationProperties).length === 0 ||
                Object.keys(todaysReputation).length === 0 ||
                Object.keys(reputationChartData).length === 0
                // Object.keys(recentReviews).length === 0
                ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the Reputation Properties...
                        </h6>
                        <span>Crunching some numbers :)</span>
                    </Spinner>
                )
                :
                (
                    Object.entries(
                        Object.fromEntries(Object.entries(reputationProperties).sort())
                      ).map((property, idx) => {
                        return selectedPropNames.includes(property[0]) &&               
                            <div key={idx} className="m-4 mt-4 py-4 shadow border p-3"
                                style={{ borderRadius: "14px" }}
                            >
                                <div className="d-flex flex-column">
                                    <div className="property-details d-flex">
                                        <div className="d-flex flex-row" style={{width:"100%",justifyContent:"space-between"}}>
                                            <div className="d-flex flex-row">
                                                <span className="m-0 ms-2 fw-bold fs-4">{property[0]}</span>
                                                <div className="d-flex mx-3 align-items-center">
                                                    <img src={Pin} alt="pin" />
                                                    <span className="text-muted">{property[1].address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ReputaionScore chartData={todaysReputation[property[0]]}/>
                                    <ReputationHistory labels = {reputationChartData.labels} dataset = {reputationChartData[property[0]]}  />
                                    {/* <Reviews reviews = {recentReviews[property[0]]}/> */}
                                    <ReviewsWrapper reviews = {recentReviews} property={property[0]}/>
                                </div>
                            </div>
                        } ))
            }
        </div>

    )
}

export default Reputation