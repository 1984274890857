import React, {useState} from "react";
import ReactSpeedometer from "react-d3-speedometer";
import StarRatings from 'react-star-ratings';
import { DatePicker } from "antd";
import { loadMarketingScore } from "../../../../Actions/propertyActions";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";


const { RangePicker } = DatePicker;

const Marketing = ({propertyId,marketingScore}) => {
    // const temp = new Date();
    // const now = new Date();
    // const initialDate = new Date(temp.setDate(now.getDate() - 730));  
    // const finalDate = new Date(temp.setDate(now.getDate() + 730));
    const [dateRange, setDateRange] = useState([]);
    const dateFormat = ["MM/DD/YYYY", "MM/DD/YYYY"];
    const dispatch = useDispatch();

    return (

        <div
        className="bg-white occupancy mx-2 my-3"
        style={{
        display:"flex",
        width: "100%",
      }}>   

            <div className="marketing_wrapper d-flex flex-column-reverse"
                style={{
                    gap:"2rem",
                    width: "100%",
                }}
            >
                <div className="upper_wrapper shadow-sm border"
                        style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            borderRadius: "14px",
                            overflowY: "scroll"
                        }}
                    >
                        <div className="marketing_upper"
                        style = {{
                            maxHeight: "150px",
                            display:"flex",
                            flexDirection : "row",
                            gap : "6rem",
                        }}
                        >
                            <div className="marketing_up_left">
                                <h6 className="fw-bold mt-2 mb-3 text-center"> Leasing Agents </h6>
                                {
                                    Object.entries(marketingScore.agents).map((agent,idx) => {
                                        return ( <p key={idx}> {agent[0]} </p>)
                                    })
                                }
                            </div>

                            <div className="marketing_up_right">
                                <h6 className="fw-bold mt-2 mb-3 text-center"> Leads </h6>
                                {
                                    Object.entries(marketingScore.agents).map((agent,idx) => {
                                        return ( <p key={idx}> {agent[1]} </p>)
                                    })
                                }
                            </div>
                        </div>
                </div>

                
                {/* ======================= start from here ====================================== */}
                <div className="marketing_lower shadow-sm border"
                    style={{
                        // backgroundColor:"blue",
                        display: "flex",
                        flexDirection:"row",
                        justifyContent:"space-around",
                        gap:'3rem',
                        alignItems:"center",
                        borderRadius: "14px",
                        maxHeight:"250px"
                    }}
                >   
                    <div
                        style = {{
                            // paddingTop:"5rem"
                        }}
                    >

                        <h6 className="fw-bold mt-3 text-center">Marketing</h6>

                        <ReactSpeedometer
                        maxValue={100}
                        value={marketingScore.meter}
                        needleColor="steelblue"
                        // startColor="red"
                        segments={3}
                        customSegmentStops={marketingScore.segments}
                        segmentColors={['tomato', 'gold', 'limegreen']}
                        width={200}
                        height={200}
                        ringWidth ={30}
                        // endColor="green"
                        />
                    </div>
                    
                    {/* ======= this is the main part =============== */}
                    <div
                        style={{
                            // backgroundColor:"blue",
                            display: "flex",
                            flexDirection:"column-reverse",
                            gap:'3rem',
                            alignItems:"center",
                            justifyContent:"center"
                        }}
                    >
                        <div
                            style={{
                                // backgroundColor:"blue",
                                display: "flex",
                                flexDirection:"row",
                                gap:'1rem',
                                alignItems:"center",
                                // justifyContent:"center"
                            }}
                        >
                            <RangePicker
                                format={dateFormat}
                                onChange={(newValue) => {
                                setDateRange([newValue[0]._d, newValue[1]._d]);
                                }}
                            />
                            {/* <SolidPrimaryBtn
                                onClick={() => dispatch(loadMarketingScore(propertyId,dateRange)) }
                                className="mt-4 mx-auto"
                                text="Get"
                            /> */}

                            <Button
                                size="sm"
                                variant="primary"
                                style={{
                                    backgroundColor: " #0997F2",
                                    color: "white",
                                    borderRadius: "22px",
                                    border: "1px",
                                    fontSize: "14px",
                                }}
                                className={`d-flex mx-2 align-content-center`}
                                onClick={() => dispatch(loadMarketingScore(propertyId,dateRange)) }
                                >
                                <span className="fw-bold px-3 py-1 text-center">Get</span>
                            </Button>


                        </div>
                        
                        <div>
                            <h6 className="fw-bold mb-4 text-center">Google Score </h6>
                            <StarRatings
                            rating={marketingScore.star_rating}
                            starDimension="40px"
                            starSpacing="5px"
                            starRatedColor="gold"
                            numberOfStars={5}
                            />
                            <h5 className="fw-bold mt-2 text-center"> <i> {marketingScore.star_rating} Stars </i> </h5>
                            

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Marketing;