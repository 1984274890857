import React, { useState } from "react";
import { Container, Form, FloatingLabel, Button } from "react-bootstrap";
import { unAuthAxios } from "../../auth/unAuthAxios";
import { Spinner } from "react-bootstrap";


export default function VerifyEmail() {
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const emailVerify = () => {
    setLoading(true)
    setSuccess(false)
    setError(false)
    const data = {
        // username,
        email, 
    }

    unAuthAxios.post("verify-email", data)
    .then(resp => {
        // console.log(resp.data)
        setError(false)
        setSuccess(true)
        setLoading(false)
    })

    .catch(err => {
        console.log(err)
        setSuccess(false)
        setError(true)
        setLoading(false)
    })
  };

  return (
    
    <div
      className="w-100"
      style={{ background: "#F3F3F3", minHeight: "100vh" }}
    >
      <Container
        className="bg-white py-5 d-flex w-50 shadow border flex-column justify-content-center"
        style={{ borderRadius: "50px", margin: "7% auto" }}
      >
        <img
          src="./logo.png"
          className="mx-auto my-4"
          style={{ width: "30%" }}
          alt="revvy"
        />
        <div className="flex-column w-50 my-2 mb-3 mx-auto">
          <h3 className="fw-bold">Password Reset</h3>
          <h6>Please Enter Your Email Address. An Email Will be Sent to the Provided Address. If You Do Not
            Receive an Email, Please Reach Out to Your Revvy Admin.
          </h6>
        </div>

        {
            error? 
            <div className="Response flex-column w-50 my-2 mb-3 mx-auto">
                <h6 style={{
                    color:"red"
                }}>Error!!! Please Enter a Valid Email.
                </h6>
            </div>
            : null
        }

        {
            success ?
            <div className="Response flex-column w-50 my-2 mb-3 mx-auto">
                <h6 style={{
                    color:"green"
                }}>Email Sent Successfully. 
                {/* Please Check. <Link to = "/reset-password/112"> Here </Link> to Login. */}
                </h6>
            </div>
            : null

        }

        <Form className="d-flex flex-column w-50 mx-auto justify-content-center">
            {/* <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className="mb-3"
            >
                <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                />
            </FloatingLabel> */}

            <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
            >
                <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email"
                />
            </FloatingLabel>

          {
            loading ?
            <Spinner animation="border" role="status" className="my-5 mx-auto">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <Button
              type = "button"
              onClick={emailVerify}
              variant="outline-dark"
              className="my-3 btn btn-outline-secondary btn-block"
              >
              Send Email
            </Button>
          }

        </Form>
      </Container>
    </div>
  );
}


