const ReputationState = {
    reputationProperties : {},
    todaysReputation : {}, 
    reputationChartData:{},
    recentReviews:{}
}


const reputationReducers = (state = ReputationState, action) => {
    switch (action.type) {
      case "GET_REPUTATION_PROPERTIES":
        return {
          ...state,
          reputationProperties: action.payload.reputationProperties,
        };

        case "GET_TODAY_REPUTAION_DATA":
          return {
            ...state,
            todaysReputation: action.payload.todaysReputation,
          };

          case "GET_REPUTAION_CHART_DATA":
            return {
              ...state,
              reputationChartData: action.payload.reputationChartData,
            };

          case "GET_RECENT_REVIEWS":
            return {
              ...state,
              recentReviews: action.payload.recentReviews,
            };

        default:
      return {
        ...state,
      };
  }
};

export default reputationReducers