import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Switch, Space } from 'antd';
import Select from "react-select";
import makeAnimated from "react-select/animated";


const MultiLineChart = ({rentData, propName, dates, selectOptions, valid_floorplans}) => {
  const animatedComponents = makeAnimated();
  const [floorplan, setFloorplan] = useState("all")
  const [prevDays, setPrevDays] = useState(30)
  const [rentPsf, setRentPsf] = useState(false)
  const [chartData, setChartData] = useState({
    labels: dates.slice(-prevDays),
    datasets: [
      {
        label: propName.slice(0,10),
        borderColor: '#339966',
        borderWidth: 3,
        fill: false,
        // data: rentData[propName][floorplan].slice(-prevDays),
        data : []
      },
    ],
  })


  const colorCodes = [
    '#cc33ff', // Purple
    '#ff3366', // Pink
    '#339966',  // Teal
    '#ff9933', // Orange
    '#66cccc', // Turquoise
    '#cccc66', // Olive
    '#993333', // Maroon
    '#ff5733', // Red
    '#ffcc33', // Yellow
    '#33ccff', // Blue
    '#33ff57', // Green
    '#9966cc', // Lavender
  ];
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };


  function combineRentData(obj, divisor) {
    let resultArray = [];
    Object.values(obj)[0].forEach((_, index) => {
      let sum = Object.values(obj).reduce((acc, arr) => acc + (arr[index] || 0), 0);
      sum = sum/divisor
      resultArray.push(sum);
    });
  
    return resultArray;
  }

  const formatRentData = () => {

    const labels = dates.slice(-prevDays)
    const valid_floorplans_arr = Object.values(valid_floorplans)
    const datasets = Object.entries(rentData).map((property, idx) => {
      let formated_data = []

      if (floorplan === "all"){
        if (rentPsf) {
          formated_data = combineRentData(property[1].psf,valid_floorplans_arr[idx])
          formated_data = formated_data.slice(-prevDays)
        }
        else {
          let temp = {...property[1]}
          delete temp.psf
          formated_data = combineRentData(temp, valid_floorplans_arr[idx])
          formated_data = formated_data.slice(-prevDays)
        }
      }

      else{
        if (rentPsf) {
          formated_data = property[1]["psf"][floorplan].slice(-prevDays)
        }
        else {
          formated_data = property[1][floorplan].slice(-prevDays)
        }
      }

      return ({
        label: property[0].slice(0,10),
        borderColor: colorCodes[idx],
        borderWidth: 3,
        fill: false,
        data: formated_data,
      })
    })

    setChartData(prevState => ({
      ...prevState, 
      labels : labels,
      datasets : datasets
    }))
  }


  const handleChange = (event,newAlignment) => {
    setPrevDays(parseInt(newAlignment));
  };

  const handleSwitch = (value) => {
    setRentPsf(value)
  }

  useEffect(()=> {
    formatRentData();
  },[prevDays, rentPsf, floorplan])


  return (
    <div
        className="bg-white shadow-sm border"
        style={{
        borderRadius: "14px",
        padding:"50px",
        }}
    >
        <div style = {{
          display:"flex",
          flexDirection:"row",
          justifyContent:"space-between"
        }}>

          <h3 className="fw-bold mt-3">Competitor's Chart</h3>

          <div style={{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            gap:"3rem"
          }}>

            <Select
                style={{ borderRadius: "16px" }}
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={{value:"all", label:"all"}}
                onChange={(val) => setFloorplan(val.value)}
                options={selectOptions}
                className="w-100"
                maxMenuHeight={150}
            />

            <Space direction="vertical">
              <Switch checkedChildren="PSF" unCheckedChildren="PSF"  onChange={handleSwitch} />
            </Space>

          </div>

          <ToggleButtonGroup
            color="primary"
            value={`${prevDays}`}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
              <ToggleButton value="30">30</ToggleButton>
              <ToggleButton value="60">60</ToggleButton>
              <ToggleButton value="90">90</ToggleButton>
              <ToggleButton value="365">365</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Line data={chartData} options={options} />
    </div>
  );
};

export default MultiLineChart;
