
import React, { useEffect, useState, useContext } from 'react';
import { Bar } from 'react-chartjs-2';


const ReputaionScore = ({chartData}) => {

  const shortenLables = () => {
    const labels = chartData.labels
    return labels.map((name) => {
      return name.slice(0,10) + '..'
    });
  }

  const backgroundColor = [
    '#cc33ff', // Purple
    '#ff3366', // Pink
    '#339966',  // Teal
    '#ff9933', // Orange
    '#66cccc', // Turquoise
    '#cccc66', // Olive
    '#993333', // Maroon
    '#ff5733', // Red
    '#ffcc33', // Yellow
    '#33ccff', // Blue
    '#33ff57', // Green
    '#9966cc', // Lavender

  ];


  const chartOptions = {
    labels : shortenLables(),
    datasets: [
      {
        backgroundColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false, // To allow chart to be centered
    scales: {
      y: {
        beginAtZero: true,
        max: 5,
      },
    },
  };

  const [reputationToday, setReputationToday] = useState({
    ...chartOptions,
    datasets: [
      {
        ...chartOptions.datasets[0],
        data: [],
      },
    ],
  })

 const calculateData = () => {
    
  setReputationToday((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: chartData.dataset,
        },
      ],
    }));
 }

  useEffect(()=> {
    calculateData()
  }, [])

  return (
    <div 
        style={{ display: 'flex', justifyContent: 'center', height:"45vh", marginTop:"6vh"}}>
        <div  className="bg-white occupancy shadow-sm border mx-2 my-3"
            style={{
            borderRadius: "14px", 
            width: "100vh",
            }} 
        >
            <h6 className="fw-bold mt-3 text-center">Reputation Score ( This Week )</h6>
            <div className="my-auto px-2" >
                <Bar data={reputationToday} options={options} height={300}  />
            </div>
        </div>
    </div>
  );
};

export default ReputaionScore;