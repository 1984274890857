import React from "react";
// import IconButton from "@mui/material/IconButton";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import { ReactComponent as Search } from "./search.svg";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// const options = [
//   { value: "all", label: "All" },
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

export default function SearchBar({ leaseProperties, setSelectedProperties }) {
  const formatedProperties = () => {
    if (leaseProperties !== undefined) {
      const props = [{ value: "all", label: "All" }];
      leaseProperties.forEach((property, idx) => {
        props.push({ value: property, label: property });
      });
      return props;
    }
  };
  const animatedComponents = makeAnimated();
  const options = formatedProperties();
  return (
    <div className="w-75">
      <Select
        style={{ borderRadius: "16px" }}
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={options[0]}
        isMulti
        onChange={(val) => setSelectedProperties(val)}
        options={options}
      />
      {/* <FormControl sx={{ my: 2, width: "80%" }} variant="outlined">
        <InputLabel sx={{ mx: 2 }} htmlFor="outlined-adornment-search">
          Search
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          type="text"
          sx={{ borderRadius: 30 }}
          endAdornment={
            <InputAdornment sx={{ mx: 2 }} position="end">
              <IconButton aria-label="toggle password visibility" edge="end">
                <Search />
              </IconButton>
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl> */}
    </div>
  );
}
