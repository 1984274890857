import React from "react";
import { Button } from "react-bootstrap";
import "./obtn.css";
function PrimaryBtn(props) {
  const { text, ...rest } = props;
  return (
    <Button
      size="lg"
      variant="primary"
      {...rest}
      disabled
      className="d-flex mx-2 align-items-center btn-primary"
    >
      <span className="fw-bold px-3 py-1 text-center">{text}</span>
    </Button>
  );
}

export default PrimaryBtn;
