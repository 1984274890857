import React, { useState } from "react";
import { Container, Form, FloatingLabel, Button } from "react-bootstrap";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useAuth from "../../auth/useAuth";
import { Link } from "react-router-dom";

export default function Login({ setUser }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();

  const login = () => {
    auth.login(username, password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="w-100"
      style={{ background: "#F3F3F3", minHeight: "100vh" }}
    >
      <Container
        className="bg-white py-5 d-flex w-50 shadow border flex-column justify-content-center"
        style={{ borderRadius: "50px", margin: "7% auto" }}
      >
        <img
          src="./logo.png"
          className="mx-auto my-4"
          style={{ width: "30%" }}
          alt="revvy"
        />
        <div className="flex-column w-50 my-2 mb-3 mx-auto">
          <h3 className="fw-bold">Log in</h3>
          <h6>Sign in to get started!</h6>
        </div>
        <Form className="d-flex flex-column w-50 mx-auto justify-content-center">
          <FloatingLabel
            controlId="floatingInput"
            label="Username"
            className="mb-3"
          >
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              value={password}
              autoComplete="false"
              onKeyPress={(e) => (e.key === "Enter" ? login() : null)}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />

                <Button
                    // variant="secondary"
                    onClick={togglePasswordVisibility}
                    className="password-toggle-button"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        }}
                  >
                    {
                        showPassword ? 
                        <VisibilityOffIcon />
                        :
                        <VisibilityIcon />
                    }
                  </Button>

          </FloatingLabel>
          {/* <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: grey[900],
                  },
                }}
                defaultChecked
              />
            }
            sx={{ mt: 2 }}
            label="Remember me"
          /> */}
          <Button
            onClick={login}
            variant="outline-dark"
            className="my-3 btn btn-outline-secondary btn-block"
          >
            Login
          </Button>
          <div class="flex justify-end">
            {/* <a href="http://127.0.0.1/api/password-reset"> Forgot Password? </a> */}
            <Link to = "/verify-email"> Forgot Password </Link>
          </div>
        </Form>
      </Container>
    </div>
  );
}
