import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Fab from "@mui/material/Fab";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Zoom from "@mui/material/Zoom";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as NewLeasesIcon } from "../assets/newlease.svg";
import { ReactComponent as LeaseRenewalIcon } from "../assets/leaseRenewal.svg";
import CompetitorsIcon from "../assets/competitors.png";
import logo from "../assets/logo.png";
import pfp from "../assets/logo2.png";
import { Dropdown } from "react-bootstrap";
import useAuth from "../../auth/useAuth";
import axios from "../../auth/Axios";
import { AdminContext } from "../../auth/AdminContext";
import { useSelector, useDispatch } from "react-redux";
import { SuperuserContext } from "../../auth/SuperuserContext";
import { MarketAccessContext } from "../../auth/MarketAccessContext";
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { UserManagementContext } from "../../auth/UserMngmtContext";
import { ReputationContext } from "../../auth/ReputatationContext";
import StarsIcon from '@mui/icons-material/Stars';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    padding: 0.5,
    display: "flex",
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function ScrollTop() {
  const trigger = useScrollTrigger();

  const handleClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <Fab color="default" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}

export default function PersistentDrawer(props) {
  const theme = useTheme();
  const { isAdmin, setAdmin } = React.useContext(AdminContext);
  const { isSuperuser, setIsSuperuser } = React.useContext(SuperuserContext);
  const { hasMarketAccess, setHasMarketAccess } = React.useContext(MarketAccessContext);
  const { hasUserMngmtAccess, setHasUserMngmtAccess } = React.useContext(UserManagementContext);
  const { hasReputationContext, setHasReputationContext } = React.useContext(ReputationContext);
  const auth = useAuth();
  const [open, setOpen] = React.useState(true);
  const [username, setUsername] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [profilePicture, setProfilePic] = React.useState(pfp);
  const [companyLogo, setCompanyLogo] = React.useState(logo);
  const [loading, setLoading] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(true); 
  const dWidth = open ? 240 : 0;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    setOpen(false);
  };


  function handleOpenSettings(){
    setOpenCollapse(!openCollapse);
}
  
  const associatedPropDispatcher = (associatedProperty) => {
    localStorage.setItem("associatedProp",associatedProperty)
    dispatch({
      type: "UPDATE_ASSOCIATED_PROP",
      payload: {
        associatedProperty: associatedProperty,
      },
    });
  }

  const menuItems = [
    {
      id: 1,
      icon: <DashboardIcon style={{ width: "30px", height: "25px" }} />,
      title: "Dashboard",
      route: "/",
    },
    {
      id: 2,
      icon: <NewLeasesIcon style={{ width: "30px" }} />,
      title: "New Leases",
      route: "/new-leases",
    },
    {
      id: 3,
      icon: <LeaseRenewalIcon style={{ width: "30px" }} />,
      title: "Lease Renewals",
      route: "/lease-renewals",
    },
    {
      id: 4,
      icon: (
        <img
          src={CompetitorsIcon}
          alt="Revvy Competitors"
          style={{ width: "30px" }}
        />
      ),
      title: "Competitors",
      route: "/competitors",
    },
    {
      id: 6,
      icon: <SettingsIcon style={{ width: "30px" , color:'#323333' }} />,
      title: "Parameters",
      route: "/parameters",
    },
  ];
  const fetchProfile = async () => {
    setLoading(true);
    await axios
      .get("profile")
      .then((res) => {
        setAdmin(res.data.isAdmin);
        setIsSuperuser(res.data.isSuperuser);
        setUsername(res.data.user_name);
        setProfilePic(res.data.profile_picture);
        setCompanyName(res.data.company_name);
        setCompanyLogo(res.data.company_logo);
        setHasMarketAccess(res.data.hasMarketAccess);
        setHasUserMngmtAccess(res.data.has_user_management_access);
        associatedPropDispatcher(res.data.associated_property)
        setHasReputationContext(res.data.has_reputation_access)
        // var title = document.getElementsByTagName("title")[0];
        // document.getElementsByName("icon")[0].href = res.data.company_logo;
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => fetchProfile(), 5000);
      });
  };
  React.useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div sx={{ display: "flex" }}>
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <img
                src={companyLogo ? companyLogo : logo}
                style={{ height: "30px" }}
                className="ms-4"
                alt={companyName}
              />
            </Link>
          </div>
          <div className="d-flex align-items-center mx-5">
            <Dropdown className="w-100">
              <Dropdown.Toggle
                className="ml-2 btn-sm d-flex align-items-center"
                variant=""
                id="dropdown-basic"
                style={{
                  border: "0px solid white",
                  color: "white",
                }}
              >
                {username ? (
                  <>
                    <div className="d-flex ps-3 me-3 text-end flex-column">
                      <span className="fw-bold text-black">{username}</span>
                      {companyName !== "" ? (
                        <small className="text-muted">{companyName}</small>
                      ) : null}
                    </div>
                    <img
                      className="rounded-circle"
                      style={{ width: "45px" }}
                      src={profilePicture ? profilePicture : pfp}
                      alt="User's Profile"
                    />
                  </>
                ) : null}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item onClick={auth.logout} className="text-danger">
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Toolbar>
      </AppBar>
      <ScrollTop {...props} />
      <Drawer
        sx={{
          width: dWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: dWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <Link key={index} to={item.route}>
              <ListItem button sx={{ marginTop: 1.5 }}>
                <ListItemIcon style={{ width: "30px" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  secondaryTypographyProps={{
                    sx: {
                      fontFamily: "Poppins,sans-serif",
                    },
                  }}
                  secondary={item.title}
                />
              </ListItem>
            </Link>
          ))}

          <>
            <ListItem button onClick={handleOpenSettings} sx={{ marginTop: 1.5 }}>
              <ListItemIcon style={{ width: '30px' }}>
                <AutoStoriesIcon style={{ width: "40px", color:'#323333' }}/>
              </ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{
                  sx: {
                    fontFamily: 'Poppins,sans-serif',
                  },
                }}
                secondary="Report Management"
              />
            </ListItem>

            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                <Link to='/reports'>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon style={{ width: '30px' }}>
                      <MenuBookIcon style={{ width: "40px", color:'#323333' }}/>
                    </ListItemIcon>
                      <ListItemText
                        secondaryTypographyProps={{
                          sx: {
                            fontFamily: 'Poppins,sans-serif',
                          },
                        }}
                        secondary="Reports"
                      />
                  </ListItemButton>
                </Link>

                <Link to='/assign-report'>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon style={{ width: '30px' }}>
                      <BookmarkAddIcon style={{ width: "40px", color:'#323333' }}/>
                    </ListItemIcon>
                      <ListItemText
                        secondaryTypographyProps={{
                          sx: {
                            fontFamily: 'Poppins,sans-serif',
                          },
                        }}
                        secondary="Schedule"
                      />
                  </ListItemButton>
                </Link>

              </List>
            </Collapse>
          </>

          {
            hasMarketAccess && (
              <Link to='/market-insight'>
                <ListItem button sx={{ marginTop: 1.5 }}>
                  <ListItemIcon style={{ width: "30px" }}>
                    <QueryStatsIcon style={{ width: "40px", color:'#323333' }} />
                  </ListItemIcon>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Poppins,sans-serif",
                      },
                    }}
                    secondary="Market Manager"
                  />
                </ListItem>
              </Link>
            )
          }

        {
            hasReputationContext && (
              <Link to='/reputation'>
                <ListItem button sx={{ marginTop: 1.5 }}>
                  <ListItemIcon style={{ width: "30px" }}>
                    <StarsIcon style={{ width: "40px", color:'#323333' }} />
                  </ListItemIcon>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Poppins,sans-serif",
                      },
                    }}
                    secondary="Reputation Manager"
                  />
                </ListItem>
              </Link>
            )
          }

          {
            hasUserMngmtAccess && (
              <Link to='/user-management'>
                <ListItem button sx={{ marginTop: 1.5 }}>
                  <ListItemIcon style={{ width: "30px" }}>
                    <GroupAddIcon style={{ width: "40px", color:'#323333' }} />
                  </ListItemIcon>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Poppins,sans-serif",
                      },
                    }}
                    secondary="User Management"
                  />
                </ListItem>
              </Link>
            )
          }

          {isAdmin && (
            <>
              <a
                target="_blank"
                href="https://app.revvyco.com/admin/"
                rel="noreferrer"
              >
                <ListItem button sx={{ marginTop: 1.5 }}>
                  <ListItemIcon style={{ width: "30px" }}>
                    {/* <img src={Admin} alt="admin" style={{ width: "30px" }} /> */}
                    <SupervisedUserCircleIcon style={{ width: "40px", color:'#323333' }} />
                  </ListItemIcon>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Poppins,sans-serif",
                      },
                    }}
                    secondary="Admin"
                  />
                </ListItem>
              </a>
            </>
          )}
        </List>
      </Drawer>
    </Box>
  );
}
