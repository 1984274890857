import React, { useState } from "react";
import { Container, Form, FloatingLabel, Button } from "react-bootstrap";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from "react-router-dom";
import { unAuthAxios } from "../../auth/unAuthAxios";
import { useParams } from 'react-router-dom';
import { Spinner } from "react-bootstrap";



export default function PasswordReset() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { user_token } = useParams();

  const resetpassword = () => {  
    setLoading(true)
    setError(false)
    setSuccess(false)
    setErrorMsg("")

    const data = {
        password,
        user_token
    }

    unAuthAxios.post("reset-password-custom", data)
    .then(resp => {
        // console.log(resp.data)
        setError(false)
        setErrorMsg("")
        setSuccess(true)
        setLoading(false)
    })

    .catch(err => {
        // console.log(err.response.data)
        setSuccess(false)
        setError(true)
        setErrorMsg(err.response.data)
        setLoading(false)
    })

  };

  return (
    <div
      className="w-100"
      style={{ background: "#F3F3F3", minHeight: "100vh" }}
    >
      <Container
        className="bg-white py-5 d-flex w-50 shadow border flex-column justify-content-center"
        style={{ borderRadius: "50px", margin: "7% auto" }}
      >
        <img
          src="/logo.png"
          className="mx-auto my-4"
          style={{ width: "30%" }}
          alt="revvy"
        />
        <div className="flex-column w-50 my-2 mb-3 mx-auto">
          <h3 className="fw-bold">Password Reset</h3>
          <h6>Please Enter Your New Password</h6>
        </div>

        {
            error? 
            <div className="Response flex-column w-50 my-2 mb-3 mx-auto">
                <h6 style={{
                    color:"red"
                }}>{errorMsg}
                </h6>
            </div>
            : null
        }

        {
            success ?
            <div className="Response flex-column w-50 my-2 mb-3 mx-auto">
                <h6 style={{
                    color:"green"
                }}>Password Updated Successfully. Please Click <Link to = "/login"> Here </Link> to Login.
                </h6>
            </div>
            : null

        }

        <Form className="d-flex flex-column w-50 mx-auto justify-content-center">
            <FloatingLabel controlId="floatingPassword" label="New Password">
                <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    autoComplete="off"
                    onKeyPress={(e) => (e.key === 'Enter' ? resetpassword() : null)}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <Button
                    // variant="secondary"
                    onClick={togglePasswordVisibility}
                    className="password-toggle-button"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        }}
                >
                    {
                        showPassword ? 
                        <VisibilityOffIcon />
                        :
                        <VisibilityIcon />
                    }
                </Button>
            </FloatingLabel>

            {
              loading ?
                <Spinner animation="border" role="status" className="my-5 mx-auto">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              :
                <Button
                  type = "button"
                  onClick={resetpassword}
                  variant="outline-dark"
                  className="my-3 btn btn-outline-secondary btn-block"
                  >
                  Reset Password
                </Button>
          }

        </Form>
      </Container>
    </div>
  );
}


