import React from "react";
import CompetitorsData from "./CompetitorsData";

export default function CompetitorFloorPlan({
  record,
  unitTypes,
  floorPlan,
  competitorPropertyId,
  fetchRecords,
}) {
  return (
    <div
      className="flex flex-column shadow-sm border py-4 p-3 mx-5 my-3"
      style={{ borderRadius: "14px" }}
    >
      <h6
        className="fw-bold d-inline m-2 py-2 px-4"
        style={{
          backgroundColor: "#CEEAFC",
          color: "#0997F2",
          borderRadius: "22px",
          border: "1px",
          marginTop: "2rem",
        }}
      >
        Floor Plan - {floorPlan}
      </h6>
      <CompetitorsData
        record={record}
        unitTypes={unitTypes}
        floorPlan={floorPlan}
        competitorPropertyId={competitorPropertyId}
        fetchRecords={fetchRecords}
      />
    </div>
  );
}
