import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../auth/AdminContext";
import { SuperuserContext } from "../../auth/SuperuserContext";
import { Modal, Form, Spinner } from "react-bootstrap";
import SolidPrimaryBtn from "../Buttons/SolidPrimaryBtn";
import OutlinedPrimaryBtn from "../Buttons/OutlinedPrimaryBtn";
import TextField from "@mui/material/TextField";
import axios from "../../auth/Axios";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material";
import SelectUnit from "./SelectUnit";
import Success from "../assets/success.gif";
function GeneratePricing(props) {
  const { isAdmin } = useContext(AdminContext);
  const { isSuperuser } = useContext(SuperuserContext);
  const [pricingGridData, setPricingGridData] = useState([]);
  const [customPricingGrid, setCustomPricingGrid] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [showError, setError] = useState("");
  const [date, setDate] = useState(new Date());
  const [dateDisabled, setDateDisable] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [editRents, setEditRents] = useState(false);
  const [leaseTerms, setLeaseTerms] = useState(props.leaseTerms);
  const [customRents, setCustomRents] = useState([]);
  const [selectedColumnIdx, setSelectedColumnIdx] = useState(0);
  const [success, setSuccess] = useState(false);
  const [syncFailed, setSyncFailed] = useState(false)
  const [amenityAmt, setAmenityAmt] = useState(0)
  const [allGridData, setAllGridData] = useState([]);
  const fetchPricingGrid = async () => {
    if (leaseTerms <= 24) {
      if (props.units[selectedUnit] === undefined) {
        setError("Select a Unit");
      } else {
        setError("");
        setLoading(true);
        const unitID =
          props.units[selectedUnit] === undefined
            ? setError("Select")
            : props.units[selectedUnit];
        // const startDate = props.availDates[selectedUnit];

        await axios
          .post("get-new-lease-pricing-grid", {
            unit_id: unitID,
            prop_id: props.propertyid,
            start_date: date,
          })
          .then((res) => {
            setAllGridData(res.data.data)
            console.log("========== the amenity amount is =====", res.data.amenity_amt)
            setAmenityAmt(res.data.amenity_amt)
            formatTableData(res.data, date);
            setLoading(false);
            setShowGrid(true);
          })
          .catch((err) => {
            console.error(err);
            setTimeout(() => {
              fetchPricingGrid();
            }, 5000);
          });
      }
    } else setError("Lease Term should be less than 24 months");
  };
  const formatTableData = (data, parentDate) => {
    const formattedData = data.data.slice(2, leaseTerms);
    const columns = [];
    const skipDays = data.skip_days;
    const moveinFreq = data.move_in_day_change_frequency;
    const temp = new Date();
    const dateChild = new Date(temp.setDate(temp.getDate() + skipDays));
    data.columns.forEach((column, idx) => {
      if (idx < data.columns.length - 1) {
        const nextDay = new Date(
          dateChild.setDate(
            dateChild.getDate() + (idx === 0 ? null : moveinFreq)
          )
        );
        columns.push(nextDay.getMonth() + 1 + "/" + nextDay.getDate());
      }
    });
    setPricingGridData({
      columns: ["Term", ...columns],
      data: formattedData,
      rowIndex: data.index,
    });
    setCustomPricingGrid(formattedData);
  };
  const getColumnData = (idx) => {
    if (idx) {
      const temp = [];
      pricingGridData.data.forEach((row) => {
        temp.push(row[idx]);
      });
      return temp;
    }
  };
  const updateColumnInGrid = (colIdx, grid) => {
    if (colIdx && grid) {
      const temp = [];
      //this deep copy issue has been resolved but may have performance issue
      const gridCopy = JSON.parse(JSON.stringify(grid));
      gridCopy.forEach((row, rowIdx) => {
        row[colIdx] = customRents[rowIdx];
      });
      console.log(gridCopy);
      return gridCopy;
    }
  };
  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    confirmFilters: true,
    rowsPerPage: 100,
    elevation: 0,
    tableBodyHeight: "375px",
    onColumnSortChange: (changedColumn, direction) => {
      if (isAdmin || isSuperuser) {
        const colIdx = pricingGridData.columns.indexOf(changedColumn);
        const selectedColumnData = getColumnData(colIdx);
        setSelectedColumnIdx(colIdx);
        setSelectedColumn(selectedColumnData);
        setCustomRents(selectedColumnData);
        setEditRents(true);
      }
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return null;
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div
          className="mt-4 d-flex justify-content-center"
          style={{ fontFamily: "Poppins,sans-serif" }}
        >
          <OutlinedPrimaryBtn
            onClick={() => applyNewFilters()}
            text={"Apply Filters"}
          ></OutlinedPrimaryBtn>
        </div>
      );
    },
  };

  const handleHide = () => {
    props.setmodalshow(false);
    setSelectedUnit(undefined);
    setDateDisable(true);
    setTimeout(() => setShowGrid(false), 500);
    setTimeout(() => setEditRents(false), 500);
  };

  const downloadPDF = () => {
    console.log("PDF downloaded");
    props.setmodalshow(false);
    setShowGrid(false);
  };
  useEffect(() => {
    if (selectedUnit !== undefined) {
      setDateDisable(false);
      const temp = new Date(props.availDates[selectedUnit]);
      setDate(`${temp.getMonth() + 1}/${temp.getDate()}/${temp.getFullYear()}`);
    } else if (selectedUnit === undefined) setDateDisable(true);
  }, [selectedUnit, props.show, props.availDates]);

  const handleChange = (idx) => (e) => {
    console.log(e.target.value);
    if (!isNaN(e.target.value)) {
      customRents[idx] = parseInt(e.target.value);
      setCustomRents([...customRents]);
    } else if (isNaN(e.target.value)) {
      setError(true);
    }
  };
  const pushRents = async () => {
    const reqBody = editRents
      ? {
          unit_id: props.units[selectedUnit],
          custom_rents: updateColumnInGrid(
            selectedColumnIdx,
            customPricingGrid
          ),
          real_rents: pricingGridData.data,
          all_rents: allGridData,
        }
      : {
          unit_id: props.units[selectedUnit],
          custom_rents: pricingGridData.data,
          real_rents: pricingGridData.data,
          all_rents: allGridData,
        };
    setSuccess(true);
    await axios.post("push-new-lease-rents", reqBody).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          handleHide();
          setSuccess(false);
        }, 3000);
      } else {
        setError("Something went wrong");
      }
    })
    .catch(err => {
      setSyncFailed(true)
      setTimeout(() => {
        handleHide();
        setSuccess(false);
        setSyncFailed(false)
      }, 5000);
    });
  };
  return (
    <Modal
      size={showGrid ? "xl" : "lg"}
      centered
      style={{
        fontFamily: "Poppins, sans-serif",
        zIndex: 1300,
        maxHeight: "100%",
        width: showGrid ? "110%" : "100%",
      }}
      show={props.show}
      onHide={handleHide}
      animation="true"
    >
      <Modal.Header closeButton>
        {showGrid ? (
          <h4 className="text-center m-0 m-2 fw-bold">Generate Pricing</h4>
        ) : null}
      </Modal.Header>
      <Modal.Body className="px-5">
        {isLoading ? (
          <div className="d-flex justify-content-center w-100">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : syncFailed ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h6 className="fw-bold">Sync Failed. Property is not allowed to manually push rents.</h6>
          </div>
        ) : success ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={Success} alt="success gif" className="w-50" />
            <h6 className="fw-bold">Successfully synced with PMs</h6>
          </div>
        ) : editRents ? (
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            {selectedColumn.map((row, idx) => {
              return (
                <TextField
                  key={idx + 3}
                  type="number"
                  label={idx + 3 + " months"}
                  value={customRents[idx]}
                  onChange={handleChange(idx)}
                  sx={{ m: 2 }}
                />
              );
            })}
          </div>
        ) : showGrid ? (
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap mx-2 justify-content-around">
              <h6>Unit Type : {props.unitType}</h6>
              <h6>Unit Number : {selectedUnit}</h6>
              <h6>Reference Rent : ${props.refRent}</h6>
              <h6>Availability Date : {props.availDates[selectedUnit]}</h6>
              <h6>Amenity Amount : {amenityAmt}</h6>
            </div>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={pricingGridData.data}
                columns={pricingGridData.columns}
                options={options}
              />
            </ThemeProvider>
          </div>
        ) : props.units ? (
          <>
            <h4 className="text-center m-0 fw-bold">Generate Pricing</h4>
            <Form className="my-2 mt-4">
              <div className="mb-3 d-flex align-items-center">
                <h6 className="m-0 me-4 w-50  font-normal">Unit Number</h6>
                <SelectUnit
                  selectedUnit={selectedUnit}
                  setSelectedUnit={setSelectedUnit}
                  units={props.unitnumbers}
                  className="w-100"
                />
              </div>
              <div className="mb-3 d-flex align-items-center">
                <h6 className="m-0 me-4 w-50 font-normal">
                  Lease Terms <br />
                  <small className="font-light">(in months)</small>
                </h6>
                <Form.Control
                  type="number"
                  value={leaseTerms}
                  size="sm"
                  onChange={(e) => setLeaseTerms(e.target.value)}
                />
              </div>
            </Form>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" className="my-5">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showError !== "" || leaseTerms > 24 ? (
          <span className="ms-4 text-danger fw-normal">{showError}</span>
        ) : null}
        {showGrid ? (
          <>
            <OutlinedPrimaryBtn
              onClick={() => {
                if (editRents) {
                  setEditRents(false);
                } else {
                  setSelectedUnit(undefined);
                  setDateDisable(true);
                  setShowGrid(false);
                }
              }}
              text="Go Back"
            />
            <SolidPrimaryBtn onClick={pushRents} text="Sync with PMS" />
          </>
        ) : (
          <>
            <SolidPrimaryBtn
              onClick={fetchPricingGrid}
              text="Generate Pricing"
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default GeneratePricing;
